<template>
    <!-- 店铺公共 -->
    <div class="shop_base">
        <div class="shop_box" v-ani.fade-up>
            <div class="l_box">
                <div class="l">
                    <img :src="shop_info.logo">
                </div>
                <div class="r">
                    <div class="name_box">
                        {{ shop_info.shop_name }}
                        <div class="follow" @click="followClick">{{ shop_info.if_follow ? '已关注' : '+ 关注' }}</div>
                    </div>
                    <div class="level_box">
                        <img class="guanF" v-if="shop_info.suppliers_id == '666666'"
                            src="https://yizhu-new.oss-cn-shenzhen.aliyuncs.com/app_img/aIcon/icon483.png">
                        <img class="ziY" v-else
                            src="https://yizhu-new.oss-cn-shenzhen.aliyuncs.com/app_img/aIcon/icon482.png">
                        <UserRank :hatArr="shop_info.level.hat" :starsArr="shop_info.level.stars"></UserRank>
                    </div>
                    <div class="evaluate_box">
                        评价标签
                        <div class="p_label">发货及时</div>
                        <div class="p_label">售后满意</div>
                        <div class="p_label">发货及时</div>
                    </div>
                </div>
            </div>
            <div class="r_box">
                <input type="text" v-model="keywords" placeholder="模糊搜索店内商品" @keydown="keyDown">
                <div class="search_txt" @click="searchClick">搜索</div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        shop_info: { type: Object, default: () => { } },

    },
    created() {

    },
    data() {
        return {
            keywords: ""
        }
    },
    methods: {
        keyDown(e) {
            if (e.key === 'Enter') {
                this.searchClick();
            }
        },
        searchClick() {
            this.$emit('searchClick', this.keywords);
        },
        followClick() {
            this.$emit('followClick');
        },
    }
}
</script>
<style lang="scss" scoped>
.shop_box {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .l_box {
        width: 50%;
        display: flex;

        .l {
            width: 0.95rem;
            height: 0.95rem;

            img {
                width: 100%;
                height: 100%;
                border-radius: 10rpx;
            }
        }

        .r {
            flex: 1;
            margin-left: 0.1rem;

            .name_box {
                font-size: 0.18rem;
                display: flex;

                .follow {
                    margin-left: 0.1rem;
                    width: 0.49rem;
                    height: 0.24rem;
                    line-height: 0.24rem;
                    background: linear-gradient(90deg, #6FB1FF 0%, #0261D5 100%);
                    border-radius: 4px 4px 4px 4px;
                    color: #fff;
                    font-size: 0.12rem;
                    text-align: center;
                    cursor: pointer;
                }
            }

            .level_box {
                margin-top: 0.05rem;
                display: flex;
                align-items: center;

                .ziY {
                    margin-right: 0.1rem;
                    width: 0.6rem;
                    height: 0.18rem;
                }

                .guanF {
                    margin-right: 0.1rem;
                    width: 0.42rem;
                    height: 0.18rem;
                }
            }

            .evaluate_box {
                margin-top: 0.05rem;
                display: flex;
                align-items: center;

                .p_label {
                    margin-left: 0.05rem;
                    padding: 0 0.1rem;
                    box-sizing: border-box;
                    min-width: 0.7rem;
                    height: 0.22rem;
                    line-height: 0.22rem;
                    background: #E5ECFC;
                    border-radius: 4px 4px 4px 4px;
                    text-align: center;
                    color: #0261D5;
                }
            }
        }
    }

    .r_box {
        width: 50%;
        position: relative;

        input {
            padding-left: 0.2rem;
            padding-right: 0.8rem;
            width: 100%;
            height: 0.4rem;
            background: #FFFFFF;
            box-shadow: 0px 0px 6px 1px #EEEEEE;
            border-radius: 4px 4px 4px 4px;
            border: 1px solid #EEEEEE;
        }

        .search_txt {
            width: 0.7rem;
            height: 0.4rem;
            line-height: 0.4rem;
            background: #0261D5;
            border-radius: 4px 4px 4px 4px;
            text-align: center;
            color: #fff;
            font-size: 0.18rem;
            position: absolute;
            top: 0;
            right: 0;
            cursor: pointer;
        }
    }
}
</style>