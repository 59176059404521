<template>
  <div class="base-tooltip">
    <slot name='target' />
    <img v-if="!$slots.target" src="@/assets/images/icon-121.png" alt="" class="icon">
    <div class="pop">
      <div class="pop-cnt s14 color-9">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {
    // console.log(this.$slots);
  }
};
</script>

<style lang="scss" scoped>
.base-tooltip{
  position: relative;
  margin-left: .04rem;
  display: inline-block; vertical-align: middle;
  .icon{
    display: block;
    width: .14rem;
  }
  .pop{
    position: absolute; bottom: 100%; left: 50%; z-index: 10;
    width: 2.33rem;
    padding-bottom: .18rem;
    margin-left: -1.165rem;
    display: none;
    &-cnt{
      background-color: #ffffff;
      border-radius: .04rem;
      filter: drop-shadow(0 0 0.3rem rgba(219, 236, 255, 0.8));
      position: relative;
      padding: .25rem .28rem;
      line-height: calc(24 / 14 * 1em);
      &:after{
        content: '';
        position: absolute; top: 100%; left: 50%;
        border: .1rem solid transparent;
        border-top-color: #fff;
        margin-left: -.1rem;
      }
    }
  }
  &:hover{
    .pop{
      display: block;
    }
  }
}
</style>