<template>
    <!-- 商城列表使用 -->
    <div class="c-product-item c-card">
        <router-link class="goods" :to="`/product-detail?goods_id=${value.goods_id}`" tag="a" target="_blank">
            <!-- 图片 -->
            <div class="img">
                <ImgRatio :src="value.goods_image" :width="260" :height="260"></ImgRatio>
                <div v-if="value.if_period" class="label s14 color-f font-light">账期</div>
            </div>
            <!-- 标题 -->
            <div class="tit s18 ep2">{{ value.goods_name }}</div>
            <!-- 价格 -->
            <div class="price s20 color-red font-medium">
                <span class="s22">{{ value.shop_price }}</span>/{{ value.unit_name }}
            </div>
            <!-- 销量&发货地 -->
            <div class="s14 color-9 font-light sales">
                <span>{{ value.sales_sum }}人已购买</span>
                <!-- <span>{{ value.city_name }}</span> -->
            </div>
            <!-- 标签 -->
            <div class="labels s12 font-light">
                <div v-if="value.sell_mode" class="label-item color-blue border-blue">{{ value.sell_mode }}</div>
                <div v-if="value.account_period" class="label-item color-green border-green">账期{{ value.account_period }}天
                </div>
            </div>
        </router-link>
        <!-- 店铺 -->
        <router-link class="shop flex-middle" :to="`/shop/seller?suppliers_id=${value.suppliers_id}`" tag="a"
            target="_blank">
            <img v-if="value.logo" :src="value.logo" class="shop-icon">
            <div class="name s14 font-light">{{ value.company_name }}</div>
            <div class="icon bg-contain"></div>
        </router-link>
        <!-- 按钮 -->
        <div class="btns">
            <div class="l_btn s22" @click="cancelCollect(value)">取消收藏</div>
        </div>
        <!-- 遮罩 -->
        <div class="mask_box" v-if="isMask">
            <img v-if="value.isCheck" class="sel_icon"
                src="https://yizhu-new.oss-cn-shenzhen.aliyuncs.com/app_img/aIcon/icon383.png" @click="selectClick">
            <img v-else class="sel_icon" src="https://yizhu-new.oss-cn-shenzhen.aliyuncs.com/app_img/aIcon/icon382.png"
                @click="selectClick">
        </div>
    </div>
</template>
  
<script>
import { CollectGoodsApi } from "@/request/api/shop";
export default {
    props: {
        isMask: { type: Boolean, default: false },
        value: { type: Object, default() { return {} } }
    },
    data() {
        return {};
    },
    methods: {
        // 取消收藏
        cancelCollect(item) {
            CollectGoodsApi({ goods_id: item.goods_id }).then(({ status }) => {
                if (status == 200) {
                    this.$message.success('操作成功');
                    this.$emit('refresh');
                }
            })
        },
        selectClick() {
            this.value.isCheck = !this.value.isCheck;
            this.$emit('checkFn', { isCheck: this.value.isCheck, goods_id: this.value.goods_id });
        }
    }
};
</script>
  
<style lang="scss" scoped>
.c-product-item {
    height: 5.9rem;
    position: relative;

    .mask_box {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba($color: #000000, $alpha: 0.4);
        border-radius: .04rem;

        .sel_icon {
            width: 42px;
            height: 42px;
            position: absolute;
            top: 0.1rem;
            right: 0.1rem;

        }
    }

    .goods {
        padding: 0.2rem;
        display: block;

        .img {
            position: relative;
            border-radius: .04rem;
            overflow: hidden;

            .label {
                position: absolute;
                right: 0;
                top: 0;
                background-image: linear-gradient(90deg, #2991ee 0%, #0174da 100%);
                border-radius: 0 .04rem 0 .04rem;
                line-height: calc(30 / 14 * 1em);
                padding: 0 .11rem;
            }
        }

        .tit {
            line-height: calc(32 / 18 * 1em);
            height: calc(32 / 18 * 1em * 2);
            margin-top: .21rem;
        }

        .price {
            margin: .06rem;
        }

        .sales {
            display: flex;
            justify-content: space-between;
            margin-top: .11rem;
        }

        .labels {
            display: flex;
            margin-top: .17rem;

            .label-item {
                margin-right: 0.07rem;
                border-radius: .04rem;
                padding: .013rem .04rem;
            }
        }

        &:hover {
            .tit {
                color: $blue;
            }
        }
    }

    .shop {
        border-top: 1px solid #e6f2f8;
        padding: .17rem .2rem;

        &-icon {
            width: 0.2rem;
            height: 0.2rem;
            border-radius: 50%;
            background-color: $blue;
            object-fit: cover;
            margin-right: .06rem;
        }

        .icon {
            width: .06rem;
            height: .1rem;
            background-image: url(~@/assets/images/icon-right.png);
            margin-left: .09rem;
        }

        &:hover {
            color: $blue;

            .icon {
                background-image: url(~@/assets/images/icon-right-blue.png);
            }
        }
    }
}

.btns {
    padding: 0 0.2rem;
    display: flex;
    justify-content: center;

    .l_btn {
        width: 100%;
        padding: 0 .05rem;
        text-align: center;
        line-height: .35rem;
        border-radius: .04rem;
        cursor: pointer;
        color: $blue;
        background: $blueLight;

    }
}
</style>