<template>
  <!-- 单选框列表 -->
  <div class="c-radio_group flex-middle">
    <template v-for="item in options">
      <label class="c-radio flex-middle" :key="item.value">
        <input type="radio" hidden :name="name" :checked='value == item.value' :disabled="disabled"
          @change="handleChange(item.value)">
        <div class="c-radio_icon"></div>
        <BasePopover v-if="item.text" :show="item.showText">
          <span slot='target' class="c-radio_name">{{ item.name }}</span>
          <div v-html="item.text"></div>
        </BasePopover>
        <span v-else class="c-radio_name">{{ item.name }}</span>
        <BaseTooltip v-if="item.tips">{{ item.tips }}</BaseTooltip>
      </label>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      default() { return [] }
    },
    disabled: { type: Boolean, default: false },
    value: '',
    name: ''
  },
  data() {
    return {};
  },
  methods: {
    handleChange(value) {
      this.$emit('input', value);
      this.$emit('change',value);
    }
  }
};
</script>

<style lang="scss" scoped>
.c-radio_group {
  flex-wrap: wrap;
}

.c-radio {
  margin: .068rem .3rem .068rem 0;
  cursor: pointer;
  position: relative;

  &:last-of-type {
    margin-right: 0;
  }

  &_icon {
    margin-right: .07rem;
  }

  input:checked~&_name {
    color: $blue;
  }
}
</style>