<template>
  <BaseDialog
    v-if="visible"
    :visible.sync="visible"
    title='商品评价'
    confirm-text='提交'
    @close="$emit('close')"
    @cancel="$emit('cancel')"
    @confirm="handleSubmit"
  >
    <form class="form s16 color-2" v-for="(item,index) in detailInfo?.goods_list" :key="index">
      <!-- 关联订单 -->
      <div class="form-item" style="margin-bottom: .1rem;">
        <!-- <label class="form-item_label">
          <div><p class="p_label">关联订单</p></div>
        </label> -->
        <div class="form-item_content">
          <div class="goods flex">
            <img :src="item.goods_image" alt="" class="goods-cover">
            <div class="s16 color-4 text">
              <div class="tit">
                <span class="color-9 info" style="width: 110px">商品标题：</span><span class="text-overflow">{{ item.goods_name }}</span>
              </div>
              <div class="flex-middle flex-between row-2">
                <div class="tit"><span class="color-9 info"  style="width: 60px">规格：</span><span class="text-overflow" style="flex: 1">{{ item.spec_key_name }}</span></div>
                <div style="width: 60px; text-align: right;">x{{ item.goods_num  }}</div>
              </div>
              <div class="tags flex-wrap s12">
                <div class="tag color-blue" v-for="(item,index) in item.tags" :key="index">{{ item }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 分 -->
      <div class="form-item" style="margin-bottom: .26rem">
        <label class="form-item_label">
          <div><p class="p_label">商品评价</p></div>
        </label>
        <div class="form-item_content">
          <div class="stars flex-middle">
            <img 
              v-for="i in 5"
              :key="i"
              src="@/assets/images/icon-star-6.png"
              alt=""
              :class="{ on: i <= item.goods_rank }"
              @click="changeRank('goods_rank',i)"
            >
          </div>
          <div class="tips s14 color-9">满意请打五星哦</div>
        </div>
      </div>
      <div class="form-item" style="margin-bottom: .26rem">
        <label class="form-item_label">
          <div><p class="p_label">物流评价</p></div>
        </label>
        <div class="form-item_content">
          <div class="stars flex-middle">
            <img 
              v-for="i in 5"
              :key="i"
              src="@/assets/images/icon-star-6.png"
              alt=""
              :class="{ on: i <= item.deliver_rank }"
              @click="changeRank('deliver_rank',i)"
            >
          </div>
          <div class="tips s14 color-9">满意请打五星哦</div>
        </div>
      </div>
      <div class="form-item" style="margin-bottom: .26rem">
        <label class="form-item_label">
          <div><p class="p_label">服务评价</p></div>
        </label>
        <div class="form-item_content">
          <div class="stars flex-middle">
            <img 
              v-for="i in 5"
              :key="i"
              src="@/assets/images/icon-star-6.png"
              alt=""
              :class="{ on: i <= item.service_rank }"
              @click="changeRank('service_rank',i)"
            >
          </div>
          <div class="tips s14 color-9">满意请打五星哦</div>
        </div>
      </div>
      <!-- 用户评价 -->
      <div class="form-item">
        <label class="form-item_label">
          <div><p class="p_label">用户评价</p></div>
        </label>
        <div class="form-item_content">
          <textarea type="text" class="ipt" placeholder="请输入用户评价" v-model="item.content">
          </textarea>
        </div>
      </div>
      <!-- 图片 -->
      <div class="form-item">
        <label class="form-item_label">
          <div><p class="p_label">上传图片</p></div>
        </label>
        <div class="form-item_content">
          <BaseFile v-model="item.comment_img" placeholder="请上传图片" @change="(list) => getCommentFile(list,index)"></BaseFile>
        </div>
      </div>
    </form>
  </BaseDialog>
</template>

<script>
import { OrderDetailApi, commentOrderApi } from "@/request/api/order.js"
export default {
  props: {
    orderId:{
      type: String,
      default: ""
    }
  },
  mounted(){
    this.getDetailInfo();
  },
  data() {
    return {
      visible: true,
    
      count: 5,
      
      detailInfo: null,
    };
  },
  methods: {
    //详情
    getDetailInfo(){
      OrderDetailApi({order_id: this.orderId}).then(res => {
        if(res.status == 200){
          this.detailInfo = res.data;
          this.detailInfo.goods_list = this.detailInfo.goods_list.map((item,index) => {
            this.$set(item, 'goods_rank',0);
            this.$set(item, 'deliver_rank',0);
            this.$set(item, 'service_rank',0);
            return item;
          })
         
        }
      })
    },

    //改变评分
    changeRank(type,num){
      this.detailInfo.goods_list = this.detailInfo.goods_list.map((item) => {
        if(type == 'goods_rank'){
          item.goods_rank = num;
        }
        if(type == 'deliver_rank'){
          item.deliver_rank = num;
        }
        if(type == 'service_rank'){
          item.service_rank = num;
        }
        return item;
      })
    },
    getCommentFile(list,i){
      this.detailInfo.goods_list = this.detailInfo.goods_list.map((item,index) => {
        if(index == i){
          item.comment_img = list;
        }
        return item
      })
    },
    handleSubmit() {
      let data = [];
      this.detailInfo.goods_list = this.detailInfo.goods_list.forEach(item => {
        let obj = {};
        obj.goods_id = item.goods_id;
        obj.order_id = this.detailInfo.order_id;
        obj.goods_rank = item.goods_rank;
        obj.deliver_rank = item.deliver_rank;
        obj.service_rank = item.service_rank;
        obj.content = item.content;
        obj.comment_img = item.comment_img.length > 0 ? item.comment_img.join(",") : "";
        data.push(obj)
      })
      let params = {
        order_id: this.detailInfo.order_id,
        data: JSON.stringify(data)
      }
      commentOrderApi(params).then(res => {
        if(res.status == 200){
          this.$message.success("评价成功");
          this.$emit('confirm') // 添加成功，页面要重新加载地址列表
        }
      })
    }
  }
};
</script>

<style lang="scss" scoped>
  .form{
    padding: .3rem .3rem .2rem;
    &-item{
      display: flex; align-items: flex-start;
      margin-bottom: .2rem;
      &:last-of-type{
        margin-bottom: 0;
      }
      &_label{
        min-width: 1rem;
        min-height: .5rem;
        display: flex; align-items: center;
        line-height: 1.4em;
        position: relative;
        padding-right: .07rem;
        &.required {
          .p_label{
            position: relative;
            &:after{
              content: '*';
              color: #f53e39;
              position: absolute; right: -.5em; top: .02rem;
            }
          }
          .p_tips{
            text-align: center;
          }
        }
      }
      &_content{
        flex: 1;
        .goods{
          .tit{
            display: flex;
          }
          .text-overflow{
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
          }
        }
      }
    }
    ::v-deep .ipt{
      width: 100%; height: .5rem;
      border-radius: .04rem;
      border: solid 1px #cae1ec;
      padding: 0 .14rem;
      transition: .3s;
      &::placeholder{
        color: #999;
      }
      &:focus{
        border-color: $blue;
      }
    }
    textarea.ipt{
      display: block;
      height: 1.5rem;
      padding: .14rem .14rem;
    }
    .stars{
      height: .5rem;
      img{
        width: .26rem; margin-right: .07rem;
        opacity: .3;
        cursor: pointer;
        &.on{
          opacity: 1;
        }
      }
    }
    .tips{
      margin-top: .02rem;
    }
    .goods{
      border-radius: 0.04rem;
      border: dashed 0.01rem #cae1ec;
      padding: .1rem; padding-right: .19rem;
      &-cover{
        width: 1rem; height: 1rem;
        object-fit: cover;
        border-radius: .04rem;
        margin-right: .19rem;
      }
      .text{
        flex: 1;
        padding-top: .07rem;
      }
      .row-2{
        margin-top: .12rem;
      }
      .tags{
        margin-top: .15rem;
        .tag{
          border: 1px solid;
          border-radius: .04rem;
          margin-right: .1rem;
          padding: .01rem .04rem;
        }
      }
    }
  }
</style>