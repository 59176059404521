<template>
  <div class="base-video-dialog">
    <div class="base-video-dialog_mask"></div>
    <div class="base-video-dialog_main">
      <!-- 关闭 -->
      <img src="@/assets/images/icon-close2.png" alt="" class="close" @click="handleClose">
      <!-- 视频 -->
      <video controls :src="video" class="player" ref="player"></video>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    video: {
      type: String, default: ''
    },
    visible: {
      type: Boolean, default: false
    }
  },
  data() {
    return {};
  },
  mounted() {
    this.$refs.player.play()
  },
  methods: {
    handleClose() {
      this.$emit('close')
    }
  },
};
</script>

<style lang="scss" scoped>
.base-video-dialog{
  position: fixed; left: 0; top: 0; z-index: 1000;
  width: 100%; height: 100%;
  &_mask{
    position: absolute; left: 0; top: 0; z-index: 1;
    width: 100%; height: 100%;
    background: rgba($color: #000000, $alpha: 0.8);
  }
  &_main{
    position: absolute; left: 50%; top: 50%; z-index: 2;
    transform: translate(-50%, -50%);
    border-radius: .04rem;
    background: #000;
    .close{
      position: absolute; top: -.29rem; right: -.35rem;
      width: .21rem;
      cursor: pointer;
    }
    .player{
      display: block;
      width: 12rem; height: 6.75rem;
    }
  }
}
</style>