<template>
  <BaseDialog :visible.sync="visible" title='新增发票' :cancel='false' confirmText='保存' @close="$emit('close')"
    @confirm='handleSubmit'>
    <form class="form s16 color-2">
      <!-- 发票类型 -->
      <div class="form-item">
        <label class="form-item_label required">
          <div>
            <p class="p_label">发票类型</p>
          </div>
        </label>
        <div class="form-item_content">
          <BaseRadio class="s16" :options="list" v-model="form.type" />
        </div>
      </div>
      <!-- 发票抬头 -->
      <div class="form-item">
        <label class="form-item_label required">
          <div>
            <p class="p_label">发票抬头</p>
          </div>
        </label>
        <div class="form-item_content">
          <input type="text" class="ipt" placeholder="请输入发票抬头" v-model="form.invoice_title">
        </div>
      </div>
      <!-- 税号 -->
      <div class="form-item">
        <label class="form-item_label required">
          <div>
            <p class="p_label">税号</p>
          </div>
        </label>
        <div class="form-item_content">
          <input type="text" class="ipt" placeholder="请输入税号" v-model="form.tax_number">
        </div>
      </div>
      <!-- 税点 -->
      <div class="form-item">
        <label class="form-item_label required">
          <div>
            <p class="p_label">税点</p>
          </div>
        </label>
        <div class="form-item_content">
          <input type="text" class="ipt" placeholder="请输入税点" v-model="form.tax_rate">
        </div>
      </div>
      <!-- 地址 -->
      <div class="form-item" v-show="form.type == 1">
        <label class="form-item_label required">
          <div>
            <p class="p_label">地址</p>
          </div>
        </label>
        <div class="form-item_content">
          <input type="text" class="ipt" placeholder="请输入地址" v-model="form.address">
        </div>
      </div>
      <!-- 开户行 -->
      <div class="form-item" v-show="form.type == 1">
        <label class="form-item_label required">
          <div>
            <p class="p_label">开户行</p>
          </div>
        </label>
        <div class="form-item_content">
          <input type="text" class="ipt" placeholder="开户行" v-model="form.bank_name">
        </div>
      </div>
      <!-- 银行账号 -->
      <div class="form-item" v-show="form.type == 1">
        <label class="form-item_label required">
          <div>
            <p class="p_label">银行账号</p>
          </div>
        </label>
        <div class="form-item_content">
          <input type="text" class="ipt" placeholder="请输入银行账号" v-model="form.bank_account">
        </div>
      </div>
      <!-- 收票人手机 -->
      <div class="form-item">
        <label class="form-item_label required">
          <div>
            <p class="p_label">收票人手机</p>
          </div>
        </label>
        <div class="form-item_content">
          <input type="text" class="ipt" placeholder="请输入收票人手机" v-model="form.mobile">
        </div>
      </div>
      <!-- 收票人邮箱 -->
      <div class="form-item">
        <label class="form-item_label required">
          <div>
            <p class="p_label">收票人邮箱</p>
          </div>
        </label>
        <div class="form-item_content">
          <input type="text" class="ipt" placeholder="请输入收票人邮箱" v-model="form.email">
        </div>
      </div>
    </form>
  </BaseDialog>
</template>

<script>
import { InvoiceAddApi } from "@/request/api/order";
export default {
  created() {

  },
  data() {
    return {
      form: {
        type: 0,
        invoice_title: "",
        tax_number: "",
        tax_rate: "",
        mobile: "",
        email: "",
        address: "",
        bank_name: "",
        bank_account: "",
        is_default: 0,
      },
      visible: true,
      list: [
        { value: 0, name: '普通增值税' },
        { value: 1, name: '专用增值税' },
      ],
    };
  },
  methods: {
    handleSubmit() {
      InvoiceAddApi(this.form).then(({ status, data }) => {
        if (status == 200) {
          this.$message.success('新增成功');
          this.$emit('change') // 添加成功，页面要重新加载地址列表
        }
      })
      // this.$emit('close')

    }
  }
};
</script>

<style lang="scss" scoped>
.form {
  padding: .18rem .3rem .2rem;

  &-item {
    display: flex;
    align-items: center;
    margin-bottom: .1rem;

    &:last-of-type {
      margin-bottom: 0;
    }

    &_label {
      min-width: 1rem;
      // min-height: .5rem;
      display: flex;
      align-items: center;
      line-height: 1.4em;
      position: relative;
      padding-right: .07rem;

      &.required {
        .p_label {
          position: relative;

          &:after {
            content: '*';
            color: #f53e39;
            position: absolute;
            right: -.5em;
            top: .02rem;
          }
        }

        .p_tips {
          text-align: center;
        }
      }
    }

    &_content {
      flex: 1;
    }
  }

  ::v-deep .ipt {
    width: 100%;
    height: .5rem;
    border-radius: .04rem;
    border: solid 1px #cae1ec;
    padding: 0 .14rem;
    transition: .3s;

    &::placeholder {
      color: #999;
    }

    &:focus {
      border-color: $blue;
    }
  }

  .c-id-card {
    width: 100%;
    margin-bottom: .2rem;
  }
}
</style>