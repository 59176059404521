<template>
  <!-- 步骤 -->
  <div class="c-steps text-center">
    <div class="list flex">
      <div
        class="item"
        v-for="(item, index) in list"
        :class="{ on: index <= current }"
        :key="index"
      >
        <div class="num flex-center">
          <span class="s18 color-6 font-medium">{{index + 1}}</span>
          <img src="@/assets/images/icon-check5.png" alt="" class="icon">
        </div>
        <div class="tit s16 color-6">{{item.title}}</div>
        <div class="desc color-9" v-if="item.desc != '--/--/--'">{{item.desc}}</div>
      </div>
    </div>
    <div class="tips s14 color-9">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: { type: Array, default() { return [] } },
    current: { type: Number, default: 0 },
  },
  data() {
    return {
    };
  },
  computed: {

  },
  created() {

  },
  mounted() {

  },
  methods: {

  }
};
</script>

<style lang="scss" scoped>
.c-steps{
  .list{
    .item{
      width: 1.66rem;
      position: relative;
      &:not(:first-child):before{
        content: '';
        position: absolute; left: 0; top: .35rem;
        width: 0.6rem; height: 1px;
        transform: translateX(-50%);
        background-color: #e6f2f8;;
      }
      &.on{
        &:before{
          background: $blue;
        }
        .num{
          background: $blueLight;
          border-color: $blue;
          .icon{
            display: block;
          }
          span{
            display: none;
          }
        }
        .tit{
          color: $blue;
        }
      }
    }
    .num{
      margin: auto;
      width: .4rem; height: .4rem;
      border-radius: 50%;
      border: solid 0.01rem #cae1ec;
      .icon{
        width: .15rem;
        display: none;
      }
    }
    .tit{
      margin-top: .13rem;
    }
    .desc{
      margin-top: .06rem;
      font-size: 12px;
    }
  }
  .tips{
    margin-top: .23rem;
  }
}
</style>