<template>
  <div class="md-side s12 color-6">
    <div class="item">
      <router-link class="hd text-center" to="">
        <img src="@/assets/images/side-1.png" alt="" class="icon">
        <p>易筑APP</p>
      </router-link>
      <div class="bd">
        <div class="bd-cnt">
          <img src="@/assets/images/code2.jpg" alt="" class="img">
        </div>
      </div>
    </div>
    <div class="item">
      <router-link class="hd text-center" to="/join">
        <img src="@/assets/images/side-2.png" alt="" class="icon">
        <p>加入我们</p>
      </router-link>
    </div>
    <div class="item">
      <router-link class="hd text-center" to="/kefu">
        <img src="@/assets/images/side-3.png" alt="" class="icon">
        <p>客服</p>
      </router-link>
    </div>
    <div class="item">
      <router-link class="hd text-center" to="/feedback">
        <img src="@/assets/images/side-4.png" alt="" class="icon">
        <p>反馈</p>
      </router-link>
    </div>
    <div class="item">
      <router-link class="hd text-center" to="" @click.native="toNeed">
        <img src="@/assets/images/side-5.png" alt="" class="icon">
        <p>发布需求</p>
      </router-link>
    </div>
    <div class="item">
      <div class="hd text-center top" @click="toTop">
        <img src="@/assets/images/side-6.png" alt="" class="icon">
        <p>顶部</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    toTop() {
      window.scroll({
        top: 0,
        behavior: "smooth",
      })
    },
    toNeed(){
      this.$message.warning("暂未开放")
    }
  }
};
</script>

<style lang="scss" scoped>
.md-side{
  position: fixed; right: .17rem; top: 50%; z-index: 100;
  transform: translateY(-50%);
  .item{
    margin-bottom: .01rem;
    &:first-of-type{
      .hd{
        border-radius: .3rem .3rem 0 0;
        padding-top: .19rem;
      }
    }
    &:last-of-type{
      margin-bottom: 0;
      .hd{
        border-radius: 0 0 .3rem .3rem;
        padding-bottom: .15rem;
      }
    }
    &:hover{
      .hd:not(.top){
        background-color: $blueLight;
      }
      .bd{
        display: block;
      }
    }
  }
  .hd{
    display: block;
    font-size: 12px;
    width: .6rem; min-width: 5em;
    background: #fff;
    padding: .13rem 0 .09rem;
    transition: .3s;
    cursor: pointer;
    // filter: drop-shadow(0 0 1px #ecf5fa);
    // filter: drop-shadow(0 0 .3rem  rgba(219, 236, 255, 0.6));
    .icon{
      display: block;
      height: .2rem;
      margin: 0 auto .02rem;
    }
    &.top{
      background: $blue; color: #fff;
      &:hover{
        background: $blueDeep;
      }
    }
  }
  .bd{
    position: absolute; right: calc(100% + .17rem); top: 0;
    width: 1.5rem;
    filter: drop-shadow(0 0 .3rem  rgba(219, 236, 255, 0.6));
    display: none;
    &:after{
      content: '';
      position: absolute; right: -.2rem; top: .23rem;
      border: .1rem solid transparent;
      border-left-color: #fff;
    }
    &-cnt{
      background: #fff;
      border-radius: .04rem; overflow: hidden;
    }
    .img{
      display: block;
      width: 100%;
    }
  }
  &:hover{
    z-index: 200;
  }
}
</style>