<template>
  <BaseDialog
    :visible.sync="visible"
    title='确认收货'
    @close="$emit('close')"
    @cancel="$emit('cancel')"
    @confirm="$emit('confirm')"
  >
    <div class="wrap">
      <div class="list flex-center" v-if="detailInfo?.send_goods">
        <div class="li" v-for="(item,index) in detailInfo?.send_goods" :key="index">
          <img :src="item.goods_img">
        </div>
        <div class="li s16 color-9 flex-center">共{{ detailInfo?.send_goods.length}}件</div>
      </div>
      <div class="list flex-center" v-if="detailInfo?.goods_list">
        <div class="li" v-for="(item,index) in detailInfo?.goods_list" :key="index">
          <img :src="item.goods_image">
        </div>
        <div class="li s16 color-9 flex-center">共{{ detailInfo?.goods_list.length }}件</div>
      </div>
      <div class="s16 color-2 tips">为了保证你的售后权益，请收到商品确认无误后再确认收货</div>
    </div>
  </BaseDialog>
</template>

<script>
import { OrderDetailApi, SendLogDetailApi } from "@/request/api/order.js"
export default {
  props: {
    orderId:{
      type: String,
      default: ""
    },
    send_sn:{
      type: String,
      default: ""
    },
    type:{
      type: String,
      default: ""
    }
  },
  mounted(){
    if(this.type=="DeliveryNote"){
      this.getSendLogDetail();
    }else{
      this.getDetailInfo();
    }
  },
  data() {
    return {
      visible: true,
      detailInfo: null,

    };
  },
  methods: {
    //详情
    getDetailInfo(){
      OrderDetailApi({order_id: this.orderId}).then(res => {
        if(res.status == 200){
          this.detailInfo = res.data;
        }
      })
    },
    //获取送货单详情
    getSendLogDetail(){
      SendLogDetailApi({send_sn: this.send_sn}).then(res => {
        if(res.status == 200){
          this.detailInfo = res.data.info;
        }
      })
    },
  }
};
</script>

<style lang="scss" scoped>
.wrap{
  padding: .3rem; padding-bottom: .16rem;
  text-align: center;
  .list{
    .li{
      width: 1rem; height: 1rem;
      background: #ecf5fa;
      margin-right: .2rem;
      &:last-of-type{
        margin-right: 0;
      }
      img{
        width: 100%; height: 100%;
        object-fit: cover;
        display: block;
      }
    }
  }
  .tips{
    margin-top: .27rem;
  }
}
</style>