<template>
  <div class="c-card md-service-nav">
    <router-link
      v-for="item in list"
      :key="item.id"
      to=""
      class="item s16"
      @click.native="jump(item.id)"
    >{{ item.title }}</router-link>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default() { return [] }
    }
  },
  data() {
    return {
      current: 1
    };
  },
  methods: {
    jump(id) {
      // this.current = id
      this.$emit('change', id)
    }
  }
};
</script>

<style lang="scss" scoped>
.md-service-nav{
  display: flex; flex-wrap: wrap;
  padding: .1rem .3rem;
  margin-top: .4rem;
  .item{
    color: $blue;
    background: $blueLight;
    border-radius: .04rem;
    padding: 0 .2rem;
    margin: .2rem .2rem .2rem 0;
    line-height: .4rem;
    transition: .3s;
    &:last-of-type{
      margin-right: 0;
    }
    &.on,
    &:hover{
      background: $blue; color: #fff;
    }
  }
}
</style>