<template>
  <div class="row">
    <!-- 易筑严选 -->
    <div class="section section-1 c-card">
      <div class="s22 font-medium">易筑严选</div>
      <div class="desc s14 color-9">严选把控，质量有保障</div>
      <div class="img text-center s0">
        <img src="@/assets/images/shop.png">
      </div>
      <div class="text-center">
        <router-link class="btn hn-btn-blue-plain s16" to="/shop">进入严选商城</router-link>
      </div>
    </div>
    <!-- 榜单推荐 -->
    <div class="section section-2 c-card">
      <div class="s22 font-medium">榜单推荐</div>
      <div class="list">
        <router-link class="li flex-middle" to="/rank?type=1">
          <img src="@/assets/images/icon-1.png" class="icon">
          <div class="text">
            <div class="tit s18 font-medium">优质榜</div>
            <div class="desc s14 color-9 ep1">覆约金额领先|信用分高</div>
          </div>
        </router-link>
        <router-link class="li flex-middle" to="/rank?type=2">
          <img src="@/assets/images/icon-2.png" class="icon">
          <div class="text">
            <div class="tit s18 font-medium">热度榜</div>
            <div class="desc s14 color-9 ep1">受关注度高|平台活跃高</div>
          </div>
        </router-link>
      </div>
    </div>
    <!-- 专区 -->
    <div class="section section-3 c-card s18 font-medium">
      <router-link class="link flex-center" to="/brand?shop_type=brand">
        <img src="@/assets/images/icon-3.png"><span>品牌专区</span>
      </router-link>
      <router-link class="link flex-center" to="/brand?shop_type=selected">
        <img src="@/assets/images/icon-4.png"><span>精选专区</span>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.row {
  display: flex;
}

.section {
  flex: 1;
  margin-right: .2rem;
  min-height: 2.89rem;

  &:last-of-type {
    margin-right: 0;
  }

  &-1 {
    padding: .26rem .3rem .3rem;

    .desc {
      margin-top: .11rem;
    }

    .img {
      margin-top: .21rem;

      img {
        height: .94rem;
      }
    }

    .btn {
      display: inline-flex;
      min-width: 1.4rem;
      padding: 0 1em;
      margin: .19rem auto 0;
      border-radius: .04rem;
      line-height: calc(38 / 16 * 1em);
    }
  }

  &-2 {
    padding: .26rem .3rem .3rem;

    .list {
      margin-top: .13rem;

      .li {
        border: 1px solid #e6f2f8;
        padding: .12rem .2rem .1rem;
        min-height: .95rem;
        transition: .2s;

        &:first-of-type {
          border-radius: .04rem .04rem 0 0;
        }

        &:last-of-type {
          border-radius: 0 0 .04rem .04rem;
        }

        &:hover {
          background-color: #e5f2ff;
          border-color: #e5f2ff;
        }
      }

      .icon {
        width: .33rem;
        margin-right: .1rem;
      }

      .text {
        flex: 1;
      }

      .desc {
        margin-top: .04rem;
      }
    }
  }

  &-3 {
    padding: .29rem .3rem;

    .link {
      height: 1.09rem;
      margin-bottom: .12rem;
      border-radius: .04rem;
      border: solid 1px #e6f2f8;
      transition: .2s;

      &:last-of-type {
        margin-bottom: 0;
      }

      img {
        width: .55rem;
        margin-right: .1rem;
      }

      &:hover {
        background-image: linear-gradient(0deg, #dcedff 0%, #e6f2ff 100%);
      }
    }
  }
}
</style>