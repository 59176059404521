<template>
  <div class="md-service-form">
    <!-- 标题 -->
    <div class="c-title-2 s22" v-ani.fade-up>{{ title }}</div>

    <div class="content c-card" v-ani.fade-up>

      <form class="form s16 color-4" @submit.prevent="handleSubmit">

        <div class="form-item">
          <label class="form-item_label font-medium">品名</label>
          <div class="form-item_content">
            <BaseSelect :options="options" ipt-class='ipt' v-model="steel_name" placeholder="请选择品名" @change="nameChange">
            </BaseSelect>
          </div>
        </div>

        <div class="form-item">
          <label class="form-item_label font-medium">材质</label>
          <div class="form-item_content">
            <input type="text" class="ipt" placeholder="请输入材质" v-model="steel_type" disabled>
          </div>
        </div>

        <div class="form-item" v-if="steelProductData[indexs].a">
          <label class="form-item_label font-medium">短边宽(毫米)</label>
          <div class="form-item_content">
            <input type="text" class="ipt" placeholder="请输入" v-model="a">
          </div>
        </div>
        <div class="form-item" v-if="steelProductData[indexs].B">
          <label class="form-item_label font-medium">长边宽(毫米)</label>
          <div class="form-item_content">
            <input type="text" class="ipt" placeholder="请输入" v-model="B">
          </div>
        </div>
        <div class="form-item" v-if="steelProductData[indexs].d">
          <label class="form-item_label font-medium">直径(毫米)</label>
          <div class="form-item_content">
            <input type="text" class="ipt" placeholder="请输入" v-model="d">
          </div>
        </div>
        <div class="form-item" v-if="steelProductData[indexs].D">
          <label class="form-item_label font-medium">外径(毫米)</label>
          <div class="form-item_content">
            <input type="text" class="ipt" placeholder="请输入" v-model="D">
          </div>
        </div>
        <div class="form-item" v-if="steelProductData[indexs].h">
          <label class="form-item_label font-medium">高(毫米)</label>
          <div class="form-item_content">
            <input type="text" class="ipt" placeholder="请输入" v-model="h">
          </div>
        </div>
        <div class="form-item" v-if="steelProductData[indexs].t">
          <label class="form-item_label font-medium">边厚(毫米)</label>
          <div class="form-item_content">
            <input type="text" class="ipt" placeholder="请输入" v-model="t">
          </div>
        </div>
        <div class="form-item" v-if="steelProductData[indexs].s">
          <label class="form-item_label font-medium">对边距离(毫米)</label>
          <div class="form-item_content">
            <input type="text" class="ipt" placeholder="请输入" v-model="s">
          </div>
        </div>
        <div class="form-item" v-if="steelProductData[indexs].S">
          <label class="form-item_label font-medium">壁厚(毫米)</label>
          <div class="form-item_content">
            <input type="text" class="ipt" placeholder="请输入" v-model="S">
          </div>
        </div>
        <div class="form-item" v-if="steelProductData[indexs].r">
          <label class="form-item_label font-medium">端弧半径(毫米)</label>
          <div class="form-item_content">
            <input type="text" class="ipt" placeholder="请输入" v-model="r">
          </div>
        </div>
        <div class="form-item" v-if="steelProductData[indexs].R">
          <label class="form-item_label font-medium">内弧半径(毫米)</label>
          <div class="form-item_content">
            <input type="text" class="ipt" placeholder="请输入" v-model="R">
          </div>
        </div>
        <div class="form-item" v-if="steelProductData[indexs].ll">
          <label class="form-item_label font-medium">腿长(毫米)</label>
          <div class="form-item_content">
            <input type="text" class="ipt" placeholder="请输入" v-model="ll">
          </div>
        </div>
        <div class="form-item" v-if="steelProductData[indexs].wt">
          <label class="form-item_label font-medium">腰厚(毫米)</label>
          <div class="form-item_content">
            <input type="text" class="ipt" placeholder="请输入" v-model="wt">
          </div>
        </div>
        <div class="form-item" v-if="steelProductData[indexs].pt">
          <label class="form-item_label font-medium">平均腿厚(毫米)</label>
          <div class="form-item_content">
            <input type="text" class="ipt" placeholder="请输入" v-model="pt">
          </div>
        </div>
        <div class="form-item" v-if="steelProductData[indexs].sd">
          <label class="form-item_label font-medium">断面直径(毫米)</label>
          <div class="form-item_content">
            <input type="text" class="ipt" placeholder="请输入" v-model="sd">
          </div>
        </div>

        <div class="form-item">
          <label class="form-item_label font-medium">长度(米)</label>
          <div class="form-item_content">
            <input type="num" class="ipt" placeholder="请输入长度" v-model="l">
          </div>
        </div>

        <div class="form-item">
          <label class="form-item_label font-medium">单价(元)</label>
          <div class="form-item_content">
            <input type="num" class="ipt" placeholder="请输入单价" v-model="price">
          </div>
        </div>

        <div class="form-item form-item--full flex-center">
          <BaseButton tag='button' color='green' @click="calculate">开始计算</BaseButton>
        </div>
      </form>

      <div class="res flex-center s16 color-4">
        <div class="res-item">
          <span>理论重量：</span>
          <span class="color-green s26 font-medium">{{ kg }}</span>
          <span class="color-6">千克/米</span>
        </div>
        <div class="res-item">
          <span>总重量：</span>
          <span class="color-green s26 font-medium">{{ allKg }}</span>
          <span class="color-6">吨</span>
        </div>
        <div class="res-item">
          <span>参考总价：</span>
          <span class="color-green s26 font-medium">{{ allPrice }}</span>
          <span class="color-6">元</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: { type: String, default: '' },
  },
  data() {
    return {
      options: [
        { id: 1, name: '圆钢' },
        { id: 2, name: '盘条' },
        { id: 3, name: '螺纹钢' },
        { id: 4, name: '方钢' },
        { id: 5, name: '扁钢' },
        { id: 6, name: '六角钢' },
        { id: 7, name: '八角钢' },
        { id: 8, name: '等边角钢' },
        { id: 9, name: '不等边角钢' },
        { id: 10, name: '槽钢' },
        { id: 11, name: '工字钢' },
        { id: 12, name: '钢板' },
        { id: 13, name: '钢管' },
      ],
      steel_name: '',
      steelProductData: [
        {
          name: "圆钢",
          d: true,
          public: (d) => 0.00617 * this.d * this.d
        },
        {
          name: "盘条",
          d: true,
          public: (d) => 0.006165 * this.d * this.d
        },
        {
          name: "螺纹钢",
          sd: true,
          public: (sd) => 0.00617 * this.sd * this.sd
        },
        {
          name: "方钢",
          a: true,
          public: (a) => 0.00785 * this.a * this.a
        },
        {
          name: "扁钢",
          a: true,
          t: true,
          public: (a, t) => 0.00785 * this.a * this.t
        },
        {
          name: "六角钢",
          s: true,
          public: (s) => 0.0068 * this.s * this.s
        },
        {
          name: "八角钢",
          s: true,
          public: (s) => 0.0065 * this.s * this.s
        },
        {
          name: "等边角钢",
          a: true,
          t: true,
          r: true,
          R: true,
          public: (a, t, r, R) => 0.00785 * (this.t * (2 * this.a - this.t) + 0.215 * (this.R * 2 - 2 *
            this.r * this.r))
        },
        {
          name: "不等边角钢",
          b: true,
          B: true,
          t: true,
          r: true,
          R: true,
          public: (b, B, t, r, R) => 0.00785 * (this.t * (this.B + this.b - this.t) + 0.215 * (this.R *
            2 - 2 * this.r * this.r))
        },
        {
          name: "槽钢",
          h: true,
          ll: true,
          wt: true,
          pt: true,
          r: true,
          R: true,
          public: (h, ll, wt, pt, r, R) => 0.00785 * (this.h * this.wt + 2 * this.pt * (this.ll - this
            .wt) + 0.349 * (this.R - this.r * this.r))
        },
        {
          name: "工字钢",
          h: true,
          ll: true,
          wt: true,
          pt: true,
          r: true,
          R: true,
          public: (h, ll, wt, pt, r, R) => 0.00785 * (this.h * this.wt + 2 * this.pt * (this.ll - this
            .wt) + 0.615 * (this.R * this.R - this.r * this.r))
        },
        {
          name: "钢板",
          t: true,
          public: (t) => 7.85 * this.t
        },
        {
          name: "钢管",
          D: true,
          S: true,
          public: (D, S) => 0.02466 * this.S * (this.D - this.S)
        }],
      indexs: 0,
      steel_type: '钢材',
      a: '', //边宽
      b: '', //短边宽
      B: '', //长边宽
      d: '', //直径
      D: '', //外径
      h: '', //高
      t: '', //边厚
      s: '', //对边距离
      S: '', //壁厚
      r: '', //端弧半径
      R: '', //内弧半径
      ll: '', //腿长
      wt: '', //腰厚
      pt: '', //平均腿厚
      sd: '', //断面直径
      l: '', //长度(米)
      price: "",
      kg: '0.000',
      allKg: '0.000',
      allPrice: '0.00',
    };
  },
  methods: {
    nameChange(e) {
      this.indexs = e - 1;
    },
    // 计算
    calculate() {
      let kg = this.steelProductData[this.indexs].public();
      kg = kg < 0 ? 0 : kg;
      this.kg = kg.toFixed(3);
      console.log(this.steelProductData[this.indexs], this.allKg, this.allPrice);
      // this.allKg = (kg * this.num).toFixed(2);
      // this.allPrice = (kg * this.num * this.price).toFixed(2);
      this.allKg = (kg * this.l / 1000).toFixed(3);
      this.allPrice = (this.allKg * this.price).toFixed(2);
      if (Number(this.allPrice) == 0) {
        this.$message.warning('请输入全部参数在计算!');
      }
    },
    handleSubmit() { }
  }
};
</script>

<style lang="scss">
.md-service-form {
  margin-top: .35rem;

  .content {
    padding: .3rem 0;
    margin-top: 0.15rem;
  }

  .form {
    display: flex;
    flex-wrap: wrap;

    &-item {
      display: flex;
      align-items: center;
      width: calc((125 + 308) / 1350 * 100%);
      margin-right: calc(10 / 1350 * 100%);
      margin-bottom: .2rem;

      &_label {
        width: 1.25rem;
        min-height: .4rem;
        padding: 0 .2rem 0 .05rem;
        display: inline-flex;
        align-items: center;
        justify-content: flex-end;
      }

      &_content {
        flex: 1;
      }

      &--full {
        width: 100%;
      }
    }

    .ipt {
      width: 100%;
      height: .4rem;
      border-radius: .04rem;
      border: solid 1px #cae1ec;
      padding: 0 .12rem;

      &::placeholder {
        color: #999;
      }
    }

    .base-button {
      width: 1.1rem;
    }
  }

  .res {
    background: #ecf9ef;
    border: solid 1px rgba($color: #2ba743, $alpha: .1);
    border-radius: .04rem;
    margin: 0 .3rem;
    height: 1.15rem;

    &-item {
      padding: 0 .35rem;
      position: relative;

      &:after {
        content: '';
        position: absolute;
        right: 0;
        top: 50%;
        width: 1px;
        height: .16rem;
        background-color: #daf2de;
        transform: translateY(-50%);
      }
    }
  }
}
</style>