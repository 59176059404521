<template>
  <div class="logo-list c-card">
    <div class="tit font-medium s18">平台合作供应商</div>
    <div class="list">
      <swiper :options="swiperOption" class="swiper">
        <template v-for="(item,index) in listArr">
          <swiper-slide :key="index" class="slide">
            <router-link class="li" to="" target="">
              <ImgRatio :src="item" :width="160" :height="90" fit="cover" />
            </router-link>
          </swiper-slide>
        </template>
      </swiper>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    listArr: { type: Array, default: () => [] }
  },
  data() {
    return {
      logoList: [
        { id: 1, img: require('@/assets/images/logo-1.jpg'), link: '', target: '' },
        { id: 2, img: require('@/assets/images/logo-2.jpg'), link: '', target: '' },
        { id: 3, img: require('@/assets/images/logo-3.jpg'), link: '', target: '' },
        { id: 4, img: require('@/assets/images/logo-4.jpg'), link: '', target: '' },
        { id: 5, img: require('@/assets/images/logo-5.jpg'), link: '', target: '' },
        { id: 6, img: require('@/assets/images/logo-5.jpg'), link: '', target: '' },
      ],
      swiperOption: {
        autoplay: 3000,
        slidesPerView: 5,
        loop: true,
        spaceBetween: 12
      }
    };
  },
};
</script>

<style lang="scss" scoped>
.logo-list {
  margin-top: 0.2rem;
  padding: .26rem .3rem .3rem;

  .list {
    // display: flex;
    margin-top: .16rem;

    .li {

      // flex: 1;
      // margin-right: .12rem;
      // &:last-of-type{
      //   margin-right: 0;
      // }
      &:hover {
        .img {
          transform: scale(1.1);
        }
      }
    }

    .c-pic {
      border: 1px solid #e6f2f8;
      border-radius: .04rem;
    }
  }
}</style>