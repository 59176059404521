<template>
  <!-- 买家退货 -->
  <BaseDialog
    :visible.sync="visible"
    title='发货信息'
    width="10.63rem"
    confirm-text='发货'
    @close="$emit('close')"
    @cancel="$emit('cancel')"
    @confirm="$emit('confirm')"
  >
    <form class="form s16 color-2">
      <div class="title-1 flex-middle">
        <div class="t t1 color-2">选择商品</div>
        <div class="t t2 color-9">待发货 1</div>
        <div class="t t2 color-9">已发货 0</div>
      </div>
      <!-- 商品列表 -->
      <table class="table s16">
        <thead class="color-4">
          <tr>
            <th>
              <BaseSwitch v-model="checkAll" @change="handleCheckAll"></BaseSwitch>
            </th>
            <th>商品</th>
            <th>数量</th>
            <th>状态</th>
            <th>发货方式</th>
            <th>运单号</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in list" :key="item.id">
            <td>
              <BaseSwitch v-model="item.check" @change="handleCheck"></BaseSwitch>
            </td>
            <td>
              <div class="goods flex-middle">
                <img :src="item.cover" alt="" class="goods-cover">
                <div class="s16 color-4 text lh30">
                  <div>
                    <span class="color-9">商品标题：</span>
                    <span>{{item.title}}</span>
                  </div>
                  <div>
                    <span class="color-9">规格：</span>
                    <span>{{item.attr}}</span>
                  </div>
                </div>
              </div>
            </td>
            <td>{{item.productNumber}}</td>
            <td>{{item.status}}</td>
            <td>/</td>
            <td>{{item.no}}</td>
          </tr>
        </tbody>
      </table>
      <!-- 收货信息 -->
      <div class="form-item form-item--address">
        <label class="form-item_label">
          <div><p class="p_label">收货信息</p></div>
        </label>
        <div class="form-item_content">
          <div class="form-item_content color-9 lh30">
            <div>配送方式：货拉拉</div>
            <div>期望到货时间：2023-04-04 14:00-15:00</div>
            <div>收货人：张三  15393710180</div>
            <div>收货地址：广东省广州市  150大街xx店铺</div>
          </div>
        </div>
      </div>
      <!-- 配送方式 -->
      <div class="form-item form-item--way">
        <label class="form-item_label">
          <div><p class="p_label">配送方式</p></div>
        </label>
        <div class="form-item_content">
          <div class="form-item_content">
          <BaseRadio :options='options' v-model="way"></BaseRadio>
        </div>
        </div>
      </div>
      <!-- 货拉拉订单号 -->
      <div class="form-item" v-show="way == 1">
        <label class="form-item_label">
          <div><p class="p_label">货拉拉订单号</p></div>
        </label>
        <div class="form-item_content">
          <div class="form-item_content">
            <input type="text" class="ipt" placeholder="请输入货拉拉订单号">
          </div>
        </div>
      </div>
      <!-- 联系人 -->
      <div class="form-item" v-if="way != 3">
        <label class="form-item_label">
          <div><p class="p_label">联系人</p></div>
        </label>
        <div class="form-item_content">
          <div class="form-item_content">
            <input type="text" class="ipt" placeholder="请输入联系人">
          </div>
        </div>
      </div>
      <!-- 联系电话 -->
      <div class="form-item" v-if="way != 3">
        <label class="form-item_label">
          <div><p class="p_label">联系电话</p></div>
        </label>
        <div class="form-item_content">
          <div class="form-item_content">
            <input type="text" class="ipt" placeholder="请输入联系电话">
          </div>
        </div>
      </div>
      <!-- 车牌号 -->
      <div class="form-item" v-if="way != 3">
        <label class="form-item_label">
          <div><p class="p_label">车牌号</p></div>
        </label>
        <div class="form-item_content">
          <div class="form-item_content">
            <input type="text" class="ipt" placeholder="请输入车牌号">
          </div>
        </div>
      </div>
      <!-- 快递单号 -->
      <div class="form-item" v-if="way == 3">
        <label class="form-item_label">
          <div><p class="p_label">快递单号</p></div>
        </label>
        <div class="form-item_content">
          <div class="form-item_content">
            <input type="text" class="ipt" placeholder="请输入快递单号">
          </div>
        </div>
      </div>
      <!-- 快递公司 -->
      <div class="form-item" v-if="way == 3">
        <label class="form-item_label">
          <div><p class="p_label">快递公司</p></div>
        </label>
        <div class="form-item_content">
          <div class="form-item_content">
            <BaseSelect :options='options2' v-model="company" ipt-class='ipt' placeholder="请选择快递公司"></BaseSelect>
          </div>
        </div>
      </div>
      <!-- 发货地址 -->
      <div class="form-item" v-if="way == 3">
        <label class="form-item_label">
          <div><p class="p_label">发货地址</p></div>
        </label>
        <div class="form-item_content">
          <div class="form-item_content">
            <BaseSelect :options='options3' v-model="address" ipt-class='ipt' placeholder="请选择发货地址"></BaseSelect>
          </div>
        </div>
      </div>
    </form>
  </BaseDialog>
</template>

<script>
export default {
  data() {
    return {
      visible: true,
      way: '',
      options: [
        { value: 1, name: '货拉拉配送' },
        { value: 2, name: '商家自送' },
        { value: 3, name: '其他物流' },
      ],
      options2: [
        { value: 1, name: '顺丰' },
        { value: 2, name: '顺丰' },
        { value: 3, name: '顺丰' },
        { value: 4, name: '顺丰' },
        { value: 5, name: '顺丰' },
        { value: 6, name: '顺丰' },
        { value: 7, name: '顺丰' },
      ],
      company: '',
      address: '',
      options3: [
        { value: 1, name: '广州。。。。。' },
      ],
      checkAll: false,
      list: [
        {
          id: 1,
          cover: require('@/assets/images/prod-4.jpg'),
          title: '泳池水下灯',
          attr: '50*50',
          productNumber: 1,
          check: false,
          status: '待发货',
          no: '123456789123456789',
          check: false
        }
      ]
    };
  },
  methods: {
    handleSubmit() {
      this.$emit('confirm') // 添加成功，页面要重新加载地址列表
    },
    handleCheckAll() {
      this.list.forEach(el => el.check = this.checkAll)
    },
    handleCheck() {
      this.checkAll = this.list.every(el => el.check)
    }
  }
};
</script>

<style lang="scss" scoped>
  .form{
    padding: .26rem .3rem .2rem;
    .lh30{
      line-height: calc(30 / 16 * 1em);
    }
    .title-1{
      .t{
        margin-right: .2rem;
      }
    }
    &-item{
      display: flex; align-items: flex-start;
      margin-bottom: .2rem;
      &:last-of-type{
        margin-bottom: 0;
      }
      &_label{
        min-width: 1.06rem;
        min-height: .5rem;
        display: flex; align-items: center;
        line-height: 1.4em;
        position: relative;
        padding-right: .07rem;
        &.required {
          .p_label{
            position: relative;
            &:after{
              content: '*';
              color: #f53e39;
              position: absolute; right: -.5em; top: .02rem;
            }
          }
          .p_tips{
            text-align: center;
          }
        }
      }
      &_content{
        flex: 1;
      }
      &--address{
        margin-bottom: .1rem;
        .form-item_label{
          min-height: auto;
          line-height: .3rem;
        }
      }
      &--way{
        margin-bottom: .1rem;
        .form-item_label{
          min-height: auto;
          line-height: .3rem;
        }
      }
    }
    ::v-deep .ipt{
      width: 100%; height: .5rem;
      border-radius: .04rem;
      border: solid 1px #cae1ec;
      padding: 0 .14rem;
      transition: .3s;
      &::placeholder{
        color: #999;
      }
      &:focus{
        border-color: $blue;
      }
    }
  }
  .goods{
    text-align: left;
    &-cover{
      width: .6rem; height: .6rem;
      object-fit: cover;
      border-radius: .04rem;
      margin-right: .16rem;
    }
    .text{
      flex: 1;
    }
    .row-2{
      margin-top: .12rem;
    }
  }
  .table{
    width: 100%;
    text-align: center;
    margin: .16rem 0 .23rem;
    border-radius: 0.04rem 0.04rem 0rem 0rem; overflow: hidden;
    thead{
      height: 0.49rem;
      background-color: #ecf5fa;
      border: solid 0.01rem #dfeef6; 
      th{
        padding: 0 .3rem;
        font-weight: normal;
      }
    }
    tbody{
      td{
        border: solid 0.01rem #e6f2f8;
        min-height: 1.22rem;
        padding: .3rem;
      }
    }
  }
</style>