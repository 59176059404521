<template>
  <div class="c-card md-filter flex-middle">
    <!-- 排序 -->
    <div class="sort s16 color-4">
      <template v-for="item in list">
        <div class="sort-item flex-middle" :key="item.id" :class="{ on: sort_field == item.name }"
          @click="handleClick(item)">
          <span class="tit">{{ item.title }}</span>
          <span class="icon-1" v-if="item.type == 1"></span>
          <span class="icon-2" v-if="item.type == 2" :class="{
            'icon-up': item.rule == 'asc',
            'icon-down': item.rule == 'desc',
          }"></span>
        </div>
      </template>
    </div>
    <!-- 价格区间 -->
    <div class="price flex-middle s16">
      <input class="ipt" type="number" placeholder="￥最低价" v-model="min" @change="update">
      <span></span>
      <input class="ipt" type="number" placeholder="￥最高价" v-model="max" @change="update">
    </div>
    <!-- 仅看官方 -->
    <label v-if="is_official" class="switch s16 color-4 flex-middle" :class="{ on: is_official }" @click="handleOfficial">
      <div class="icon"></div>
      <span>仅看官方</span>
    </label>
    <!-- 总数 -->
    <div class="all s16 color-4">共{{ totalNum }}+商品</div>
  </div>
</template>

<script>
export default {
  props: {
    official: { type: Boolean, default: true },
    totalNum: { type: Number, default: 0 }
  },
  data() {
    return {
      sort_field: "auto",
      sort_rule: "desc",
      orderId: "",
      list: [
        { id: 1, title: '综合', name: 'auto', type: 1, rule: 'desc' },
        { id: 2, title: '销量', name: 'sales', type: 2, rule: 'desc' },
        { id: 3, title: '评论数', name: 'comments', type: 2, rule: 'desc' },
        { id: 4, title: '价格', name: 'price', type: 2, rule: 'asc' },
      ],
      min: '',
      max: '',
      is_official: false
    };
  },
  methods: {
    handleClick(item) {
      if (item.type == 2 && this.sort_field == item.name) {
        item.rule = item.rule == 'asc' ? 'desc' : 'asc'
      }
      this.sort_field = item.name;
      this.sort_rule = item.rule;
      this.orderId = item.id;
      this.update()
    },
    handleOfficial() {
      this.is_official = !this.is_official
      this.update()
    },
    update() {
      let res = {
        // is_official: this.is_official,
        min_price: this.min,
        max_price: this.max,
        order: this.orderId,
        sort: this.sort_rule
      }
      this.$emit('change', res)
    }
  }
};
</script>

<style lang="scss" scoped>
.md-filter {
  padding: .3rem;
  margin-top: .2rem;

  .all {
    margin-left: auto;
  }

  .sort {
    display: flex;
    user-select: none;

    &-item {
      height: .4rem;
      border-radius: .04rem;
      padding: 0 .19rem;
      cursor: pointer;
      margin-right: .1rem;

      &.on {
        color: $blue;
        background-color: $blueLight;

        .icon-1 {
          background-image: url(~@/assets/images/icon-down4.png);
        }

        .icon-up {
          background-image: url(~@/assets/images/icon-sort-1.png);
        }

        .icon-down {
          background-image: url(~@/assets/images/icon-sort-2.png);
        }
      }

      &:hover {
        color: $blue;
      }
    }

    .icon-1 {
      width: 0.09rem;
      height: .12rem;
      margin-left: .03rem;
      background: url(~@/assets/images/icon-down3.png) no-repeat center / contain;
    }

    .icon-2 {
      width: 0.09rem;
      height: .13rem;
      margin-left: .04rem;
      background: url(~@/assets/images/icon-sort-0.png) no-repeat center / contain;
    }
  }

  .price {
    .ipt {
      width: .92rem;
      height: .4rem;
      border-radius: .04rem;
      border: solid 1px #cae1ec;
      text-align: center;

      &::-webkit-inner-spin-button {
        display: none;
      }

      &::placeholder {
        color: #666;
      }

      &:focus {
        border-color: $blue;
      }
    }

    span {
      height: 1px;
      width: .1rem;
      background: $blue;
      margin: 0 .1rem;
    }
  }

  .switch {
    margin-left: .2rem;
    cursor: pointer;

    .icon {
      width: 0.14rem;
      height: .14rem;
      border-radius: 50%;
      margin-right: .06rem;
      border: 1px solid #666;
    }

    &:hover {
      color: $blue;
    }

    &.on {
      color: $blue;

      .icon {
        border-color: $blue;
        border-width: .04rem;
      }
    }
  }
}
</style>