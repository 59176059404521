<template>
  <div class="home-form c-card">
    <div class="tit s18 font-medium">免费发布需求</div>
    <form class="form s16" @submit.prevent="handleSubmit">
      <div class="form-item">
        <input type="text" placeholder="* 联系人姓名" class="ipt" v-model="form.username" />
      </div>
      <div class="form-item">
        <input type="text" placeholder="* 联系手机号" v-model="form.mobile" class="ipt" />
      </div>
      <div class="form-item form-item--code">
        <input type="text" placeholder="* 手机号验证码" class="ipt" v-model="form.code" />
        <span class="btn-code s14 hn-btn-blue-light" @click="getCode">{{btnText}}</span>
      </div>
      <div class="form-item">
        <BaseSelect
          placeholder="* 项目类型"
          :options="industryType"
          v-model="form.industry_type_id"
          name='projectType'
          ipt-class="select"
          placeholder-class="empty"
          @change="getTypeId"
        />
      </div>
      <div class="form-item">
        <input type="text" placeholder="* 项目地址" v-model="form.address" class="ipt" />
      </div>
      <button class="hn-btn-blue btn-submit" @click="freeDemandAdd">立即发布</button>
    </form>
    <div class="list s14 color-9">
      <template v-for="item in list">
        <div class="li flex-middle" :key="item.id">
          <img :src="item.img" alt="" class="icon">
          <span>{{ item.title }}</span>
          <div class="pop">
            <div class="pop-cnt">
              <div class="flex-middle tit s18 color-blue font-medium">
                <img :src="item.img2" alt="" class="img">
                <span>{{ item.title }}</span>
              </div>
              <div class="text s14 color-9" v-html="item.text"></div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { industryTypeApi, freeDemandAddApi } from "@/request/api/user";
import { sendSmsApi } from "@/request/api/login"
export default {
  data() {
    return {
      type: '',
      list: [
        {
          id: 1, title: '担保交易',
          img: require('@/assets/images/icon-5.png'),
          img2: require('@/assets/images/icon-17.png'),
          text: `
            平台对买卖双方的交易进行担保，让买卖双方可以放心的在平台进行供采交易。
            买家下单后，平台担保卖家将按照订单保质保量供货。
            账期供应的订单，平台对卖方的回款提供担保，如买方逾期未支付账期款，在卖方无投诉无争议的前提下平台将在6个月后无责偿付给卖家相关货款。
          `
        }, {
          id: 2, title: '易筑严选',
          img: require('@/assets/images/icon-7.png'),
          img2: require('@/assets/images/icon-18.png'),
          text: "易筑广泛参考建材价格大数据及综合考量，选出的一批具有较高性价比、材料品质和商家服务皆优的建材产品。"
        }, {
          id: 3, title: '全程监督',
          img: require('@/assets/images/icon-6.png'),
          img2: require('@/assets/images/icon-16.png'),
          text: '易筑通过完善的交易管理制度，通过买卖双方严格的身份认证、信誉等级管理制度、物流配送管理制度等，对买卖双方的每一笔交易进行全程监管，保障交易过程的安全与顺畅。'
        }
      ],
      btnText: '获取验证码',
      timer: '',
      loading: false,

      industryType: [],

      form: {
        username: "",
        mobile: "",
        code: "",
        industry_type_id: "",
        address: ""
      }
    };
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  mounted(){
    this.getProjectType();
  },
  methods: {
    handleSubmit(e) {
      console.log(e);
    },
    // getCode() {
    //   if (!this.loading) {
    //     this.loading = true
    //     clearInterval(this.timer)
    //     // this.$axios.get('').then()
    //     this.btnText = '60s后重试'
    //     let num = 60
    //     this.timer = setInterval(() => {
    //       num--
    //       if (num < 0) {
    //         clearInterval(this.timer)
    //         this.btnText = '获取验证码'
    //       } else {
    //         this.btnText = num + 's后重试'
    //       }
    //     }, 1000);
    //   }
    // },
    getCode() {
      if (!this.form.mobile) {
        return
      }
      let params = {
        type: 1,
        mobile: this.form.mobile
      }
      sendSmsApi(params).then(res => {
        if (res.status == 200) {
          this.$message.success("验证码已发送");
          var time = 60
          this.timer = setInterval(() => {
            if (time == 0) {
              clearInterval(this.timer)
              this.btnText = '发送验证码'
            } else {
              this.btnText = time + '秒后重试'
              time--;
            }
          }, 1000)
        }
      })
    },
    //获取项目类型
    getProjectType(){
      industryTypeApi().then(res => {
        if(res.status == 200){
          res.data.forEach(item => {
            let obj = {
              id: item.id,
              name: item.name
            }
            this.industryType.push(obj)
          })
        }
      })
    },
    getTypeId(val){
      this.form.industry_type_id = val;
    },
    //校验
    validate() {
      if (!this.form.username) {
        this.$message.warning("请填写联系人姓名")
        return false
      }
      if (!this.form.mobile) {
        this.$message.warning("请填写联系手机号")
        return false
      }
      if (!this.form.code) {
        this.$message.warning("请填写手机号验证码")
        return false
      }
      if (!this.form.industry_type_id) {
        this.$message.warning("请填写项目类型")
        return false
      }
      if (!this.form.address) {
        this.$message.warning("请填写项目地址")
        return false
      }
      return true
    },
    //发布需求
    freeDemandAdd(){
      if(!this.validate()) return;
      freeDemandAddApi({...this.form}).then(res => {
        if(res.status == 200){
          this.$message.success("发布成功");
          this.form = {
            username: "",
            mobile: "",
            code: "",
            industry_type_id: "",
            address: ""
          }
        }
      })
    }
  }
};
</script>

<style lang="scss" scoped>
.home-form {
  padding: .28rem .3rem .3rem;
  height: 100%;
  position: relative; z-index: 101;
  .form{
    margin-top: 0.16rem;
  }
  ::v-deep .form-item{
    height: .5rem;
    border-radius: .04rem;
    border: solid 1px #e6f2f8;
    margin-bottom: .1rem;
    .ipt, .select{
      width: 100%;
      height: 100%;
      border: none;
      background: transparent;
      padding: 0 .12rem;
      &::placeholder{
        color: #999;
      }
    }
    .empty{
      color: #999;
    }
    .select{
      background: url(~@/assets/images/icon-down.png) right .14rem center no-repeat;
    }
    &--code{
      display: flex;
      .ipt{
        flex: 1;
      }
      .btn-code{
        line-height: .5rem;
        padding: 0 .1rem;
        cursor: pointer;
      }
    }
  }
  .btn-submit {
    border: none;
    width: 100%;
    height: .5rem;
    border-radius: .04rem;
    cursor: pointer;
  }
  .list{
    margin-top: .21rem;
    display: flex; justify-content: space-between;
    .icon{
      height: .15rem; min-height: 10px;
    }
    .li{
      position: relative;
      &:hover{
        .pop{
          display: block;
        }
      }
    }
    .pop{
      position: absolute; left: 0; top: 100%; z-index: 100;
      width: 2.28rem;
      filter: drop-shadow(0 0 .3rem rgba(219, 236, 255, 0.8));
      padding-top: .1rem;
      display: none;
      &:before{
        content: '';
        position: absolute; left: 0.28rem; top: -.1rem;
        border: .1rem solid transparent;
        border-bottom-color: #fff;
      }
      &-cnt{
        background: #fff;
        border-radius: .04rem; overflow: hidden;
        padding: .28rem .3rem .23rem;
      }
      .tit{
        img{
          height: .18rem; min-height: 1em;
          margin-right: .04rem;
        }
      }
      .text{
        line-height: calc(24 / 14 * 1em);
        margin-top: .11rem;
      }
    }
  }
}
</style>