<template>
  <BaseDialog :visible.sync="visible" title='新增项目地址' :cancel='false' confirmText='保存项目管理地址' width="11.8rem"
    @close="$emit('close')" @confirm='handleSubmit'>
    <form class="form s16 color-2">
      <!-- 工程名称 -->
      <div class="form-item">
        <label class="form-item_label required">
          <div>
            <p class="p_label">工程名称</p>
          </div>
        </label>
        <div class="form-item_content">
          <div class="form-item_content">
            <input type="text" class="ipt" placeholder="请输入工程名称" v-model="form.project_name">
          </div>
        </div>
      </div>
      <!-- 购买方公司 -->
      <div class="form-item">
        <label class="form-item_label required">
          <div>
            <p class="p_label">购买方公司</p>
          </div>
        </label>
        <div class="form-item_content">
          <input type="text" class="ipt" placeholder="请输入购买方公司" v-model="form.company_name">
        </div>
      </div>
      <!-- 营业执照 -->
      <div class="form-item">
        <label class="form-item_label required">
          <div>
            <p class="p_label">营业执照</p>
          </div>
        </label>
        <div class="form-item_content">
          <BaseFile v-model="licenseArr" placeholder="请上传营业执照" :max='1' @change="licenseChange"></BaseFile>
        </div>
      </div>
      <!-- 业主单位 -->
      <div class="form-item">
        <label class="form-item_label required">
          <div>
            <p class="p_label">业主单位</p>
          </div>
        </label>
        <div class="form-item_content">
          <input type="text" class="ipt" placeholder="请输入业主单位" v-model="form.owner_unit">
        </div>
      </div>
      <!-- 总包单位 -->
      <div class="form-item">
        <label class="form-item_label required">
          <div>
            <p class="p_label">总包单位</p>
          </div>
        </label>
        <div class="form-item_content">
          <input type="text" class="ipt" placeholder="请输入总包单位" v-model="form.winning_unit">
        </div>
      </div>
      <!-- 分包单位 -->
      <div class="form-item">
        <label class="form-item_label required">
          <div>
            <p class="p_label">分包单位</p>
          </div>
        </label>
        <div class="form-item_content">
          <input type="text" class="ipt" placeholder="请输入分包单位" v-model="form.shall_unit">
        </div>
      </div>
      <!-- 收货地址 -->
      <div class="form-item">
        <label class="form-item_label required">
          <div>
            <p class="p_label">收货地址</p>
          </div>
        </label>
        <div class="form-item_content">
          <input type="text" class="ipt" placeholder="请输入收货地址" v-model="form.project_address">
        </div>
      </div>
      <!-- 收货方 -->
      <div class="form-item">
        <label class="form-item_label required">
          <div>
            <p class="p_label">收货方</p>
          </div>
        </label>
        <div class="form-item_content">
          <input type="text" class="ipt" placeholder="请输入收货方" v-model="form.project_consignee">
        </div>
      </div>
      <!-- 联系人电话 -->
      <div class="form-item">
        <label class="form-item_label required">
          <div>
            <p class="p_label">联系人电话</p>
          </div>
        </label>
        <div class="form-item_content">
          <input type="text" class="ipt" placeholder="请输入联系人电话" v-model="form.project_phone">
        </div>
      </div>
      <!-- 身份证号码 -->
      <div class="form-item">
        <label class="form-item_label required">
          <div>
            <p class="p_label">身份证号码</p>
          </div>
        </label>
        <div class="form-item_content">
          <input type="text" class="ipt" placeholder="请输入身份证号码" v-model="form.id_card_no">
        </div>
      </div>
      <!-- 身份证图片 -->
      <div class="form-item form-item--idcard">
        <label class="form-item_label required">
          <div>
            <p class="p_label">身份证图片</p>
          </div>
        </label>
        <div class="form-item_content flex-between">
          <BaseIdCard type='front' v-model="idcard_1" @change="forntChange"></BaseIdCard>
          <BaseIdCard type='back' v-model="idcard_2" @change="backChange"></BaseIdCard>
        </div>
      </div>
    </form>
  </BaseDialog>
</template>

<script>
import { PurchaseAddApi } from "@/request/api/order.js";
export default {
  data() {
    return {
      form: {
        project_name: "",
        company_name: "",
        owner_unit: "",
        shall_unit: "",
        winning_unit: "",
        project_consignee: "",
        project_phone: "",
        project_address: "",
        id_card_no: "",
        license_img: "",
        id_card_img: [],
      },
      visible: true,
      licenseArr: [],
      idcard_1: '',
      idcard_2: '',
    };
  },
  methods: {
    // 营业执照改变
    licenseChange(arr) {
      this.form.license_img = arr[0];
      this.licenseArr = arr;
    },
    // 身份证正面
    forntChange(str) {
      this.form.id_card_img[0] = str;
    },
    // 身份证反面
    backChange(str) {
      this.form.id_card_img[1] = str;
    },
    handleSubmit() {
      PurchaseAddApi(this.form).then(({ status, data }) => {
        if (status == 200) {
          this.$message.success('新增成功');
          setTimeout(() => {
            this.$emit('close')
          }, 1000);
        }
      })

      // this.$emit('update') // 添加成功，页面要重新加载地址列表
    }
  }
};
</script>

<style lang="scss" scoped>
.form {
  padding: .3rem .3rem .2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  &-item {
    display: flex;
    align-items: flex-start;
    margin-bottom: .2rem;
    width: 5.4rem;

    &:last-of-type {
      margin-bottom: 0;
    }

    &_label {
      min-width: 1rem;
      min-height: .5rem;
      display: flex;
      align-items: center;
      line-height: 1.4em;
      position: relative;
      padding-right: .07rem;

      &.required {
        .p_label {
          &:after {
            content: '*';
            color: #f53e39;
          }
        }

        .p_tips {
          text-align: center;
        }
      }
    }

    &_content {
      flex: 1;
    }

    &--idcard {
      display: block;
      width: 100%;

      .c-id-card {
        width: 5.4rem;
      }
    }
  }

  ::v-deep .ipt {
    width: 100%;
    height: .5rem;
    border-radius: .04rem;
    border: solid 1px #cae1ec;
    padding: 0 .14rem;
    transition: .3s;

    &::placeholder {
      color: #999;
    }

    &:focus {
      border-color: $blue;
    }
  }
}
</style>