// 配置参考 https://www.axios-http.cn/
import axios from 'axios'
// 自定义实例默认值
const instance = axios.create({
    baseURL: '',
    timeout: 5000,
});
// 默认值
instance.defaults.baseURL = '';
// 添加请求拦截器
instance.interceptors.request.use(function (config) {
    // 在发送请求之前做些什么
    // token
    // const token = localStorage.getItem('token')
    // if (token) {
    //   config.headers.Authorization = 'Bearer ' + token
    // }
    return config;
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
});

// 添加响应拦截器
instance.interceptors.response.use(function (response) {
    // 2xx 范围内的状态码都会触发该函数。
    // 对响应数据做点什么
    return response;
}, function (error) {
    // 超出 2xx 范围的状态码都会触发该函数。
    // 对响应错误做点什么
    const { response, config } = error
    // token验证失败
    if (response.status == 401) {
        // 重新获取token
    }
    return Promise.reject(error);
});

export default instance