<template>
  <div class="md-pagination s16 color-6 flex-center">
    <!-- <a href="" class="item flex-center first" :class="{ disabled: firstDisabled }" @click.prevent='getLink(1)'>首页</a> -->
    <!-- :class="{ disabled: firstDisabled }" -->
    <a href="" class="item flex-center prev"  @click.prevent="getLink(_page - 1)">
      <span class="icon"></span>
      <span>上一页</span>
    </a>
    <template v-for="item in numList">
      <!-- 省略号 -->
      <p class="item flex-center num ell" :key='item.value' v-if="item.type === 'more'">...</p>
      <!-- 数字 -->
      <a
        v-else
        href=""
        class="item flex-center num"
        :class="{ on: item.value === _page }"
        :key="item.value"
        @click.prevent="getLink(item.value)"
      >
        {{ item.value }}
      </a>
    </template>
    <a href="" class="item flex-center next" :class="{ disabled: lastDisabled }" @click.prevent="getLink(_page + 1)">
      <span>下一页</span>
      <span class="icon"></span>
    </a>
    <!-- <a href="" class="item flex-center last" :class="{ disabled: lastDisabled }" @click.prevent="getLink(totalPages)">尾页</a> -->
    <div class="status"><span class="color-blue">{{ _page }}</span>/{{ totalPages }}</div>
    <div class="jump color-9">
      <span>到第</span>
      <input type="number" class="item" v-model="jumpPage" @change="confirmClick" :max="totalPages" :min="1">
      <span>页</span>
    </div>
    <div class="item flex-center pointer comfirm" @click="confirmClick">确定</div>
  </div>
</template>
<script>
export default {
  name: 'Pagination',
  props: {
    page: {
      type: Number,
      default: 1
    },
    size: {
      type: Number,
      default: 10
    },
    total: {
      type: Number,
      default: 0,
      require: true
    },
    pagerCount: {
      type: Number,
      default: 15,
    }
  },
  data() {
    return {
      _page: 1,
      jumpPage: '',
      numList: []
    }
  },
  computed: {
    totalPages() {
      return Math.ceil(this.total / this.size);
    },
    firstDisabled() {
      return this._page === 1
    },
    lastDisabled() {
      return this._page === this.totalPages
    },
  },
  watch: {
    page(val) {
      this.jumpPage = this._page = val
      this.getList()
    }
  },
  created() {
    this.jumpPage = this._page = this.page
    this.getList()
  },
  methods: {
    getList() {
      var totalPages = this.totalPages;
      var count = this.pagerCount - 1 // 减去当前页
      var page = this._page
      var leftMaxCount = count / 2 // 左边先填充一半的个数
      var rightMaxCount = count / 2 // 右边先填充一半的个数
      var list = [{ value: page } ]; // 先放当前页
      // 1. 填充左边
      var i = page - 1
      while(leftMaxCount > 0 && i >= 1) {
        list.unshift({value: i})
        i--;
        leftMaxCount--;
      }
      // 2. 填充右边
      i = page + 1
      rightMaxCount += leftMaxCount
      while(rightMaxCount > 0 && i <= totalPages) {
        list.push({ value: i})
        i++;
        rightMaxCount--;
      }
      // 3. 前面个数不够
      if (list.length < totalPages) {
        i = list[0].value - 1
        while(rightMaxCount > 0 && i >= 1) { // 对前面继续进行补充
          list.unshift({ value: i })
          i--;
          rightMaxCount--;
        }
      }
      // 4. 前后判断
      if (list[0].value != 1) {
        list[0].value = 1
        list.splice(1, 0, { type: 'more', value: -1 })
      }
      if (list[list.length - 1].value != totalPages) {
        list[list.length - 1].value = totalPages;
        list.splice(list.length - 1, 0, { type: 'more', value: -2 })
      }
      this.numList = list;
    },
    getLink(value) {
      var res = 1;
      if (value) {
        res = value
      }
      if (res > this.totalPages) {
        res = this.totalPages
      }
      if (res !== this._page) {
        this.jumpPage = this._page = res
        this.getList()
        this.$emit('change', res)
      }
    },
    confirmClick() {
      let res = Number(this.jumpPage)
      if (!isNaN(res)) {
        this.getLink(res)
      }
    }
  }
}
</script>
<style scoped lang='scss'>
.md-pagination{
  flex-wrap: wrap;
  .item{
    min-width: .3rem;
    height: .3rem;
    padding: 0 .08rem;
    border-radius: .04rem;
    border: solid 1px #cae1ec;
    &:hover{
      color: $blue;
      border-color: $blue;
    }
    &.on{
      background: $blue;
      border-color: $blue;
      color: #fff;
    }
  }
  .num{
    margin-right: .06rem;
    &:last-of-type{
      margin-right: 0;
    }
    &.ell{
      border: none;
    }
  }
  .icon{
    width: .06rem; height: .1rem;
    min-width: 6px; min-height: 10px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .prev{
    margin-right: .07rem; min-width: .9rem;
    .icon{
      background-image: url(~@/assets/images/icon-left.png);
      margin-right: .1rem;
    }
    &:hover{
      .icon{
        background-image: url(~@/assets/images/icon-left3.png);
      }
    }
  }
  .next{
    margin-left: .07rem; min-width: .9rem;
    .icon{
      background-image: url(~@/assets/images/icon-right5.png);
      margin-left: .1rem;
    }
    &:hover{
      .icon{
        background-image: url(~@/assets/images/icon-right3.png);
      }
    }
  }
  .first{
    margin-right: .07rem;
  }
  .last{
    margin-left: .07rem;
  }
  .disabled{
    cursor: not-allowed;
    pointer-events: none;
  }
  .status{
    margin: 0 .2rem;
  }
  .jump{
    input{
      width: .3rem;
      margin: 0 .1rem;
      background: transparent;
      padding: 0;
      text-align: center;
      -moz-appearance: none;
      &::-webkit-inner-spin-button{
        display: none;
      }
      &:focus{
        border-color: $blue; color: $blue;
      }
    }
  }
  .comfirm{
    margin-left: 0.2rem;
    min-width: .6rem;
  }
}
</style>