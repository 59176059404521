<template>
  <div class="base-date" v-clickoutside='hide'>
    <div class="base-date_hd flex-center color-blue s14" @click="show = !show">
      <span>{{ showText | dateFormat('yyyy-MM-dd HH:mm') }}</span>
      <img src="@/assets/images/icon-date2.png" alt="">
    </div>
    <div class="base-date_bd" v-if="show">
      <div class="cnt flex c-card">
        <div class="day">
          <div class="day-item s16 color-4" v-for="item in days" :key="item.value" :class="{ on: day == item.value }"
            @click="setDay(item.value)">
            {{ item.text }}({{ item.week }})
          </div>
        </div>
        <div class="time s16 color-4">
          <div class="time-group">
            <p class="s18 color-2 font-medium">上午</p>
            <div class="list">
              <div class="li" v-for="item in times" :class="{ on: item.value == time }" :key="item.value"
                @click="setTime(item)">
                <span>{{ item.text }}</span>
              </div>
            </div>
          </div>
          <div class="time-group">
            <p class="s18 color-2 font-medium">下午</p>
            <div class="list">
              <div class="li" v-for="item in times2" :class="{ on: item.value == time }" :key="item.value"
                @click="setTime(item)">
                <span>{{ item.text }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { numPad, dateFormat } from '@/utils';
export default {
  props: {
    // showText: { type: String, default: "" }
  },
  data() {
    return {
      days: [],
      day: '',
      time: '', // 7:00
      times: [
        { text: '7:00--8:00', value: '8:00' },
        { text: '8:00--9:00', value: '9:00' },
        { text: '9:00--10:00', value: '10:00' },
        { text: '10:00--11:00', value: '11:00' },
        { text: '11:00--12:00', value: '12:00' },
      ],
      times2: [
        { text: '12:00--13:00', value: '13:00' },
        { text: '13:00--14:00', value: '14:00' },
        { text: '14:00--15:00', value: '15:00' },
        { text: '15:00--16:00', value: '16:00' },
        { text: '16:00--18:00', value: '17:00' },
      ],
      show: false,
    };
  },
  computed: {
    showText() {
      let res = '请选择日期'
      if (this.day) {
        res = this.day
      }
      if (this.time) {
        res += ' ' + this.time
      }
      return res
    }
  },
  created() {
    this.init()
  },
  methods: {
    setDay(val) {
      this.day = val;
      this.$emit('change', this.showText);

    },
    setTime(val) {
      this.time = val.value;
      this.show = false;
      this.$emit('change', this.showText);
    },
    hide() {
      this.show = false
    },
    init() {
      let days = []
      let weekText = ['一', '二', '三', '四', '五', '六', '日'];
      for (let i = 0; i < 7; i++) {
        let obj = {}
        let _d = new Date(Date.now() + i * 24 * 60 * 60 * 1000)
        obj.text = dateFormat(_d, 'MM月dd日')
        obj.week = i == 0 ? '今天' : '周' + weekText[_d.getDay()]
        obj.value = dateFormat(_d, 'yyyy-MM-dd')
        days.push(obj)
      }
      this.days = days
      // this.day = days[0].value;
    }
  }
};
</script>

<style lang="scss" scoped>
.base-date {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  min-width: 1.6rem;

  &_hd {
    border: 1px solid;
    border-radius: 0.04rem;
    height: .3rem;
    padding: 0 .09rem;
    cursor: pointer;

    img {
      width: .14rem;
      margin-left: .08rem;
    }
  }

  &_bd {
    width: 6rem;
    position: absolute;
    left: -.03rem;
    top: 100%;
    z-index: 100;
    padding-top: .09rem;
  }

  .cnt {
    box-shadow: 0rem 0rem 0.5rem 0rem rgba(0, 126, 240, 0.08);

    .day {
      width: 2rem;
      padding: .3rem;

      &-item {
        padding: 0.11rem 0.1rem .08rem;
        border-radius: 0.04rem;
        text-align: center;
        margin-bottom: .16rem;
        cursor: pointer;

        &.on {
          color: $blue;
          background-color: $blueLight;
        }
      }
    }

    .time {
      width: 4rem;
      border-left: 1px solid #e6f2f8;
      padding: .27rem .3rem;

      &-group {
        margin-bottom: .27rem;

        &:last-of-type {
          margin-bottom: 0;
        }
      }

      .list {
        margin-top: .15rem;

        .li {
          position: relative;
          line-height: 0.38rem;
          border-radius: 0.04rem;
          border: solid 0.01rem #e6f2f8;
          cursor: pointer;
          padding-left: .13rem;
          margin-bottom: .1rem;

          &:last-of-type {
            margin-bottom: 0;
          }
        }

        .on {
          color: $blue;
          border-color: $blueLight;
          background-color: $blueLight;
          background-image: url(~@/assets/images/icon-101.png);
          background-position: right .14rem center;
          background-repeat: no-repeat;
          background-size: .16rem;
        }
      }
    }
  }
}
</style>