<template>
  <swiper class="banner" :options="swiperOption" ref="mySwiper" :not-next-tick="notNextTick">
    <swiper-slide v-for="item in list" :key="item.id">
      <!-- <router-link class="slide" :to="item.link" :target="item.target">
        <img class="img" :src="item.img" :alt="item.title" :style="{ height: height }" />
        <div class="text">
          <div class="tit s40 font-zihun color-f" v-html="item.title"></div>
          <div class="intro s22 font-zihun color-f">{{item.intro}}</div>
          <div v-if="item.btn" class="btn hn-btn-blue s16" :to="item.link" :target="item.target">立即查看</div>
        </div>
      </router-link> -->
      <div class="slide">
        <img class="img" :src="item.ad_code" :style="{ height: height }" @click="jumpClick(item)" />
        <!-- <div class="text">
          <div class="tit s40 font-zihun color-f" v-html="item.title"></div>
          <div class="intro s22 font-zihun color-f">{{ item.intro }}</div>
          <div v-if="item.btn" class="btn hn-btn-blue s16" :to="item.link" :target="item.target">立即查看</div>
        </div> -->
      </div>
    </swiper-slide>
    <div class="swiper-pagination" slot="pagination"></div>
  </swiper>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default() {
        // list format
        // [
        //   { 
        //     id: 1, 
        //     link: '', 
        //     target: '', 
        //     btn: true, // 显示按钮
        //     img: '/images/banner.jpg', 
        //     title: '易筑采集商城<br>全新上线！'
        //   }
        // ]
        return []
      }
    },
    height: {
      type: String,
      default: '4.44rem'
    }
  },
  data() {
    return {
      notNextTick: true,
      swiperOption: {
        pagination: '.swiper-pagination',
        paginationClickable: true,
        speed: 500,
        spaceBetween: 20,
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: true
        },
        loop: true
      }
    };
  },
  computed: {
    // swiperOption() {
    //   if (this.list.length > 1) {
    //     return {
    //       pagination: '.swiper-pagination',
    //       paginationClickable: true,
    //       speed: 500,
    //       spaceBetween: 20,
    //       autoplay: {
    //         delay: 3000,
    //         stopOnLastSlide: false,
    //         disableOnInteraction: true
    //       },
    //       loop: true
    //     }
    //   } else {
    //     return {}
    //   }
    // },
    swiper() {
      return this.$refs.mySwiper.swiper;
    },

  },
  methods: {
    jumpClick(item) {
      if (item.source_id) {
        window.open(item.source_id, '_blank');
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.banner {
  margin: 0;

  .slide {
    display: block;
    border-radius: .04rem;
    overflow: hidden;
    position: relative;
  }

  .img {
    display: block;
    width: 100%;
    object-fit: cover;
  }

  .text {
    position: absolute;
    left: .67rem;
    top: 49.5%;
    right: 0;
    margin: auto;
    transform: translateY(-50%);

    .tit {
      white-space: pre;
    }

    .intro {
      opacity: .6;
      margin-top: .07rem;
    }

    .btn {
      line-height: .4rem;
      border-radius: .4rem;
      padding: 0 .2rem;
      min-width: 1.2rem;
      text-align: center;
      display: inline-block;
      margin-top: .34rem;
    }
  }

  ::v-deep .swiper-pagination {
    bottom: .3rem;
    font-size: 0;

    &-bullet {
      width: 0.1rem;
      height: 0.1rem;
      background: #fff;
      opacity: .5;
      margin: 0 .06rem !important;

      &-active {
        opacity: 1;
      }
    }
  }
}
</style>