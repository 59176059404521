<template>
  <div class="md-home-5">
    <div class="base-width">
      <h2 class="c-title s22 color-2">配套服务</h2>
      <div class="list text-center">
        <template v-for="item in serviceList">
          <div class="li c-card" :key="item.id">
            <div class="tit s22 font-zihun" v-html="item.title"></div>
            <div class="desc s16 color-6 ep3" v-html="item.desc"></div>
            <BaseButton
              style-type='border'
              class="btn"
              tag='router-link'
              :to='item.link'
            >
              查看更多
            </BaseButton>
          </div>
        </template>
        <router-link class="li c-card more flex-center" to="/service">
          <div class="text">
            <p class="t1 s22 color-9 font-zihun">查看更多</p>
            <p class="t2 flex-center"><span class="dot" v-for="item in 3" :key="item"></span></p>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [
        { id: 1, link: '/service/letter', title: '工程<br/>保函', desc: '保障您的业务安全推荐您最可靠的工程保函!' },
        { id: 2, link: '/service/budget', title: '工程<br/>预决算', desc: '精确计算，明晰成本工程预决算为您的工程项目把关' },
        { id: 3, link: '/service/finance', title: '工程<br/>财税', desc: '理清工程财税，规范财务管理，为您提供专业、高效的财税解决方案!' },
        { id: 4, link: '/service/design', title: '深化<br/>设计', desc: '以创新引领设计，以设计提升价值，我们的深化设计服务为您打造独具匠心的建筑作品' },
        { id: 5, link: '/service/design', title: '深化<br/>设计', desc: '以创新引领设计，以设计提升价值，我们的深化设计服务为您打造独具匠心的建筑作品' },
      ]
    };
  },
  computed: {
    serviceList() {
      return this.list.slice(0, 4);
    }
  }
};
</script>

<style lang="scss" scoped>
.md-home-5{
  margin-bottom: 0.4rem;
  .list{
    display: flex;
    .li{
      margin-right: .2rem;
      width: 3.27rem;
      padding: .39rem .3rem .51rem;
      &:nth-child(1) {
        .tit{
          color: $blue;
          @include lg-blue;
        }
      }
      &:nth-child(2) {
        .tit{
          color: $green;
          @include lg-green;
        }
      }
      &:nth-child(3) {
        .tit{
          color: $red;
          @include lg-red;
        }
      }
      &:nth-child(4) {
        .tit{
          color: $brown;
          @include lg-brown;
        }
      }
    }
    .tit{
      width: 1.1rem; height: 1.1rem;
      border-radius: 50%;
      display: inline-flex; align-items: center; justify-content: center;
      position: relative;
      &:after{
        content: '';
        position: absolute; left: 50%; top: 50%;
        width: 1.02rem; height: 1.02rem;
        transform: translate(-50%, -50%);
        background: url(~@/assets/images/icon-circle3.png) no-repeat center / contain;
      }
    }
    .desc{
      line-height: calc(30 / 16 * 1em);
      height: calc(3 * 30 / 16 * 1em);
      margin-top: .12rem;
    }
    .btn{
      min-width: 1.21rem;
      margin-top: .22rem;
    }
    .more{
      text-align: center;
      margin-right: 0;
      .text{
        margin-top: .07rem;
      }
      .t1{
        transition: .3s
      }
      .t2{
        margin-top: .14rem;
        .dot{
          width: .08rem; height: .08rem;
          background: #999;
          border-radius: 50%;
          margin: 0 .03rem;
          transition: .3s
        }
      }
      &:hover{
        .t1{
          color: $blue;
        }
        .t2{
          .dot{
            background-color: $blue;
          }
        }
      }
    }
  }
}
</style>