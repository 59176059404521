<template>
  <div class="page">
    <Header />
    <slot></slot>
    <Side v-if="side" />
    <Footer v-ani.fade-up />
  </div>
</template>

<script>
export default {
  props: {
    side: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss">
</style>