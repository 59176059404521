<template>
  <!-- 购物车中使用 -->
  <component :is="compName" :to="`/product-detail?goods_id=${goods.goods_id}`" class="c-goods flex-top"
    :class="{ out: goods.out }" tag="a" target="_blank">
    <div class="cover">
      <img :src="goods.original_img">
      <div v-if="goods.out" class="out-text s16 color-f">已失效</div>
    </div>
    <div class="text">
      <div class="tit s16 color-4">{{ goods.goods_name }}</div>
      <div class="attr s16 color-4" v-if="goods.spec_key_name">{{ goods.spec_key_name }}</div>
      <div class="attr s16 color-red font-medium" v-else>请重新选择规格</div>
      <div class="labels flex-middle s12 font-light" v-if="goods.tags.length > 0">
        <div class="label color-green" v-for="(item, index) in goods.tags" :key="index">{{ item }}</div>
        <!-- <div class="label color-blue">国标</div> -->
      </div>
    </div>
  </component>
</template>

<script>
export default {
  props: {
    goods: {
      type: Object, default() { return {} }
    }
  },
  data() {
    return {};
  },
  computed: {
    compName() {
      return this.goods.out ? 'div' : 'router-link'
    }
  },
};
</script>

<style lang="scss" scoped>
.c-goods {
  .cover {
    width: 1rem;
    height: 1rem;
    margin-right: .18rem;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: .04rem;
    }

    .out-text {
      width: .7rem;
      height: .7rem;
      border-radius: 50%;
      position: absolute;
      inset: 0;
      margin: auto;
      text-align: center;
      line-height: .7rem;
      background: rgba($color: #000000, $alpha: .6);
    }
  }

  .attr {
    margin-top: .16rem;
  }

  .labels {
    margin-top: .2rem;
  }

  .label {
    border-radius: .04rem;
    border: 1px solid;
    padding: 0 .05rem;
    margin-right: 0.1rem;
    line-height: .18rem;
  }

  &.out {

    .tit,
    .attr,
    .label {
      color: #999;
    }
  }
}
</style>