<template>
  <div class="home-news c-card">
    <div class="hd">
      <div class="tit s22 color-2">行业资讯</div>
      <router-link class="more s14 color-9 flex-middle" to="/news">
        <span>更多</span>
        <span class="icon"></span>
      </router-link>
    </div>
    <div class="list">
      <swiper :options="swiperOption" class="swiper">
        <template v-for="(item, index) in listArr">
          <swiper-slide :key="item.article_id">
            <router-link class="item" :to="`/article?id=${item.article_id}`" :title="item.title">
              <div class="num bg-green color-f s12">{{ (index + 1) | numPad }}</div>
              <div class="tit s16 color-4">{{ item.title }}</div>
            </router-link>
          </swiper-slide>
        </template>
      </swiper>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    listArr: { type: Array, default: () => [] }
  },
  data() {
    return {
      list: [
        { id: 1, title: '中建七局联合体中标广州旺村(南)旧村改造察设计施工总承包' },
        { id: 2, title: '广州:至2025年推进城市更新约130平方公里城中村改造' },
        { id: 3, title: '中建七局联合体中标广州旺村(南)旧村改造察设计施工总承包' },
        { id: 4, title: '广州:至2025年推进城市更新约130平方公里城中村改造' },
      ],
      swiperOption: {
        direction: 'vertical',
        autoplay: 3000,
        slidesPerView: 2,
        loop: true,
        observer: true,
        observeParents: true,
      }
    }
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
.home-news {
  margin-top: .16rem;
  padding: .26rem .3rem .3rem;

  .hd {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .more {
      .icon {
        width: .06rem;
        height: .1rem;
        min-width: 6px;
        min-height: 10px;
        background: url(~@/assets/images/icon-right.png);
        margin-left: .05rem;
      }

      &:hover {
        color: $green;

        .icon {
          background-image: url(~@/assets/images/icon-right2.png);
        }
      }
    }
  }

  .list {
    margin-top: .04rem;

    .swiper {
      height: 1.66rem;
    }

    .item {
      display: flex;
      align-items: flex-start;
      border-bottom: 1px solid #e6f2f8;
      padding: .13rem 0;

      &:hover {
        .tit {
          color: $green;
        }
      }
    }

    .num {
      min-width: .2rem;
      line-height: .2rem;
      text-align: center;
      border-radius: 50%;
      margin-right: .1rem;
      margin-top: .03rem;
    }

    .tit {
      line-height: calc(28 / 16 * 1em);
      transition: .2s;
    }
  }
}</style>