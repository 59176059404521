<template>
  <!-- 产品数量选择 -->
  <div class="c-product-number text-center">
    <div class="form s16 color-4">
      <div class="btn btn-add" @click.stop="handleAdd"></div>
      <input type="number" class="ipt" v-model="num" :max="inputMax" :min='min' :disabled='disabled'
        @input="handleChange">
      <div class="btn btn-minus" @click.stop="handleMinus"></div>
    </div>
    <template v-if="tips">
      <p class="s16 color-9 tips" v-if="all > 0">有货</p>
      <p class="s16 color-red tips" v-else>无货</p>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    // 库存
    all: { type: Number, default: 0 },
    // 当前值
    value: { type: Number, default: 1 },
    // 最大加购数量
    max: { default: 0 },
    min: { type: Number, default: 0 },
    tips: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false }
  },
  data() {
    return {
      num: 0
    };
  },
  watch: {
    value(val) {
      this.num = val
    }
  },
  computed: {
    inputMax() {
      return Math.min(this.max, this.all) || this.max || this.all || ''
    }
  },
  created() {
    this.num = this.value
  },
  methods: {
    update() {
      this.$emit('input', this.num);
      this.$emit('change', this.num);
    },
    handleChange(e) {
      let val = e.target.value
      if (this.inputMax && val > this.inputMax) {
        this.num = this.inputMax
        this.update()
      }
      if (val < this.min) {
        this.num = this.min
        this.update()
      }
      this.update()
    },
    // 加
    handleAdd() {
      if (!this.disabled) {
        if (this.inputMax && this.num + 1 > this.inputMax) {
          this.$message.warning('超过')
        } else {
          this.num++
          this.update()
        }
      }
    },
    // 减
    handleMinus() {
      if (!this.disabled) {
        if (this.num > this.min) {
          this.num--
          this.update()
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.c-product-number {
  display: inline-block;

  .form {
    display: flex;
    border-radius: .04rem;
    border: solid 1px #cae1ec;

    .btn {
      width: .28rem;
      line-height: .28rem;
      cursor: pointer;
      background-size: .11rem auto;
      background-repeat: no-repeat;
      background-position: center;

      &-add {
        background-image: url(~@/assets/images/icon-add.png);
      }

      &-minus {
        background-image: url(~@/assets/images/icon-minus.png);
      }
    }

    .ipt {
      width: .6rem;
      height: .28rem;
      text-align: center;
      border-style: solid;
      border-color: #cae1ec;
      border-width: 0 1px 0 1px;
      background: transparent;
      border-radius: 0;

      &::-webkit-inner-spin-button {
        display: none;
      }
    }
  }

  .tips {
    margin-top: .07rem;
  }
}
</style>