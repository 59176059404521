<template>
    <!-- 收藏的店铺 -->
    <div class="collect_shop">
        <!-- 搜索 -->
        <div class="search_box">
            <!-- 输入框搜索 -->
            <collectSearch @sarchFn="sarchFn"></collectSearch>
        </div>
        <!-- 状态搜索 -->
        <div class="status_search">
            <div class="l"></div>
            <div class="r">
                <div class="r_btn s20" v-if="isMask" @click="allClick">
                    <img v-if="isAll" src="https://yizhu-new.oss-cn-shenzhen.aliyuncs.com/app_img/aIcon/icon390.png">
                    <img v-else src="https://yizhu-new.oss-cn-shenzhen.aliyuncs.com/app_img/aIcon/icon389.png">
                    全选
                </div>
                <div class="r_del color-red s20" v-if="isMask" @click="delAllClick">移除</div>
                <div class="r_txt s20" v-if="isMask" @click="() => isMask = false">取消管理</div>
                <div class="r_txt s20" v-else @click="() => isMask = true">管理</div>
            </div>
        </div>
        <!-- 店铺列表 -->
        <div class="shop_box">
            <div class="shop_list" v-for="(item, index) in listArr" :key="item.suppliers_info.suppliers_id">

                <div class="l">
                    <div class="s_avatar"><img :src="item.suppliers_info?.shop_info?.logo"></div>
                    <div class="s_name s16">{{ item.suppliers_info?.shop_info?.shop_name }}</div>
                    <div class="s_level">
                        <UserRank :type='3' :num='4' :hatArr="item.suppliers_info?.shop_info?.level?.hat"
                            :starsArr="item.suppliers_info?.shop_info?.level?.stars"></UserRank>
                        <div class="s12">{{ item.suppliers_info?.shop_mouth?.omprehensive_score_text }}</div>
                    </div>
                    <div class="s_title s20 color-2 font-medium">店铺口碑</div>
                    <div class="s_opinions">
                        <div class="op_item">
                            <div class="op_num color-brown flex-center">
                                <span class="t1 s18">{{ item.suppliers_info?.avg_score?.service_rank }}</span>
                                <span class="t2 s14 bg-brown-light">差</span>
                            </div>
                            <div class="op_txt flex-center">服务体验</div>
                        </div>
                        <div class="op_item">
                            <div class="op_num color-brown flex-center">
                                <span class="t1 s18">{{ item.suppliers_info?.avg_score?.goods_rank }}</span>
                                <span class="t2 s14 bg-brown-light">差</span>
                            </div>
                            <div class="op_txt flex-center">商品体验</div>
                        </div>
                        <div class="op_item">
                            <div class="op_num color-brown flex-center">
                                <span class="t1 s18">{{ item.suppliers_info?.avg_score?.deliver_rank }}</span>
                                <span class="t2 s14 bg-brown-light">差</span>
                            </div>
                            <div class="op_txt flex-center">物流体验</div>
                        </div>
                    </div>
                    <BaseButton class="s_btn" color="blue" style-type='light' :hover="false">已关注</BaseButton>
                    <BaseButton class="s_btn" color="green" style-type='light' tag='router-link'
                        :to='`/shop/seller?suppliers_id=${item.suppliers_info?.suppliers_id}`'>进店看看</BaseButton>
                    <img class="del_img" src="https://yizhu-new.oss-cn-shenzhen.aliyuncs.com/app_img/aIcon/icon384.png"
                        @click="delClick(item.suppliers_info)">
                </div>
                <div class="r">
                    <div class="r_goods" v-for="(goodsItem, goodsIndex) in item.goods_list" :key="goodsItem.goods_id"
                        @click="goodsClick(goodsItem)">
                        <!-- 图片 -->
                        <div class="g_img">
                            <img :src="goodsItem.goods_image">
                            <!-- <ImgRatio :src="goodsItem.goods_image" :width="60" :height="60"></ImgRatio> -->
                            <div v-if="goodsItem.if_period" class="label s14 color-f font-light">账期</div>
                        </div>
                        <!-- 标题 -->
                        <div class="g_tit s18 ep2">{{ goodsItem.goods_name }}</div>
                        <!-- 价格 -->
                        <div class="g_price s20 color-red font-medium">
                            <span class="s22">{{ goodsItem.shop_price }}</span>/{{ goodsItem.unit_name }}
                        </div>
                    </div>
                </div>
                <div class="mask_box" v-if="isMask">
                    <img v-if="item.isCheck" class="sel_icon"
                        src="https://yizhu-new.oss-cn-shenzhen.aliyuncs.com/app_img/aIcon/icon383.png"
                        @click="selectClick(item)">
                    <img v-else class="sel_icon"
                        src="https://yizhu-new.oss-cn-shenzhen.aliyuncs.com/app_img/aIcon/icon382.png"
                        @click="selectClick(item)">
                </div>
            </div>
            <!-- 分页 -->
            <Pagination v-show="total > 0" :page="getData.p" :size="getData.page_num" :total="total" v-ani.fade-up
                @change="changePage" :key="pagKey" />
        </div>
    </div>
</template>
<script>
import { CollectSuppliersListApi, CollectSuppliersApi } from "@/request/api/shop";
export default {
    created() {
        this.listFn();
    },
    data() {
        return {
            getData: {
                p: 1,
                page_num: 20,
                keywords: ""
            },
            listArr: [],
            total: 0,
            pagKey: 0,
            isMask: false,
            isAll: false
        }
    },
    methods: {
        selectClick(info) {
            info.isCheck = !info.isCheck;
        },
        // 全选
        allClick() {
            if (this.isAll) {
                this.listArr.map(item => item.isCheck = false);
                this.isAll = false;
            } else {
                this.isAll = true;
                this.listArr.map(item => item.isCheck = true);
            }
        },
        // 全部移除
        delAllClick() {
            let arr = [];
            this.listArr.map(item => {
                if (item.isCheck) {
                    arr.push(item.suppliers_info.suppliers_id);
                }
            })
            if (arr.length <= 0) {
                this.$message.error('请选择店铺');
                return;
            }
            CollectSuppliersApi({ suppliers_id: arr.toString() }).then(({ status }) => {
                if (status == 200) {
                    this.$message.success('操作成功');
                    this.isMask = false;
                    this.getData.p = 1;
                    this.listFn();
                }
            })
        },
        // 单个移除
        delClick(info) {
            CollectSuppliersApi({ suppliers_id: info.suppliers_id }).then(({ status }) => {
                if (status == 200) {
                    this.$message.success('操作成功');
                    this.getData.p = 1;
                    this.listFn();
                }
            })
        },
        goodsClick(item) {
            let routeData = this.$router.resolve({
                path: '/product-detail',
                query: { goods_id: item.goods_id }
            });
            window.open(routeData.href, '_blank');
        },
        sarchFn(val) {
            this.getData.keywords = val;
            this.getData.p = 1;
            this.listFn();
        },
        listFn() {
            CollectSuppliersListApi(this.getData).then(({ data, status }) => {
                if (status == 200) {
                    data.list.map(item => {
                        item.isCheck = false;
                    })
                    this.listArr = data.list;
                    this.total = data.total;
                    this.pagKey++;
                }
            })
        },
        // 分页
        changePage(page) {
            this.getData.p = page;
            this.listFn();
            window.scroll({ top: 500, behavior: "smooth", });
        },
    }
}
</script>
<style lang="scss" scoped>
.search_box {
    margin-top: 0.2rem;
    min-height: 1rem;
    background-color: #fff;
    border-radius: .04rem;
}

.status_search {
    margin-top: 0.2rem;
    padding: 0 0.2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .l {
        display: flex;
        align-items: center;

        .opt {
            display: flex;

            .li {
                margin-left: 0.1rem;
                padding: 0 .05rem;
                min-width: .84rem;
                text-align: center;
                line-height: .3rem;
                border-radius: .04rem;
                cursor: pointer;

                &:hover {
                    color: $blue;
                }

                &.on {
                    color: $blue;
                    background: $blueLight;
                }
            }
        }
    }

    .r {
        display: flex;

        .r_btn {
            margin-right: 0.2rem;
            display: flex;
            align-items: center;
            cursor: pointer;

            img {
                margin-right: 6px;
                width: 21px;
                height: 21px;
            }
        }

        .r_del {
            margin-right: 0.2rem;
            cursor: pointer;
        }

        .r_txt {
            cursor: pointer;
        }
    }
}

.total_num {
    margin-top: 0.1rem;
    padding: 0 0.2rem;
    color: #999999;
}

.shop_box {
    margin-top: 0.2rem;

    .shop_list {
        margin-bottom: 0.2rem;
        display: flex;
        position: relative;

        .mask_box {
            width: 100%;
            height: 100%;
            background-color: rgba($color: #000000, $alpha: 0.4);
            position: absolute;
            top: 0;
            left: 0;
            z-index: 999;
            border-radius: 0.04rem;

            .sel_icon {
                width: 42px;
                height: 42px;
                position: absolute;
                top: 0.1rem;
                right: 0.1rem;

            }
        }

        .l {
            padding: 0.2rem 0.1rem;
            width: 2rem;
            height: 3.5rem;
            box-sizing: border-box;
            background-color: #fff;
            border-radius: 0.04rem;
            position: relative;

            .del_img {
                position: absolute;
                top: 0.1rem;
                right: 0.1rem;
                width: 0.2rem;
                height: 0.2rem;
                cursor: pointer;
            }

            .s_avatar {
                display: flex;
                justify-content: center;

                img {
                    width: 0.66rem;
                    height: .66rem;
                    border-radius: 50%;
                }
            }

            .s_name {
                margin-top: 0.05rem;
                text-align: center;
                word-break: break-all;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                overflow: hidden;
            }

            .s_level {
                margin-top: 0.05rem;
                display: flex;
                align-items: center;
                justify-content: space-between;
            }

            .s_title {
                margin-top: 0.1rem;
            }

            .s_opinions {
                margin-top: 0.05rem;
                display: flex;

                .op_item {
                    width: 33%;

                    .op_txt {
                        color: #9b9b9b;
                    }
                }
            }

            .s_btn {
                display: flex;
                margin-top: 0.1rem;
            }

        }

        .r {
            padding: 0 0.2rem;
            margin-left: 0.2rem;
            box-sizing: border-box;
            flex: 1;
            display: flex;
            background-color: #fff;
            border-radius: 0.04rem;
            cursor: pointer;

            .r_goods {
                padding: 0.2rem 0.1rem;
                width: 2.5rem;
                height: 3.5rem;
                box-sizing: border-box;

                .g_img {
                    position: relative;
                    border-radius: .04rem;
                    overflow: hidden;
                    display: flex;
                    justify-content: center;

                    img {
                        width: 2rem;
                        height: 2rem;

                        &:hover {
                            transform: scale(1.1);
                        }
                    }

                    .label {
                        position: absolute;
                        right: 0;
                        top: 0;
                        background-image: linear-gradient(90deg, #2991ee 0%, #0174da 100%);
                        border-radius: 0 .04rem 0 .04rem;
                        line-height: calc(30 / 14 * 1em);
                        padding: 0 .11rem;
                    }
                }

                .g_tit {
                    line-height: calc(32 / 18 * 1em);
                    height: calc(32 / 18 * 1em * 2);
                    margin-top: .21rem;

                    &:hover {
                        color: $blue;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}
</style>