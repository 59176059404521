<template>
  <div class="menu">
    <template v-for="item in list">
      <router-link
        class="menu-item bg-cover c-card"
        :key="item.id"
        :to="item.link"
        :target="item.target"
        :style="'background-image: url(' + item.img + ')'"
      >
        <div class="tit s22 color-2 font-mudium ep1">{{ item.title }}</div>
        <div class="desc s14 color-9 ep2">{{ item.desc }}</div>
        <BaseButton class="font-light btn" styleType="light" size='mini'>立即查看</BaseButton>
      </router-link>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default() {
        return []
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.menu{
  display: flex;
  &-item{
    flex: 1;
    margin-top: .2rem;
    height: 1.83rem;
    padding: .26rem .3rem;
    margin-right: .2rem;
    &:last-of-type{
      margin-right: 0;
    }
    &:hover{
      .btn{
        @include buttonHover($blue);
      }
    }
  }
  .tit{}
  .desc{
    line-height: calc(22 / 14 * 1em);
    height: calc(22 / 14 * 2em);
    width: 11em;
    margin-top: .09rem;
  }
  .btn{
    margin-top: .15rem;
  }
}
</style>