<template>
  <!-- 文章、智慧工地左侧用的分类列表 -->
  <div class="md-cate c-card s16">
    <template v-for="item in list">
      <div class="link flex-middle" :class="{ on: item.cat_id == currentId }" :key="item.cat_id" @click="listClick(item)">
        <span class="text">{{ item.cat_name }}</span>
        <span class="icon"></span>
      </div>
    </template>
      </div>
</template>

<script>
export default {
  props: {
    list: { type: Array, default() { return [] } },
    path: { type: String, default: '' },
    curId: { type: String, default: '' },
  },
  created() {
    this.currentId = this.curId;
  },
  data() {
    return {
      currentId: "0"
    };
  },
  methods: {
    listClick(item) {
      this.currentId = item.cat_id;
      this.$emit('change', this.currentId);
      if (item.link) {
        this.$router.push(item.link);
      }
    },
    getCurrent(id) {
      return id == (this.$route.query.id ? this.$route.query.id : '')
    }
  }
};
</script>

<style lang="scss" scoped>
.md-cate {
  padding: .2rem;
  min-height: 4.92rem;

  .link {
    border-radius: .04rem;
    padding: .08rem .13rem;
    line-height: 1.5em;
    margin-bottom: 0.116rem;
    transition: .3s;

    &:last-of-type {
      margin-bottom: 0;
    }

    .text {
      flex: 1;
    }

    .icon {
      width: .04rem;
      height: .08rem;
      min-width: 4px;
      min-height: 8px;
      background: url(~@/assets/images/icon-right3.png) no-repeat center / contain;
    }

    &.on,
    &:hover {
      background-color: $blue;
      color: #fff;

      .icon {
        background-image: url(~@/assets/images/icon-right4.png);
      }
    }
  }
}
</style>