<template>
  <div class="c-pop" v-if="show">
    <div class="c-pop__mask"></div>
    <div class="c-pop__main c-card">
      <img class="c-pop__close" :src="require('@/assets/images/icon-close.png')"
        @click="$emit('update:show', false)"
      >
      <!-- 内容 -->
      <img :src="require('@/assets/images/code3.png')" alt="" class="code">
      <div class="text s22 color-2 font-medium">下载app，马上进行投标</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.c-pop{
  position: fixed; left: 0; top: 0; z-index: 1000;
  width: 100%; height: 100%;
  text-align: center;
  &:before{
    content: '';
    display: inline-block; vertical-align: middle;
    height: 100%;
  }
  &__mask{
    position: absolute; left: 0; top: 0; z-index: 1;
    width: 100%; height: 100%;
    background: rgba($color: #000000, $alpha: 0.5);
  }
  &__main{
    position: relative; z-index: 2;
    display: inline-block; vertical-align: middle;
    width: 3.2rem;
    padding-top: .56rem; padding-bottom: .45rem;
    .code{
      width: 1.84rem;
    }
    .text{
      margin-top: .08rem;
    }
  }
  &__close{
    position: absolute; right: .29rem; top: .25rem;
    width: .21rem;
    cursor: pointer;
  }
}
</style>