<template>
  <BaseDialog
    :visible.sync="visible"
    title='商品反馈'
    width="6.3rem"
    confirm-text='提交'
    @close="$emit('close')"
    @cancel="$emit('cancel')"
    @confirm="$emit('confirm')"
  >
    <form class="form s16 color-2">
      <!-- 关联订单 -->
      <div class="form-item">
        <label class="form-item_label">
          <div><p class="p_label">关联订单</p></div>
        </label>
        <div class="form-item_content">
          <div class="goods flex-middle">
            <img src="@/assets/images/prod-1.jpg" alt="" class="goods-cover">
            <div class="s16 color-4 text">
              <div class="tit">
                <span class="color-9">商品标题：</span><span>泳池水下灯</span>
              </div>
              <div class="flex-middle flex-between row-2">
                <div class=""><span class="color-9">规格：</span><span>50*50</span></div>
              </div>
            </div>
            <div>x50</div>
          </div>
        </div>
      </div>
      <!-- 反馈原因 -->
      <div class="form-item">
        <label class="form-item_label">
          <div><p class="p_label">反馈原因</p></div>
        </label>
        <div class="form-item_content">
          <div class="form-item_content">
          <BaseSelect :options='options' v-model="reason" ipt-class='ipt' placeholder="请选择理由"></BaseSelect>
        </div>
        </div>
      </div>
      <!-- 反馈问题 -->
      <div class="form-item">
        <label class="form-item_label required">
          <div><p class="p_label">反馈问题</p></div>
        </label>
        <div class="form-item_content">
          <div class="form-item_content">
          <BaseSelect :options='options2' v-model="qs" ipt-class='ipt' placeholder="请选择"></BaseSelect>
        </div>
        </div>
      </div>
      <!-- 备注 -->
      <div class="form-item">
        <label class="form-item_label">
          <div><p class="p_label">自定义说明</p></div>
        </label>
        <div class="form-item_content">
          <textarea type="text" class="ipt" placeholder="请输入自定义说明" v-model="content"></textarea>
        </div>
      </div>
      <!-- 处理方案 -->
      <div class="form-item auto-height">
        <label class="form-item_label">
          <div><p class="p_label">处理方案</p></div>
        </label>
        <div class="form-item_content flex-middle flex-between">
          <BaseRadio :options='options3' v-model="way"></BaseRadio>
          <div class="s16 color-2">本次送货数量:100</div>
        </div>
      </div>
      <!-- 收货数量 -->
      <div class="form-item" v-if="way == 1">
        <label class="form-item_label required">
          <div><p class="p_label">实际收货数量</p></div>
        </label>
        <div class="form-item_content">
          <input type="text" class="ipt" placeholder="请输入实际收货数量">
        </div>
      </div>
      <!-- 收货地址 -->
      <div class="form-item" v-if="way == 1">
        <label class="form-item_label">
          <div><p class="p_label">收货地址</p></div>
        </label>
        <div class="form-item_content">
          <input type="text" class="ipt" placeholder="请输入收货地址">
        </div>
      </div>
      <!-- 发货地址 -->
      <div class="form-item" v-if="way == 2">
        <label class="form-item_label">
          <div><p class="p_label">发货地址</p></div>
        </label>
        <div class="form-item_content">
          <input type="text" class="ipt" placeholder="请输入发货地址">
        </div>
      </div>
      <!-- 备注 -->
      <div class="form-item">
        <label class="form-item_label">
          <div><p class="p_label">备注</p></div>
        </label>
        <div class="form-item_content">
          <textarea type="text" class="ipt" placeholder="请输入备注"></textarea>
        </div>
      </div>
      <!-- 图片 -->
      <div class="form-item">
        <label class="form-item_label">
          <div><p class="p_label">图片</p></div>
        </label>
        <div class="form-item_content">
          <BaseFile v-model="imgs" placeholder="上传图片" :max='3'></BaseFile>
        </div>
      </div>
    </form>
  </BaseDialog>
</template>

<script>
export default {
  data() {
    return {
      visible: true,
      content: '',
      count: 5,
      imgs: [],
      reason: '',
      options: [
        { value: 1, name: '货物数量不对' },
        { value: 2, name: '货物数量不对' },
      ],
      qs: '',
      options2: [
        { value: 1, name: '回复态度不友好' },
      ],
      options3: [
        { value: 1, name: '补发商品' },
        { value: 2, name: '换货' },
      ],
      way: 1
    };
  },
  methods: {
    handleSubmit() {
      this.$emit('confirm') // 添加成功，页面要重新加载地址列表
    }
  }
};
</script>

<style lang="scss" scoped>
  .form{
    padding: .3rem .3rem .2rem;
    &-item{
      display: flex; align-items: flex-start;
      margin-bottom: .2rem;
      &:last-of-type{
        margin-bottom: 0;
      }
      &_label{
        min-width: 1.14rem;
        min-height: .5rem;
        display: flex; align-items: center; justify-content: flex-end;
        line-height: 1.4em;
        position: relative;
        padding-right: .2rem;
        &.required {
          .p_label{
            position: relative;
            &:after{
              content: '*';
              color: #f53e39;
              position: absolute; right: -.5em; top: .02rem;
            }
          }
          .p_tips{
            text-align: center;
          }
        }
      }
      &_content{
        flex: 1;
      }
      &.auto-height{
        align-items: center;
        .form-item{
          &_label{
            min-height: auto;
          }
        }
      }
    }
    ::v-deep .ipt{
      width: 100%; height: .5rem;
      border-radius: .04rem;
      border: solid 1px #cae1ec;
      padding: 0 .14rem;
      transition: .3s;
      &::placeholder{
        color: #999;
      }
      &:focus{
        border-color: $blue;
      }
    }
    textarea.ipt{
      display: block;
      height: .8rem;
      padding: .14rem .14rem;
    }
    .goods{
      border-radius: 0.04rem;
      border: dashed 0.01rem #cae1ec;
      padding: .1rem; padding-right: .19rem;
      &-cover{
        width: .6rem; height: .6rem;
        object-fit: cover;
        border-radius: .04rem;
        margin-right: .19rem;
      }
      .text{
        flex: 1;
      }
      .row-2{
        margin-top: .12rem;
      }
    }
  }
</style>