<template>
  <BaseDialog :visible.sync="visible" title='我的项目管理' width="16rem" @close="$emit('close')" @confirm='handleSubmit'>
    <div class="wrap">
      <!-- <Search placeholder="请输入关键词搜索" @search="handleSearch" /> -->
      <div class="table">
        <div class="thead s18 color-2 font-medium" ref="thead">
          <div class="thead-cnt flex-middle">
            <div class="td td-1">工程名称</div>
            <div class="td td-2">购买方公司</div>
            <div class="td td-3">业主单位</div>
            <div class="td td-4">总包单位</div>
            <div class="td td-5">分包单位</div>
            <div class="td td-6">收货地址</div>
            <div class="td td-7">收货方</div>
            <div class="td td-8">联系人电话</div>
            <div class="td td-9">身份证号码</div>
          </div>
        </div>
        <div class="tbody s16 color-4 c-scroll" @scroll="handleScroll">
          <div class="item flex-middle" v-for="item in listArr" :key="item.id" :class="{ on: currentId == item.id }">
            <div class="icon bg-contain pointer" @click="currentId = item.id"></div>
            <div class="tr flex-middle">
              <div class="td td-1">{{ item.project_name }}</div>
              <div class="td td-2">{{ item.company_name }}</div>
              <div class="td td-3">{{ item.owner_unit }}</div>
              <div class="td td-4">{{ item.winning_unit }}</div>
              <div class="td td-5">{{ item.shall_unit }}</div>
              <div class="td td-6">{{ item.project_address }}</div>
              <div class="td td-7">{{ item.project_consignee }}</div>
              <div class="td td-8">{{ item.project_phone }}</div>
              <div class="td td-9">{{ item.id_card_no }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </BaseDialog>
</template>

<script>
import { PurchaseListApi } from "@/request/api/order.js";
export default {
  created() {
    this.listFn();
  },
  data() {
    return {
      getData: {
        p: 1,
        page_num: 100,
        keyword: ""
      },
      listArr: [],
      visible: true,
      currentId: 1
    };
  },
  methods: {
    listFn() {
      PurchaseListApi(this.getData).then(({ status, data }) => {
        if (status == 200) {
          this.listArr = data.list;
        }
      })
    },
    handleSubmit() {
      let data = this.listArr.find(el => el.id == this.currentId)
      if (data) {
        this.$emit('change', data) // 添加成功，页面要重新加载地址列表
      }
      this.$emit('close');
    },
    handleSearch(e) {
      console.log(e);
    },
    handleScroll(e) {
      this.$refs.thead.scrollLeft = e.target.scrollLeft
    }
  }
};
</script>

<style lang="scss" scoped>
.wrap {
  padding: .3rem .3rem .2rem;

  .md-search {
    margin: 0;
  }

  .table {
    margin-top: .09rem;

    .td {
      text-align: center;
      flex-shrink: 0;
    }

    .td-1 {
      text-align: left;
    }

    .td-2 {
      width: 2.3rem;
    }

    .td-3 {
      width: 2.3rem;
    }

    .td-4 {
      width: 2.3rem;
    }

    .td-5 {
      width: 2.3rem;
    }

    .td-6 {
      width: 4.5rem;
    }

    .td-7 {
      width: 2.3rem;
    }

    .td-8 {
      width: 2.3rem;
    }

    .td-9 {
      width: 3rem;
    }

    .thead {
      background: #fff;
      overflow: auto;

      &::-webkit-scrollbar {
        height: 0;
      }

      &-cnt {
        height: .56rem;
      }

      .td-1 {
        padding-left: .3rem;
        width: 1.63rem;
      }
    }

    .tbody {
      overflow: auto;
      max-height: 50vh;

      .td {
        padding: .1rem;
      }

      .td-1 {
        width: 1.33rem;
        text-align: center;
      }

      .icon {
        width: .16rem;
        height: .16rem;
        margin-right: .13rem;
        flex-shrink: 0;
        background-image: url(~@/assets/images/icon-102.png);
      }

      .item {
        margin-bottom: .2rem;

        &.on {
          .icon {
            background-image: url(~@/assets/images/icon-101.png);
          }
        }
      }

      .tr {
        border-radius: 0.04rem;
        border: solid 0.01rem #e6f2f8;
        min-height: 1rem;
      }
    }
  }
}
</style>