<template>
  <div class="md-prod-cate c-card" v-ani.fade-up v-clickoutside="hidePanel">
    <div class="hd s18 font-medium color-f flex-center">产品分类</div>
    <div class="list s18 color-6">
      <template v-for="item in catArr">
        <router-link class="li flex-middle" :to="'/shop/list?cat_name=' + item.label" :key="item.value"
          :class="{ on: current_1 == item.value }" @mouseenter.native="handleEnter(item)">
          <div class="tit">{{ item.label }}</div>
          <div class="icon"></div>
          <template v-if="item.children && item.children.length > 0">
            <div class="panel">
              <div class="panel-cnt flex-top">
                <!-- 二级 -->
                <div class="list2 c-scroll">
                  <router-link v-for="cat2 in item.children" :key="cat2.value" :to="'/shop/list?cat_name=' + cat2.label"
                    class="li2 color-6 s18" :class="{ on: cat2.value == current_2 }" @mouseenter.native="showList3(cat2)">
                    {{ cat2.label }}
                  </router-link>
                </div>
                <!-- 三级 -->
                <div class="list3">
                  <router-link v-for="cat3 in list3" :key="cat3.value" :to="'/shop/list?cat_name=' + cat3.label"
                    class="li3 s18 color-blue">
                    {{ cat3.label }}
                  </router-link>
                </div>
              </div>
            </div>
          </template>
        </router-link>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    catArr: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      // catArr: [
      //   {
      //     id: 1, title: '土建',
      //     children: [
      //       {
      //         id: 11, title: '金属材料',
      //         children: [
      //           { id: 101, title: '方钢' },
      //           { id: 102, title: '扁钢' },
      //           { id: 103, title: '钢丝' },
      //           { id: 104, title: '工字钢' },
      //           { id: 105, title: '钢板' },
      //           { id: 106, title: '槽钢' },
      //           { id: 107, title: '钢纹线/钢丝束' },
      //           { id: 108, title: '铜带材' },
      //           { id: 109, title: '方钢' },
      //           { id: 110, title: '扁钢' },
      //           { id: 111, title: '钢丝' },
      //           { id: 112, title: '工字钢' },
      //         ]
      //       },
      //       { id: 12, title: '混泥土/砂浆' },
      //       { id: 13, title: '水泥/砖瓦' },
      //       { id: 14, title: '防水材料' },
      //       { id: 15, title: '保温耐火' },
      //       { id: 16, title: '成型构件' },
      //       { id: 17, title: '人防设备' },
      //       { id: 18, title: '周转材料' }
      //     ]
      //   },
      //   { id: 2, title: '装饰' },
      //   { id: 3, title: '电气' },
      //   { id: 4, title: '市政' },
      //   { id: 5, title: '给排水' },
      //   { id: 6, title: '消防工程' },
      //   { id: 7, title: '园林绿化' },
      //   { id: 8, title: '暖通' },
      //   { id: 9, title: '装配式建筑' },
      //   { id: 10, title: '二手建材' },
      //   {
      //     id: 11, title: '其他材料',
      //     children: [
      //       {
      //         id: 11, title: '金属材料',
      //         children: [
      //           { id: 101, title: '方钢' },
      //           { id: 102, title: '扁钢' },
      //           { id: 103, title: '钢丝' },
      //           { id: 104, title: '工字钢' },
      //           { id: 105, title: '钢板' },
      //           { id: 106, title: '槽钢' },
      //           { id: 107, title: '钢纹线/钢丝束' },
      //           { id: 108, title: '铜带材' },
      //           { id: 109, title: '方钢' },
      //           { id: 110, title: '扁钢' },
      //           { id: 111, title: '钢丝' },
      //           { id: 112, title: '工字钢' },
      //         ]
      //       },
      //       { id: 12, title: '混泥土/砂浆' },
      //       { id: 13, title: '水泥/砖瓦' },
      //       { id: 14, title: '防水材料' },
      //       { id: 15, title: '保温耐火' },
      //       { id: 16, title: '成型构件' },
      //       { id: 17, title: '人防设备' },
      //       { id: 18, title: '周转材料' }
      //     ]
      //   },
      // ],
      list2: [],
      list3: [],
      isShowPanel: false,
      current_2: '',
      current_1: ''
    };
  },
  methods: {
    hidePanel() {
      this.isShowPanel = false
      this.current_1 = false
    },
    showPanel(cat) {
      this.current_1 = cat.value
      if (cat.children && Array.isArray(cat.children)) {
        this.isShowPanel = true
        this.list2 = cat.children
        if (this.list2.length > 0) {
          this.current_2 = this.list2[0].value
          this.showList3(this.list2[0])
        }
      } else {
        this.isShowPanel = false
      }
    },
    showList3(cat) {
      this.current_2 = cat.value
      let list = cat.children
      if (list && Array.isArray(list)) {
        this.list3 = list
      } else {
        this.list3 = []
      }
    },
    handleEnter(item) {
      try {
        this.current_2 = item.children[0].value
        this.list3 = item.children[0].children
      } catch (error) {
        this.current_2 = ''
        this.list3 = []
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.md-prod-cate {
  position: relative;
  z-index: 10;

  .hd {
    background: $blue url(~@/assets/images/logo-0.png) no-repeat left -.24rem top .01rem / .56rem;
    height: .5rem;
    border-radius: .04rem .04rem 0 0;
  }

  .list {
    padding: 0.2rem;

    .li {
      border-radius: .04rem;
      padding: .084rem .12rem;
      margin-bottom: .114rem;
      position: relative;

      &:last-of-type {
        margin-bottom: 0;
      }

      &:hover,
      &.on {
        color: $blue;
        background-color: $blueLight;

        .icon {
          background-image: url(~@/assets/images/icon-right3.png);
        }

        .panel {
          display: block;
        }
      }
    }

    .tit {
      flex: 1;
    }

    .icon {
      width: .06rem;
      height: .1rem;
      background: url(~@/assets/images/icon-right.png) no-repeat center / contain;
    }
  }

  .panel {
    position: absolute;
    left: 100%;
    top: -.7rem;
    z-index: 100;
    padding-left: .58rem;
    filter: drop-shadow(0 0 .3rem rgba(219, 236, 255, 0.8));
    display: none;

    &-cnt {
      background: #fff;
      border-radius: .04rem;
      position: relative;
      width: 12.6rem;
      height: 4.92rem;

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: .9rem;
        border: .1rem solid transparent;
        border-right-color: #fff;
        transform: translateX(-100%);
      }
    }

    .list2 {
      width: 2.52rem;
      border-right: 1px solid #e6f2f8;
      padding: .19rem .5rem .2rem .22rem;
      overflow-y: auto;
      height: 100%;

      .li2 {
        display: block;
        border-radius: .04rem;
        text-align: center;
        padding: 0.1rem .1rem .07rem;
        margin-bottom: .112rem;

        &:last-of-type {
          margin-bottom: 0;
        }

        &.on,
        &:hover {
          color: $blue;
          background: $blueLight;
        }
      }
    }

    .list3 {
      flex: 1;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      padding: .19rem 0 .2rem .47rem;

      .li3 {
        min-width: 1.4rem;
        border-radius: .04rem;
        border: solid 1px #cae1ec;
        margin-right: .2rem;
        margin-bottom: .2rem;
        padding: 0.08rem 0.1rem 0.07rem;
        text-align: center;

        &:hover {
          background: $blueLight;
          border-color: $blueLight;
        }
      }
    }
  }
}
</style>