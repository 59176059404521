import { render, staticRenderFns } from "./BasePopCode.vue?vue&type=template&id=ebdc32ea&scoped=true"
import script from "./BasePopCode.vue?vue&type=script&lang=js"
export * from "./BasePopCode.vue?vue&type=script&lang=js"
import style0 from "./BasePopCode.vue?vue&type=style&index=0&id=ebdc32ea&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ebdc32ea",
  null
  
)

export default component.exports