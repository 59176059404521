<template>
  <div class="list" :class="['list-' + row]">
    <template v-for="(img, img_index) in list">
      <router-link class="li" to="" :key="img_index"
        @mouseenter.native="handleEnter($event, img_index)"
        @mouseleave.native="handlelLeave($event, img_index)"
      >
        <ImgRatio
          :width="182"
          :height="102" 
          :src="img.src"
          fit='contain'
        />
        <div class="name flex-center color-f s16"
          :class="[img.enter, img.leave]"
        >{{img.name}}</div>
      </router-link>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array, default() { return [] }
    },
    row: {
      type: Number, default: 8
    }
  },
  data() {
    return {
    };
  },
  methods: {
    handleEnter(e, i) {
      let rect = e.target.getBoundingClientRect()
      let x1 = e.clientX - rect.left 
      let x2 = rect.right - e.clientX
      let x = Math.min(x1, x2)
      let y1 = e.clientY - rect.top
      let y2 = rect.bottom - e.clientY
      let y = Math.min(y1, y2)
      this.$set(this.list[i], 'leave', '')
      let name = ''
      if (x < y) { // 水平方向
        if (x1 < x2) { // console.log('左边');
          name = 'enter-left'
        } else{ // console.log('右边');
          name = 'enter-right'
        }
      } else { // 垂直方向
        if (y1 < y2) { // console.log('上边');
          name = 'enter-top'
        } else{ // console.log('下边');
          name = 'enter-bottom'
        }
      }
      this.$set(this.list[i], 'enter', name)
    },
    handlelLeave(e, i) {
      let rect = e.target.getBoundingClientRect()
      let name = ''
      this.$set(this.list[i], 'enter', '')
      if (rect.left > e.clientX) { // console.log('左边');
        name = 'leave-left'
      } else if (rect.right < e.clientX){ // console.log('右边');
        name = 'leave-right'
      } else if (rect.top > e.clientY) { // console.log('上边');
        name = 'leave-top'
      } else if (rect.bottom < e.clientY){ // console.log('下边');
        name = 'leave-bottom'
      }
      this.$set(this.list[i], 'leave', name)
    }
  }
};
</script>

<style lang="scss" scoped>
@keyframes enterLeft{
  0%{ transform: translateX(-100%); }
  100%{ transform: translateX(0); }
}
@keyframes enterRight{
  0%{ transform: translateX(100%); }
  100%{ transform: translateX(0); }
}
@keyframes enterTop{
  0%{ transform: translateY(-100%); }
  100%{ transform: translateY(0); }
}
@keyframes enterBottom{
  0%{ transform: translateY(100%); }
  100%{ transform: translateY(0); }
}
@keyframes leaveLeft{
  0%{ transform: translateX(0); }
  100%{ transform: translateX(-100%); }
}
@keyframes leaveRight{
  0%{ transform: translateX(0); }
  100%{ transform: translateX(100%); }
}
@keyframes leaveTop{
  0%{ transform: translateY(0); }
  100%{ transform: translateY(-100%); }
}
@keyframes leaveBottom{
  0%{ transform: translateY(0); }
  100%{ transform: translateY(100%); }
}
.list{
  display: flex; flex-wrap: wrap;
  margin-top: .17rem;
  filter: drop-shadow(0 0 .5rem rgba(225, 241, 250, 0.8));
  .li{
    margin-right: .12rem; margin-bottom: .12rem;
    position: relative;
    overflow: hidden;
  }
  &-8{
    .li{
      width: 1.82rem;
      &:nth-of-type(8n) {
        margin-right: 0;
      }
    }
  }
  &-6{
    .li{
      width: 2.05rem;
      &:nth-of-type(6n) {
        margin-right: 0;
      }
    }
  }
  .name{
    position: absolute; left: 0; top: 0;
    width: 100%; height: 100%;
    background: rgba($color: $blue, $alpha: 0.8);
    animation-duration: .2s;
    animation-timing-function: ease;
    animation-fill-mode: forwards;
    transform: translate(100%, 100%);
    &.enter-left{
      animation-name: enterLeft;
    }
    &.enter-right{
      animation-name: enterRight;
    }
    &.enter-top{
      animation-name: enterTop;
    }
    &.enter-bottom{
      animation-name: enterBottom;
    }
    &.leave-left{
      animation-name: leaveLeft;
    }
    &.leave-right{
      animation-name: leaveRight;
    }
    &.leave-top{
      animation-name: leaveTop;
    }
    &.leave-bottom{
      animation-name: leaveBottom;
    }
  }
}
</style>