<template>
  <div class="c-card md-service-intro flex-middle" v-if="info">
    <div class="text">
      <div class="c-title-2 s22">{{info.title}}</div>
      <div class="desc s16 color-4" v-html="info.desc"></div>
    </div>
    <ImgRatio :src='info.img' :width='440' :height='308'></ImgRatio>
  </div>
</template>

<script>
export default {
  props: {
    info: { type: Object, default() { return null } }
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss">
.md-service-intro{
  padding: .37rem .3rem .3rem;
  margin-top: .4rem;
  .text{
    flex: 1;
  }
  .desc{
    line-height: calc(30 / 16 * 1em);
    margin-top: 0.17rem;
    p{
      margin-bottom: .16rem;
      &:last-of-type{
        margin-bottom: 0;
      }
    }
  }
  .c-pic{
    width: 4.4rem;
    margin-left: .5rem;
    border: 1px solid #dcecf3;
    border-radius: .04rem;
    background: #f4f9fc;
  }
}
</style>