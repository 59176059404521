<template>
  <BaseDialog
    :visible.sync="visible"
    title='投诉卖家'
    confirm-text='提交'
    @close="$emit('close')"
    @cancel="$emit('cancel')"
    @confirm="handleSubmit"
  >
  <form class="form s16 color-2">
      <!-- 关联订单 -->
      <div class="" style="margin-bottom: .1rem;" v-for="(item,index) in orderGoodsList" :key="index">
        <div class="form-item_content">
          <div class="goods flex">
            <img :src="item.goods_image" alt="" class="goods-cover">
            <div class="s16 color-4 text">
              <div class="tit">
                <span class="color-9 info" style="width: 110px">商品标题：</span><span class="text-overflow">{{ item.goods_name }}</span>
              </div>
              <div class="flex-middle flex-between row-2">
                <div class="tit"><span class="color-9 info"  style="width: 60px">规格：</span><span class="text-overflow" style="flex: 1">{{ item.spec_key_name }}</span></div>
                <div style="width: 60px; text-align: right;">x{{ item.goods_num  }}</div>
              </div>
              <div class="tags flex-wrap s12">
                <div class="tag color-blue" v-for="(item,index) in item.tags" :key="index">{{ item }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 投诉原因 -->
      <div class="form-item">
        <label class="form-item_label required">
          <div><p class="p_label">投诉原因</p></div>
        </label>
        <div class="form-item_content">
          <div class="form-item_content">
            <el-select style="width: 100%" @change="changeReportType" v-model="report_type">
              <el-option
              v-for="item in reportTypeList"
              :value="item.type" 
              :label="item.report_type"
              :key="item.type">
              </el-option>
            </el-select>
            <div class="tip" v-if="showType">请选择投诉原因</div>
          <!-- <BaseSelect :options='options' v-model="reason" ipt-class='ipt' placeholder="请选择理由"></BaseSelect> -->
        </div>
        </div>
      </div>
      <!-- 反馈问题 -->
      <div class="form-item">
        <label class="form-item_label required">
          <div><p class="p_label">反馈问题</p></div>
        </label>
        <div class="form-item_content">
          <div class="form-item_content">
            <el-select style="width: 100%" v-model="report_problem">
              <el-option
              v-for="item in reportQuesList"
              :value="item" 
              :label="item"
              :key="item">
              </el-option>
            </el-select>
            <div class="tip" v-if="showQues">请选择反馈问题</div>
          <!-- <BaseSelect :options='options2' v-model="qs" ipt-class='ipt' placeholder="请选择"></BaseSelect> -->
        </div>
        </div>
      </div>
      <!-- 说明原因 -->
      <div class="form-item">
        <label class="form-item_label">
          <div><p class="p_label">说明原因</p></div>
        </label>
        <div class="form-item_content">
          <textarea type="text" class="ipt" placeholder="请输入说明原因" v-model="content">
          </textarea>
        </div>
      </div>
      <!-- 图片 -->
      <div class="form-item">
        <label class="form-item_label">
          <div><p class="p_label">上传凭证</p></div>
        </label>
        <div class="form-item_content">
          <BaseFile v-model="images" placeholder="请上传凭证" :max="3"></BaseFile>
        </div>
      </div>
    </form>
  </BaseDialog>
</template>

<script>
import { reportReadyApi, reportAddApi } from "@/request/api/order.js";
export default {
  props: {
    order_id: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      visible: true,
      content: '',
      report_type: "",
      report_problem: "",
      count: 3,
      images: [],
      reason: '',

      orderGoodsList: [],
      reportTypeList: [],
      reportQuesList: [],
      showType: false,
      showQues: false
    };
  },
  watch: {
    report_type(val){
      if(!val){
        this.showType = true;
      }else{
        this.showType = false;
      }
    },
    report_problem(val){
      if(!val){
        this.showQues = true;
      }else{
        this.showQues = false;
      }
    }
  },
  mounted(){
    this.getReportReady();
  },
  methods: {
    handleSubmit() {
      console.log(132);
      this.reportAdd();
      this.$emit('confirm') // 添加成功，页面要重新加载地址列表
    },
    //获取预设值
    getReportReady(){
      reportReadyApi({order_id: this.order_id}).then(res => {
        if(res.status == 200){
          this.orderGoodsList = res.data.order_goods;
          this.reportTypeList = res.data.types;
        }
      })
    },
    //获取反馈问题
    changeReportType(val){
      this.reportTypeList.forEach(item => {
        if(item.type == val){
          this.reportQuesList = item.report_problem;
        }
      })
    },
    validate(){
      if(!this.report_type){
        this.showType = true;
      }
      if(!this.report_problem){
        this.showQues = true;
      }
      if(this.showType || this.showQues){
        return false
      }else{
        return true
      }
    },
    //提交投诉
    reportAdd(){
      if(!this.validate()) return;
      let params = {
        order_id: this.order_id,
        report_type: this.report_type,
        report_problem: this.report_problem,
        content: this.content,
        images: this.images.length > 0 ? this.images.join(",") : ""
      }
      reportAddApi(params).then(res => {
        if(res.status == 200){
          this.$message.success("提交成功")
          this.$emit("confirm");
        }
      })
    }
  }
};
</script>

<style lang="scss" scoped>
  .form{
    padding: .3rem .3rem .2rem;
    &-item{
      display: flex; align-items: flex-start;
      margin-bottom: .2rem;
      &:last-of-type{
        margin-bottom: 0;
      }
      &_label{
        min-width: .8rem;
        min-height: .5rem;
        display: flex; align-items: center;
        line-height: 1.4em;
        position: relative;
        padding-right: .07rem;
        &.required {
          .p_label{
            position: relative;
            &:after{
              content: '*';
              color: #f53e39;
              position: absolute; right: -.5em; top: .02rem;
            }
          }
          .p_tips{
            text-align: center;
          }
        }
      }
      &_content{
        flex: 1;
      }
    }
    ::v-deep .ipt{
      width: 100%; height: .5rem;
      border-radius: .04rem;
      border: solid 1px #cae1ec;
      padding: 0 .14rem;
      transition: .3s;
      &::placeholder{
        color: #999;
      }
      &:focus{
        border-color: $blue;
      }
    }
    textarea.ipt{
      display: block;
      height: 1.5rem;
      padding: .14rem .14rem;
    }
    .goods{
      border-radius: 0.04rem;
      border: dashed 0.01rem #cae1ec;
      padding: .1rem; padding-right: .19rem;
      &-cover{
        width: .6rem; height: .6rem;
        object-fit: cover;
        border-radius: .04rem;
        margin-right: .19rem;
      }
      .text{
        flex: 1;
      }
      .row-2{
        margin-top: .12rem;
      }
    }
    .tip{
      margin-top: 5px;
      color: red;
    }
  }
</style>