import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/ui',
    name: 'ui',
    component: () => import(/* webpackChunkName: "ui" */ '../views/UI.vue'),
    meta: { title: '组件ui展示' }
  },
  {
    path: '/preview',
    name: 'preview',
    component: () => import(/* webpackChunkName: "preview" */ '../views/Preview.vue'),
    meta: { title: '页面列表' }
  }, 
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login/Login.vue'),
    meta: { title: '登录' }
  }, 
  {
    path: '/register',
    name: 'register',
    component: () => import(/* webpackChunkName: "register" */ '../views/Login/Register.vue'),
    meta: { title: '注册' }
  }, 
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
    meta: { title: '首页' }
  }, 
  {
    path: '/partner',
    name: 'partner',
    component: () => import(/* webpackChunkName: "partner" */ '../views/Partner.vue'),
    meta: { title: '合作伙伴' }
  }, 
  {
    path: '/join',
    name: 'join',
    component: () => import(/* webpackChunkName: "join" */ '../views/Join.vue'),
    meta: { title: '加入易筑' }
  }, 
  {
    path: '/news',
    name: 'news',
    component: () => import(/* webpackChunkName: "news" */ '../views/News.vue'),
    meta: { title: '行业资讯' }
  }, 
  {
    path: '/article',
    name: 'article',
    component: () => import(/* webpackChunkName: "article" */ '../views/Article.vue'),
    meta: { title: '资讯详情', parent: 'news' }
  }, 
  {
    path: '/construction',
    name: 'construction',
    component: () => import(/* webpackChunkName: "construction" */ '../views/Construction.vue'),
    meta: { title: '智慧工地' }
  }, 
  {
    path: '/construction-detail',
    name: 'construction-detail',
    component: () => import(/* webpackChunkName: "ConstructionDetail" */ '../views/ConstructionDetail.vue'),
    meta: { title: '智慧工地详情', parent: 'construction' }
  }, 
  {
    path: '/brand',
    name: 'brand',
    component: () => import(/* webpackChunkName: "brand" */ '../views/Brand.vue'),
    meta: { title: '品牌专区' }
  }, 
  {
    path: '/feedback',
    name: 'feedback',
    component: () => import(/* webpackChunkName: "feedback" */ '../views/Feedback.vue'),
    meta: { title: '反馈建议' }
  }, 
  {
    path: '/finance',
    name: 'finance',
    component: () => import(/* webpackChunkName: "finance" */ '../views/Finance.vue'),
    meta: { title: '供应链金融' }
  }, 
  {
    path: '/rank',
    name: 'rank',
    component: () => import(/* webpackChunkName: "rank" */ '../views/Rank.vue'),
    meta: { title: '优质榜' }
  }, 
  {
    path: '/member-intro',
    name: 'member-intro',
    component: () => import(/* webpackChunkName: "member-intro" */ '../views/MemberIntro.vue'),
    meta: { title: '会员等级讲解' }
  }, 
  {
    path: '/bid',
    name: 'bid',
    component: () => import(/* webpackChunkName: "member-intro" */ '../views/Bid/Index.vue'),
    meta: { title: '招投标', toChildren: true },
    children: [
      {
        path: '/bid/bid',
        name: 'bid-bid',
        component: () => import(/* webpackChunkName: "bid-bid" */ '../views/Bid/Bid.vue'),
        meta: { parent: 'bid', title: '招投标-招投标' },
      }, {
        path: '/bid/material',
        name: 'bid-material',
        component: () => import(/* webpackChunkName: "bid-material" */ '../views/Bid/Material.vue'),
        meta: { parent: 'bid', title: '材料大厅-招投标' },
      }
    ],
  }, 
  {
    path: '/kefu',
    name: 'kefu',
    component: () => import(/* webpackChunkName: "kefu" */ '../views/Kefu.vue'),
    meta: { title: '客服' },
  }, 
  {
    path: '/logistics',
    name: 'logistics',
    component: () => import(/* webpackChunkName: "logistics" */ '../views/Logistics.vue'),
    meta: { title: '云仓物流' },
  }, 
  {
    path: '/auth',
    name: 'auth',
    component: () => import(/* webpackChunkName: "auth" */ '../views/Auth/Index.vue'),
    meta: { title: '身份认证', toChildren: true },
    children: [
      {
        path: '/auth/enterprise',
        name: 'auth-enterprise',
        component: () => import(/* webpackChunkName: "auth-enterprise" */ '../views/Auth/Enterprise.vue'),
        meta: { parent: 'auth', title: '招标企业身份认证' }
      }, {
        path: '/auth/purchase',
        name: 'auth-purchase',
        component: () => import(/* webpackChunkName: "auth-purchase" */ '../views/Auth/Purchase.vue'),
        meta: { parent: 'auth', title: '战略采购商身份认证' }
      }, {
        path: '/auth/material',
        name: 'auth-material',
        component: () => import(/* webpackChunkName: "auth-material" */ '../views/Auth/Material.vue'),
        meta: { parent: 'auth', title: '材料供应商身份认证' }
      }, {
        path: '/auth/project',
        name: 'auth-project',
        component: () => import(/* webpackChunkName: "auth-project" */ '../views/Auth/Project.vue'),
        meta: { parent: 'auth', title: '工程项目身份认证' }
      }, {
        path: '/auth/service',
        name: 'auth-service',
        component: () => import(/* webpackChunkName: "auth-service" */ '../views/Auth/Service.vue'),
        meta: { parent: 'auth', title: '工程配套服务商认证' }
      }, {
        path: '/auth/finance',
        name: 'auth-finance',
        component: () => import(/* webpackChunkName: "auth-finance" */ '../views/Auth/Finance.vue'),
        meta: { parent: 'auth', title: '供应链金融企业身份认证' }
      }, {
        path: '/auth/pro-sub',
        name: 'auth-pro-sub',
        component: () => import(/* webpackChunkName: "auth-pro-sub" */ '../views/Auth/ProSub.vue'),
        meta: { parent: 'auth', title: '专业分包商认证' }
      }, {
        path: '/auth/labor-sub',
        name: 'auth-labor-sub',
        component: () => import(/* webpackChunkName: "auth-labor-sub" */ '../views/Auth/LaborSub.vue'),
        meta: { parent: 'auth', title: '劳务分包身份认证' }
      }
    ]
  }, 
  {
    path: '/service',
    name: 'service',
    component: () => import(/* webpackChunkName: "service" */ '../views/Service/Index.vue'),
    meta: { title: '配套服务', toChildren: true },
    children: [
      {
        path: '/service/cost',
        name: 'service-cost',
        component: () => import(/* webpackChunkName: "service-cost" */ '../views/Service/Cost.vue'),
        meta: { parent: 'service', title: '工程造价-配套服务' }
      }, {
        path: '/service/data',
        name: 'service-data',
        component: () => import(/* webpackChunkName: "service-data" */ '../views/Service/Data.vue'),
        meta: { parent: 'service', title: '工程资料-配套服务' }
      }, {
        path: '/service/budget',
        name: 'service-budget',
        component: () => import(/* webpackChunkName: "service-budget" */ '../views/Service/Budget.vue'),
        meta: { parent: 'service', title: '工程预决算-配套服务' }
      }, {
        path: '/service/finance',
        name: 'service-finance',
        component: () => import(/* webpackChunkName: "service-finance" */ '../views/Service/Finance.vue'),
        meta: { parent: 'service', title: '工程财税-配套服务' }
      }, {
        path: '/service/BLM',
        name: 'service-BLM',
        component: () => import(/* webpackChunkName: "service-BLM" */ '../views/Service/BLM.vue'),
        meta: { parent: 'service', title: 'BLM工程-配套服务' }
      }, {
        path: '/service/letter',
        name: 'service-letter',
        component: () => import(/* webpackChunkName: "service-letter" */ '../views/Service/Letter.vue'),
        meta: { parent: 'service', title: '工程保函-配套服务' }
      }, {
        path: '/service/material',
        name: 'service-material',
        component: () => import(/* webpackChunkName: "service-material" */ '../views/Service/Material.vue'),
        meta: { parent: 'service', title: '材料送检-配套服务' }
      }, {
        path: '/service/design',
        name: 'service-design',
        component: () => import(/* webpackChunkName: "service-design" */ '../views/Service/Design.vue'),
        meta: { parent: 'service', title: '深化设计-配套服务' }
      }, {
        path: '/service/legal',
        name: 'service-legal',
        component: () => import(/* webpackChunkName: "service-legal" */ '../views/Service/Legal.vue'),
        meta: { parent: 'service', title: '工程法务-配套服务' }
      }, {
        path: '/service/advertising',
        name: 'service-advertising',
        component: () => import(/* webpackChunkName: "service-advertising" */ '../views/Service/Advertising.vue'),
        meta: { parent: 'service', title: '工程广告-配套服务' }
      }, {
        path: '/service/query',
        name: 'service-query',
        component: () => import(/* webpackChunkName: "service-query" */ '../views/Service/Query.vue'),
        meta: { parent: 'service', title: '规范查询-配套服务' }
      }, {
        path: '/service/tools',
        name: 'service-tools',
        component: () => import(/* webpackChunkName: "service-tools" */ '../views/Service/Tools.vue'),
        meta: { parent: 'service', title: '辅助工具-配套服务' }
      }, {
        path: '/service/article',
        name: 'service-article',
        component: () => import(/* webpackChunkName: "service-article" */ '../views/Service/Article.vue'),
        meta: { parent: 'service', title: '施工指导教程详情-辅助工具-配套服务' }
      }
    ]
  }, 
  {
    path: '/shop',
    name: 'shop',
    component: () => import(/* webpackChunkName: "shop" */ '../views/Shop/Index.vue'),
    meta: { title: '集采商城' },
  }, 
  {
    path: '/shop/list',
    name: 'shop-list',
    component: () => import(/* webpackChunkName: "shop-list" */ '../views/Shop/List.vue'),
    meta: { title: '集采商城', parent: 'shop' },
  }, 
  {
    path: '/shop/seller',
    name: 'shop-seller',
    component: () => import(/* webpackChunkName: "shop-seller" */ '../views/Shop/Seller.vue'),
    meta: { title: '卖家店铺-集采商城', parent: 'shop' },
  },
  {
    path: '/shop-cart',
    name: 'shop-cart',
    component: () => import(/* webpackChunkName: "shop-cart" */ '../views/Shop/ShopCart.vue'),
    meta: { title: '购物车', parent: 'shop' },
  }, 
  {
    path: '/product-detail',
    name: 'product-detail',
    component: () => import(/* webpackChunkName: "product-detail" */ '../views/Shop/ProductDetail.vue'),
    meta: { title: '商品详情', parent: 'shop' },
  }, 
  {
    path: '/pay',
    name: 'pay',
    component: () => import(/* webpackChunkName: "order-fill" */ '../views/Shop/Pay.vue'),
    meta: { title: '支付', parent: 'shop' },
  }, 
  {
    path: '/order-fill',
    name: 'order-fill',
    component: () => import(/* webpackChunkName: "pay" */ '../views/Shop/OrderFill.vue'),
    meta: { title: '下单页', parent: 'shop' },
  }, 
  {
    path: '/pay2',
    name: 'pay2',
    component: () => import(/* webpackChunkName: "pay2" */ '../views/Shop/Pay2.vue'),
    meta: { title: '全款支付', parent: 'shop' },
  }, 
  {
    path: '/pay-code',
    name: 'pay-code',
    component: () => import(/* webpackChunkName: "pay-code" */ '../views/Shop/PayCode.vue'),
    meta: { title: '扫码支付', parent: 'shop' },
  }, 
  {
    path: '/pay-offline',
    name: 'pay-offline',
    component: () => import(/* webpackChunkName: "pay-offline" */ '../views/Shop/PayOffline.vue'),
    meta: { title: '线下支付', parent: 'shop' },
  }, 
  {
    path: '/pay-success',
    name: 'pay-success',
    component: () => import(/* webpackChunkName: "pay-success" */ '../views/Shop/PaySuccess.vue'),
    meta: { title: '支付成功', parent: 'shop' },
  }, 
  {
    path: '/order',
    name: 'order',
    component: () => import(/* webpackChunkName: "order" */ '../views/Order/List.vue'),
    meta: { title: '订单列表', parent: 'home' },
  }, 
  {
    path: '/order-detail',
    name: 'order-detail',
    component: () => import(/* webpackChunkName: "order-detail" */ '../views/Order/Detail.vue'),
    meta: { title: '订单详情', parent: 'home' },
  }, 
  {
    path: '/delivery-note',
    name: 'delivery-note',
    component: () => import(/* webpackChunkName: "delivery-note" */ '../views/Order/DeliveryNote.vue'),
    meta: { title: '送货单', parent: 'home' },
  }, 
  {
    path: '/after-sales',
    name: 'after-sales',
    component: () => import(/* webpackChunkName: "after-sales" */ '../views/Order/AfterSales.vue'),
    meta: { title: '发起售后', parent: 'home' },
  }, 
  {
    path: '/after-sales-success',
    name: 'after-sales-success',
    component: () => import(/* webpackChunkName: "after-sales-success" */ '../views/Order/AfterSalesSuccess.vue'),
    meta: { title: '发起售后', parent: 'home' },
  }, 
  {
    path: '/data',
    name: 'data',
    component: () => import(/* webpackChunkName: "data" */ '../views/Data.vue'),
    meta: { title: '大数据中心' },
  }, 
  {
    path: '/agreement',
    name: 'agreement',
    component: () => import(/* webpackChunkName: "agreement" */ '../views/Agreement.vue'),
    meta: { title: '协议' },
  },
  {
    path: '/collection',
    name: 'collection',
    component: () => import(/* webpackChunkName: "collection" */ '../views/collection/Collection.vue'),
    meta: { title: '收藏' },
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})
router.beforeEach((to, from, next) => {
  let temp = routes.find(el => el.name == to.name)
  if (temp && temp.meta && temp.meta.toChildren && temp.children && temp.children.length > 0) {
    next(temp.children[0].path)
    return;
  }
  let title = '易筑'
  if (to.meta && to.meta.title) {
    title = to.meta.title + '-' + title
  }
  document.title = title
  next()
})
export default router
export const list = routes
