<template>
  <div class="home-rank c-card">
    <div class="title s18 font-medium">中标排行榜</div>
    <div class="list">
      <template v-for="(item, index) in list">
        <div class="li flex-middle" :key="index">
          <div class="num">
            <div class="s12 num-text color-f text-center">{{ index + 1 }}</div>
          </div>
          <div class="tit ep1 s16">{{ item.title }}</div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default() { return [] }
    }
  },
  data() {
    return {
      data: [
      ]
    };
  },
};
</script>

<style lang="scss" scoped>
.home-rank{
  padding: .27rem .3rem;
  .list{
    margin-top: .09rem;
    .li{
      border-bottom: 1px solid #e6f2f8;
      padding: .175rem 0;
      &:nth-child(n+4){
        .num{
          .num-text{
            width: 0.2rem; line-height: .2rem;
            background: #ccc;
            border-radius: 50%;
          }
        }
      }
      &:nth-child(-n+3) {
        .num{
          position: relative;
          width: .19rem; height: .25rem;
          margin-right: .11rem;
          background-size: 100%;
          background-position: center top;
          .num-text{
            width: .15rem; line-height: .15rem;
            margin: .02rem auto 0;
          }
        }
      }
      &:nth-child(1){
        .num{
          background-image: url(~@/assets/images/icon-13.png);
        }
      }
      &:nth-child(2){
        .num{
          background-image: url(~@/assets/images/icon-14.png);
        }
      }
      &:nth-child(3){
        .num{
          background-image: url(~@/assets/images/icon-15.png);
        }
      }
      .num{
        margin-right: .1rem;
      }
      .tit{

      }
    }
  }
}
</style>