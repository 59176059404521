<template>
  <div class="home-cate2 c-card">
    <div class="list">
      <template v-for="item in destList">
        <router-link class="li" :key="item.cat_id" :to="link">
          <div class="img">
            <img :src="item.officia_url">
          </div>
          <div class="tit s16">{{ item.cat_name }}</div>
        </router-link>
      </template>
    </div>
    <router-link class="more s16" :to="link">查看更多</router-link>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default() {
        return []
      }
    },
    link: {
      type: String,
      default: ''
    }
  },
  data() {
    return {};
  },
  computed: {
    destList() {
      let res = []
      if (this.list.length > 6) {
        res = this.list.slice(0, 5)
        res.push({ id: -1, img: require('@/assets/images/cate-6.png'), title: '其他' })
      } else {
        res = this.list
      }
      return res
    }
  }
};
</script>

<style lang="scss" scoped>
.home-cate2 {
  background: url(~@/assets/images/homeCate2Bg.jpg);
  height: 4.96rem;
  padding: .45rem .3rem .3rem;
  text-align: center;
  color: #fff;

  .list {
    display: flex;
    flex-wrap: wrap;

    .li {
      width: 50%;
      text-align: center;
      margin-bottom: .165rem;

      &:hover {
        text-decoration: underline;

        img {
          transform: scale(1.1);
        }
      }
    }

    .img {
      font-size: 0;

      img {
        height: .72rem;
        transition: .3s;
      }
    }

    .tit {
      margin-top: .08rem;
    }
  }

  .more {
    display: inline-block;
    border: 1px solid #fff;
    border-radius: .04rem;
    text-align: center;
    line-height: calc(38 / 16 * 1em);
    min-width: 1.2rem;
    padding: 0 1em;
    margin-top: .19rem;
    transition: .3s;

    &:hover {
      background: #fff;
      color: $blue;
    }
  }
}</style>