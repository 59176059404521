import { render, staticRenderFns } from "./BaseProductNumber.vue?vue&type=template&id=09752622&scoped=true"
import script from "./BaseProductNumber.vue?vue&type=script&lang=js"
export * from "./BaseProductNumber.vue?vue&type=script&lang=js"
import style0 from "./BaseProductNumber.vue?vue&type=style&index=0&id=09752622&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "09752622",
  null
  
)

export default component.exports