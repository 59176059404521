<template>
  <div class="md-product-selected">
    <div class="c-title-2 s22" v-ani.fade-up>精选商品</div>
    <div class="list">
      <template v-for="item in list">
        <ProductItem :value="item" :key="item.id" v-ani.fade-up />
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    }
  },
  data() {
    return {
      // list: [
      //   {
      //     id: 1,
      //     img: require('@/assets/images/prod-1.jpg'),
      //     title: '水性钢结构防护彩钢瓦漆铁红中灰金属防锈底漆水性工业漆',
      //     unit: '桶',
      //     price: '¥280.00',
      //     sales: '150万人已购买',
      //     place: '广东-广州',
      //     data1: '官方店',
      //     data2: '45天账期',
      //     shop: {
      //       name: '易筑官方旗舰店',
      //       icon: require('@/assets/images/icon-89.png')
      //     }
      //   }, {
      //     id: 2,
      //     img: require('@/assets/images/prod-2.jpg'),
      //     title: '水上乐园挂壁式泳池防水水下灯LED水底灯',
      //     unit: '桶',
      //     price: '¥280.00',
      //     sales: '150万人已购买',
      //     place: '广东-广州',
      //     data1: '官方店',
      //     data2: '45天账期',
      //     shop: {
      //       name: '易筑官方旗舰店',
      //       icon: require('@/assets/images/icon-89.png')
      //     }
      //   }, {
      //     id: 3,
      //     img: require('@/assets/images/prod-3.jpg'),
      //     title: '佛山瓷砖800x800客厅地砖全瓷通体大理石地板砖',
      //     unit: '桶',
      //     price: '¥280.00',
      //     sales: '150万人已购买',
      //     place: '广东-广州',
      //     data1: '官方店',
      //     data2: '45天账期',
      //     shop: {
      //       name: '易筑官方旗舰店',
      //       icon: require('@/assets/images/icon-89.png')
      //     }
      //   }
      // ]
    };
  },
};
</script>

<style lang="scss" scoped>
.md-product-selected {
  margin-top: .3rem;

  .list {
    margin-top: .3rem;
    width: 3rem;
  }

  .c-product-item {
    margin-bottom: .2rem;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}</style>