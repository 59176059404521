var render = function render(){var _vm=this,_c=_vm._self._c;return _c(_vm.tag,{tag:"component",staticClass:"base-button",class:{
    's14': _vm.size == 'xs' || _vm.size == 'mini',
    's16': _vm.size == 'normal' || _vm.size == 'large',
    ['base-button--' + _vm.color]: true,
    ['base-button--' + _vm.size]: true,
    'is-border': _vm.styleType == 'border',
    'is-light': _vm.styleType == 'light',
    'no-round': !_vm.round,
    'hover': _vm.hover === true,
    ['hover-' + _vm.hover]: _vm.hoverColorValided,
  },attrs:{"to":_vm.to},on:{"click":function($event){return _vm.$emit('click', $event)}}},[_vm._t("default"),(_vm.icon)?[_c('span',{staticClass:"icon-right"})]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }