// 下单管理
import request from '@/request/request';


/* 支付 */
// 订单立即购买
export const CartBuyNowApi = (params) => request.post('/pc/cart/buy_now', params);
// 订单预确认订单
export const CartConfirmOrderApi = (params) => request.post('/pc/cart/confirm_order', params);
// 订单-提交
export const CartSubOrderApi = (params) => request.post('/pc/cart/sub_order', params);
// 订单待支付金额信息
export const OrderAmountApi = (params) => request.post('/pc/payment/order_amount', params);
// 订单支付
export const OrderPayApi = (params) => request.post('/pc/payment/pay', params);
// 订单支付结果检测
export const OrderPayCheckApi = (params) => request.post('/pc/payment/pay_check', params);
// 支付结果
export const OrderPayDetailApi = (params) => request.post('/pc/order/order_detail', params);







/* 发票管理 */
// 列表
export const InvoiceListApi = (params) => request.post('/pc/invoice/data_list', params);
// 新增
export const InvoiceAddApi = (params) => request.post('/pc/invoice/data_add', params);






/* 项目管理 */
// 列表
export const PurchaseListApi = (params) => request.post('/pc/purchase/getList', params);
// 添加和编辑
export const PurchaseAddApi = (params) => request.post('/pc/purchase/add', params);
// 默认项目
export const PurchaseDefaultApi = (params) => request.post('/pc/purchase/get_default', params);




/* 地址管理 */
// 列表
export const AddressListApi = (params) => request.post('/pc/address/getAddressList', params);
// 删除
export const AddressDelApi = (params) => request.post('/pc/address/del_address', params);
// 设置默认地址
export const AddressSetDefaultApi = (params) => request.post('/pc/address/setDefaultAddress', params);
// 获取默认地址
export const AddressDefaultApi = (params) => request.post('/pc/address/address_default', params);
// 详情
export const AddressDetailApi = (params) => request.post('/pc/address/address_details', params);
// 增改
export const AddressActionApi = (params) => request.post('/pc/address/actionAddress', params);


/* 订单列表 */
// 数量统计
export const OrderDataCountApi = (params) => request.post('/pc/order/data_count', params);

// 列表
export const OrderListApi = (params) => request.post('/pc/order/data_list', params);

// 详情
export const OrderDetailApi = (params) => request.post('/pc/order/data_detail', params);

// 还款记录
export const PeriodPayLogApi = (params) => request.post('/pc/order/period_pay_log', params);

// 送货单列表
export const SendLogListApi = (params) => request.post('/pc/order/send_log_list', params);

// 送货单详情
export const SendLogDetailApi = (params) => request.post('/pc/order/send_log', params);

// 物流信息
export const SendLogShippingilApi = (params) => request.post('/pc/order/send_log_shipping', params);

// 催发货
export const RemindSendApi = (params) => request.post('/pc/order/remind_send', params);

// 取消订单
export const cancelOrderApi = (params) => request.post('/pc/order/cancel', params);

// 确认收货
export const receiveOrderApi = (params) => request.post('/pc/order/receive', params);

// 删除订单
export const deleteOrderApi = (params) => request.post('/pc/order/delete', params);

// 评价
export const commentOrderApi = (params) => request.post('/pc/order/comment', params);

// 送货单-确认收货
export const sendLogConfirmOrderApi = (params) => request.post('/pc/order/send_log_confirm', params);

// 送货单-导出
export const exportSendLogOrderApi = (params) => request.post('/pc/order/send_sn_info', params);

// 上传合同
export const orderContractUploadApi = (params) => request.post('pc/order/contract_upload', params);

//投诉卖家
//预设数据
export const reportReadyApi = (params) => request.post('/pc/report/suppliers_ready', params);

//预设数据
export const reportAddApi = (params) => request.post('/pc/report/suppliers_add', params);


//再次购买
export const buyAgainApi = (params) => request.post('/pc/order/buy_again', params);


