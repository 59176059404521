<template>
  <div class="md-bid-list">
    <div class="hd flex-middle">
      <div class="c-title-2 s22">最新招投标</div>
      <div class="msg flex-middle s16 color-9">
        <!-- 消息上下轮播 -->
        <swiper class="swiper" :options="options">
          <swiper-slide class="slide flex-middle" v-for="(item,index) in msgList" :key="index">
            <p>{{ item.title }}</p>
            <p class="time">{{ item.time }}</p>
          </swiper-slide>
        </swiper>
        <div class="more color-blue" @click="$emit('showCode')">立即查看</div>
      </div>
    </div>
    <div class="list">
      <template v-for="item in list">
        <div class="li c-card flex-middle" :key="item.id" v-ani.fade-up @click="jumpClick(item)">
          <div class="col-1">
            <div class="row-1 flex-middle">
              <div class="icon-label s14 color-f">新</div>
              <div class="tit s22 color-2 font-medium">{{item.title}}</div>
              <!-- 市政 -->
              <BaseButton class="tag font-light" style-type='light' size='xs' :hover='false'>{{item.cat_name}}</BaseButton>
              <!-- 地址 -->
              <BaseButton class="tag font-light" style-type='light' size='xs' :hover='false'>{{item.city}}</BaseButton>
              <!-- 来源 -->
              <BaseButton class="tag font-light" style-type='light' size='xs' :hover='false'
                :color="item.sourse == 1 ? 'brown' : item.sourse == 2 ? 'red' : 'blue'"
              >{{sourseText(item.sourse)}}</BaseButton>
            </div>
            <div class="row-2 flex-middle">
              <div class="s16 color-4">项目地址: {{item.address}}</div>
              <!-- 状态 -->
              <div
                class="state s14 font-medium"
                :style="'color:' + stateColor(item.status)"
              >
                {{item.status}}
              </div>
            </div>
          </div>
          <div class="col-2 flex-middle s16">
            <div class="color-4">发布时间: {{item.public_time}}</div>
            <div class="split"></div>
            <div class="color-red">报名截止时间: {{item.end_time}}</div>
          </div>
          <BaseButton class="link" tag='router-link' to='' style-type="light" size='large'
          >查看详情</BaseButton>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default() { return [] }
    },
    msgList: {
      type: Array,
      default() { return [] }
    },
    type:{
      type: String,
      default: ""
    }
  },
  data() {
    return {
      // list: [
      //   {
      //     id: 1,
      //     title: '广州创维产业园消防二次改造工程',
      //     tag: '市政',
      //     region: '广东省-广州',
      //     sourse: 1,
      //     projectAddress: '广东省广州市天河区盈汇街8号首层、10号首层、12号商铺',
      //     state: 1,
      //     publishTime: '2023/05/14 14:51:11',
      //     endTime: '2023/05/14 14:51:11'
      //   }, {
      //     id: 2,
      //     title: '广州创维产业园消防二次改造工程',
      //     tag: '市政',
      //     region: '广东省-广州',
      //     sourse: 2,
      //     projectAddress: '广东省广州市天河区盈汇街8号首层、10号首层、12号商铺',
      //     state: 2,
      //     publishTime: '2023/05/14 14:51:11',
      //     endTime: '2023/05/14 14:51:11'
      //   }, {
      //     id: 3,
      //     title: '广州创维产业园消防二次改造工程',
      //     tag: '市政',
      //     region: '广东省-广州',
      //     sourse: 1,
      //     projectAddress: '广东省广州市天河区盈汇街8号首层、10号首层、12号商铺',
      //     state: 3,
      //     publishTime: '2023/05/14 14:51:11',
      //     endTime: '2023/05/14 14:51:11'
      //   }, {
      //     id: 4,
      //     title: '广州创维产业园消防二次改造工程',
      //     tag: '市政',
      //     region: '广东省-广州',
      //     sourse: 2,
      //     projectAddress: '广东省广州市天河区盈汇街8号首层、10号首层、12号商铺',
      //     state: 4,
      //     publishTime: '2023/05/14 14:51:11',
      //     endTime: '2023/05/14 14:51:11'
      //   },  {
      //     id: 5,
      //     title: '广州创维产业园消防二次改造工程',
      //     tag: '市政',
      //     region: '广东省-广州',
      //     sourse: 1,
      //     projectAddress: '广东省广州市天河区盈汇街8号首层、10号首层、12号商铺',
      //     state: 5,
      //     publishTime: '2023/05/14 14:51:11',
      //     endTime: '2023/05/14 14:51:11'
      //   }, {
      //     id: 6,
      //     title: '广州创维产业园消防二次改造工程',
      //     tag: '市政',
      //     region: '广东省-广州',
      //     sourse: 2,
      //     projectAddress: '广东省广州市天河区盈汇街8号首层、10号首层、12号商铺',
      //     state: 1,
      //     publishTime: '2023/05/14 14:51:11',
      //     endTime: '2023/05/14 14:51:11'
      //   }
      // ],
      options: {
        speed: 500,
        autoplay: 2000,
        autoplayDisableOnInteraction: false,
        slidesPerView: 1,
        spaceBetween: 10,
        observer: true,
        observeParents: true,
        direction: 'vertical',
        loop: true
      }
    };
  },
  methods: {
    sourseText(data) {
      switch (data) {
        case 1: return '采购商发布';
        case 2: return '平台直采';
        default: return '';
      }
    },
    stateText(data) {
      switch (data) {
        case 1: return '报价中';
        case 2: return '待开标';
        case 3: return '定价中';
        case 4: return '公示';
        case 5: return '已定价';
        default: return '';
      }
    },
    stateColor(data) {
      switch (data) {
        case "报价中": return '#0174da';
        case "待开标": return '#f16043';
        case "定价中": return '#d5880d';
        case "公示": return '#2ba743';
        case "已定价": return '#e01624';
        default: return '';
      }
    },
    jumpClick(item){
      if(this.type == "Material"){
        let toPath = null;
        if(item.status == "报价中" || item.status == "定价中"){
          toPath = `invite/quoteDetail?id=${item.cg_id}`
        }else{
          toPath = `invite/publicity?id=${item.cg_id}`
        }
        window.open(`${this.$domainSupplier}/#/login?token=${localStorage.getItem('token')}&toPath=${toPath}`, '_blank');
      }else{
        return
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.md-bid-list{
  margin-top: .36rem;
  .hd{
    justify-content: space-between;
    .msg{
      .time{
        margin-left: 0.2rem;
      }
      .more{
        margin-left: .26rem;
        text-decoration: underline;
        cursor: pointer;
      }
    }
    .swiper{
      height: 1.3em;
    }
  }
  .list{
    margin-top: .15rem;
    .li{
      padding: .38rem .34rem .3rem .26rem;
      margin-bottom: .2rem;
      &:last-of-type{
        margin-bottom: 0;
      }
    }
    .icon-label{
      width: 0.22rem; height: .24rem;
      background: url(~@/assets/images/icon-label.png) no-repeat center / contain;
      line-height: .22rem;
      text-align: center;
      margin-right: .07rem;
    }
    .tit{
      margin-right: .11rem;
    }
    .col-1{
      flex: 1;
    }
    .row-1{}
    .row-2{
      margin-top: .17rem;
    }
    .tag{
      margin-right: .1rem;
      border-radius: .04rem;
      padding: 0 .05rem;
    }
    .col-2{
      margin-right: .4rem;
      .split{
        width: 1px;
        height: .14rem; min-height: 1em;
        background-color: #cae1ec;
        margin: 0 .2rem;
      }
    }
    .link{
      width: 1.5rem;
    }
    .state{
      padding: 0 .07rem;
      margin-left: .12rem;
      color: $blue;
      border: 1px solid;
      border-radius: .04rem;
      line-height: .28rem;
    }
  }
}
</style>