<template>
  <div>
    <BaseDialog :visible.sync="visible" title='我的发票' :cancel='false' @close="$emit('close')" @confirm='handleSubmit'>
      <div class="wrap">
        <div class="list">
          <div class="li flex-middle" v-for="item in listArr" :key="item.invoice_id"
            :class="{ on: item.invoice_id == currentId }" @click="currentId = item.invoice_id">
            <div class="icon bg-contain"></div>
            <div class="text">
              <div class="name s18 color-2 font-medium">{{ item.name }}</div>
              <div class="title s14 color-6 font-light">发票抬头：{{ item.invoice_title }}</div>
              <div class="other s14 color-6 font-light flex-wrap">
                <div class="other-item">税号：{{ item.tax_number }}</div>
                <div class="other-item">手机号：{{ item.mobile }}</div>
                <div class="other-item">邮箱：{{ item.email }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex-right">
          <div class="btn-add flex-middle s16 color-blue pointer" @click="dialog_add = true">
            <img src="@/assets/images/icon-add-2.png" alt="">
            <span>新增</span>
          </div>
        </div>
      </div>
    </BaseDialog>
    <!-- 新增 -->
    <DialogInvoiceAdd v-if="dialog_add" @close="dialog_add = false" @change="handleNewInvoice" />
  </div>
</template>

<script>
import { InvoiceListApi } from "@/request/api/order";
export default {
  props: {
    value: '',
  },
  created() {
    this.currentId = this.value;
    this.listFn();
  },
  data() {
    return {
      getData: {
        p: 1,
        page_num: 100,
      },
      listArr: [],
      currentId: '',
      visible: true,
      dialog_add: false
    };
  },
  methods: {
    listFn() {
      InvoiceListApi(this.getData).then(({ status, data }) => {
        if (status == 200) {
          this.listArr = data.list;
        }
      })
    },
    handleSubmit() {
      let data = this.listArr.find(el => el.invoice_id == this.currentId)
      if (data) {
        this.$emit('change', data) // 添加成功，页面要重新加载地址列表
      }
      this.$emit('close');
    },
    handleNewInvoice() {
      this.listFn();
      this.dialog_add = false;
    },

  }
};
</script>

<style lang="scss" scoped>
.wrap {
  padding: .3rem;
  padding-bottom: .02rem;
}

.list {
  .li {
    border-radius: 0.04rem;
    border: solid 0.01rem #e6f2f8;
    margin-bottom: .1rem;
    padding: .26rem 0 .21rem;

    &:last-of-type {
      margin-bottom: 0;
    }

    &.on {
      .icon {
        background-image: url(~@/assets/images/icon-101.png);
      }
    }
  }

  .icon {
    width: .16rem;
    height: .16rem;
    background-image: url(~@/assets/images/icon-102.png);
    margin: 0 .23rem;
  }

  .text {
    flex: 1;
    line-height: calc(30 / 16 * 1em);
  }

  .name {
    line-height: 1.3;
  }

  .title {
    margin-top: .11rem;
  }

  .other {
    margin-top: .04rem;

    &-item {
      margin-right: .15rem;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }
}

.btn-add {
  margin-top: .17rem;

  img {
    width: .14rem;
    margin-right: .04rem;
  }
}
</style>