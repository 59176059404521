<template>
  <div class="md-prod-evaluate">
    <!-- 分类 -->
    <div class="cate flex-between flex-middle">
      <template v-for="item in cateArr">
        <div :key="item.id" class="cate-item pointer s16 color-4" :class="{ on: currentId == item.id }"
          @click="cateClick(item)">
          {{ item.title }}
        </div>
      </template>
    </div>
    <!-- 列表 -->
    <div class="list">
      <template v-for="(item, index) in commentObj.list">
        <div class="li flex-top" :key="index">
          <div class="buyer flex-middle">
            <img :src="item.head_pic" class="avatar">
            <div class="name s16 color-6">买家：{{ item.nickname | nameFormat }}</div>
          </div>
          <div class="rg">
            <div class="eva">
              <!-- 评分 -->
              <div class="count flex">
                <img src="@/assets/images/icon-star5.png" v-for="c in item.service_rank" :key="c">
              </div>
              <!-- 文字 -->
              <div class="cnt s16 color-4" v-html="item.content"></div>
              <!-- 配图 -->
              <div class="imgs flex-wrap" v-if="item.img.length > 0">
                <img class="img" v-for="(img, img_i) in item.img" :src="img" :key="img_i">
              </div>
              <div class="attrs s14 color-9 flex-middle">
                <div class="attr" v-for="attr in item.spec_key_name" :key="attr">{{ attr }}</div>
                <div class="date">{{ item.add_time | dateFormat('yyyy/MM/dd HH:mm') }}</div>
              </div>
            </div>
            <div class="reply s16 color-6" v-if="item.reply">
              <p>商家回复：</p>
              <div v-html="item.reply.content"></div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    commentObj: {
      type: Object,
      default: () => { }
    }
  },
  created() {
    this.cateArr[0].title = `全部评价(${this.commentObj.good_comment_count}+)`;
    this.cateArr[1].title = `晒图(${this.commentObj.good_comment_img}+)`;
    this.cateArr[2].title = `好评(${this.commentObj.good_comment_rate}+)`;
    this.cateArr[3].title = `中评(${this.commentObj.mid_comment_count}+)`;
    this.cateArr[4].title = `差评(${this.commentObj.bad_comment_count}+)`;
  },
  data() {
    return {
      currentId: 0,
      cateArr: [
        { id: 0, title: '全部评价(1000+)' },
        { id: 4, title: '晒图(130)' },
        { id: 1, title: '好评(300+)' },
        { id: 2, title: '中评(4)' },
        { id: 3, title: '差评(0)' }
      ],
    };
  },
  methods: {
    cateClick(item) {
      this.currentId = item.id;
      this.$emit('change', item.id);
    }
  }
};
</script>

<style lang="scss" scoped>
.md-prod-evaluate {
  word-break: break-all;
  padding: .3rem;

  .cate {
    height: .56rem;
    background-color: #ecf5fa;
    border-radius: 0.04rem;
    padding: 0 .3rem;

    &-item {
      &.on {
        color: $blue;
      }
    }
  }

  .list {
    margin-top: .09rem;

    .li {
      padding-top: .12rem;
      padding-bottom: .17rem;
      border-bottom: 1px solid #e6f2f8;
    }

    .buyer {
      width: 2.04rem;

      .avatar {
        width: 0.3rem;
        height: .3rem;
        border-radius: 50%;
        margin-right: .1rem;
      }
    }

    .rg {
      flex: 1;
      overflow: hidden;
      margin-top: .08rem;
    }

    .eva {
      .count {
        img {
          width: .18rem;
          margin-right: .08rem;
        }
      }

      .cnt {
        line-height: calc(30 / 16 * 1em);
        margin-top: .13rem;
      }

      .imgs {
        margin-top: .02rem;
        margin-bottom: .2rem;

        .img {
          width: .6rem;
          height: .6rem;
          object-fit: cover;
          margin-right: .1rem;
          margin-top: .1rem;
          border-radius: .04rem;
          border: solid 1px #e6f2f8;
        }
      }

      .attrs {
        margin-top: .12rem;

        .attr {
          margin-right: 2em;
        }
      }
    }

    .reply {
      line-height: calc(30 / 16 * 1em);
      background: #ecf5fa;
      padding: .22rem .3rem;
      position: relative;
      border-radius: .04rem;
      margin-top: .17rem;

      &:before {
        content: '';
        position: absolute;
        left: .12rem;
        top: -.2rem;
        border: .1rem solid transparent;
        border-bottom-color: #ecf5fa;
      }
    }
  }
}
</style>