<template>
  <router-link class="store-msg flex-middle" :to="`/shop/seller?suppliers_id=${suppliers_id}`">
    <BaseButton :hover='false' style-type='light' size='mini'>{{ shop_type }}</BaseButton>
    <div class="name s16 color-2 font-medium">{{ shop_name }}</div>
  </router-link>
</template>

<script>
export default {
  props: {
    shop_name: { type: String, default: "" },
    shop_type: { type: String, default: "" },
    suppliers_id: { type: Number, default: 0 },

  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.store-msg {
  .base-button {
    margin-right: .17rem;
    padding: 0 .12rem;
  }

  &:hover {
    .name {
      color: $blue;
    }
  }
}
</style>