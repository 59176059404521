<template>
  <div class="md-product-selected">
    <div class="c-title-2 s22" v-ani.fade-up>精选商品</div>
    <div class="list">
      <template v-for="item in listArr">
        <ProductItemHot :value="item" :key="item.id" v-ani.fade-up />
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    listArr: { type: Array, default: () => [] }
  },
  data() {
    return {
      
    };
  },
};
</script>

<style lang="scss" scoped>
.md-product-selected {
  margin-top: .37rem;

  .list {
    margin-top: .17rem;
    width: 2.5rem;
  }

  .c-product-item {
    margin-bottom: .2rem;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}</style>