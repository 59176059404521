<template>
  <!-- 成功信息提示，有图标、标题、内容 -->
  <BaseDialog
      :visible.sync='visible' 
      title="信息提示" 
      :cancel='false'
      class="dialog"
      @confirm='handleConfirm'
    >
      <div class="dialog-text text-center">
        <img src="@/assets/images/icon-success.png" alt="" class="icon">
        <div class="t1 s26 color-2 font-medium">{{title}}</div>
        <div class="t2 s16 color-4" v-html="text"></div>
      </div>
    </BaseDialog>
</template>

<script>
export default {
  props: {
    title: '',
    text: ''
  },
  data() {
    return {
      visible: true,
    };
  },
  methods: {
    handleConfirm() {
      this.$emit('confirm')
    }
  }
};
</script>

<style lang="scss" scoped>
.dialog-text{
  margin: 0.38rem 0 .14rem;
  .icon{
    width: .4rem;
  }
  .t1{
    margin-top: .09rem;
  }
  .t2{
    line-height: calc(42 / 26 * 1em);
    margin-top: .06rem;
  }
}
.dialog{
  ::v-deep .c-dialog_footer .btn-confirm{
    min-width: 1rem;
  }
}
</style>