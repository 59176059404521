<template>
  <div class="home-user c-card">
    <template v-if="isLogin">
      <div class="row-1">
        <img :src="userInfo.head_pic" alt="" class="avatar">
        <div class="rg">
          <div class="name-wrap flex-middle">
            <!-- 姓名 -->
            <div class="name s22 color-2 ep1">{{ userInfo.nickname }}</div>
            <!-- 退出登录 -->
            <img src="@/assets/images/icon-quit.png" alt="" class="quit" @click="toLogin">
          </div>
          <div class="level-wrap">
            <UserRank :hatArr="hatArr" :starsArr="starsArr" @click.native="toRank()" style="margin-right: 7px"></UserRank>
            <div class="icons">
              <div class="icon" v-if="userInfo.role_icon_status.type2 == 1">
                <img src="https://yizhu-new.oss-cn-shenzhen.aliyuncs.com/app_img/aIcon/icon220.png" />
              </div>
              <div class="icon" v-if="userInfo.role_icon_status.type3 == 1">
                <img src="https://yizhu-new.oss-cn-shenzhen.aliyuncs.com/app_img/aIcon/icon221.png" />
              </div>
              <div class="icon" v-if="userInfo.role_icon_status.type1 == 1">
                <img src="https://yizhu-new.oss-cn-shenzhen.aliyuncs.com/app_img/cImg/badge3.png" />
              </div>
              <div class="icon" v-if="userInfo.role_icon_status.type4 == 1">
                <img src="https://yizhu-new.oss-cn-shenzhen.aliyuncs.com/app_img/cImg/badge2.png" />
              </div>
              <div class="icon" v-if="userInfo.role_icon_status.type5 == 1">
                <img src="https://yizhu-new.oss-cn-shenzhen.aliyuncs.com/app_img/cImg/badge4.png" />
              </div>
              <div class="icon" v-if="userInfo.role_icon_status.type6 == 1">
                <img src="https://yizhu-new.oss-cn-shenzhen.aliyuncs.com/app_img/cImg/badge1.png" />
              </div>
              <div class="icon" v-if="userInfo.role_icon_status.type7 == 1">
                <img src="https://yizhu-new.oss-cn-shenzhen.aliyuncs.com/app_img/cImg/badge5.png" />
              </div>
              <div class="icon" v-if="userInfo.role_icon_status.type8 == 1">
                <img src="https://yizhu-new.oss-cn-shenzhen.aliyuncs.com/app_img/cImg/badge6.png" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row-2">
        <div class="jifen color-9 s14">
          <span class="t1">易筑分:</span>
          <span class="t2 color-blue s16 font-bold">{{ userInfo.level ? userInfo.level.score : "" }}</span>
          <span class="t3">{{ userInfo.level ? userInfo.level.account_period : "" }}</span>
        </div>
        <!-- 菜单 -->
        <div class="menu s16 color-4">
          <template v-for="item in list">
            <router-link
              class="menu-item"
              :key="item.id"
              :to="item.link"
              :target="item.target"
              @click.native="toOrder(item)"
            >
              <div class="icon" :class="item.icon" v-if="item.icon"></div>
              <div class="tit">{{ item.title }}</div>
              <span class="num s12" v-if="item.num > 0">{{ item.num | numMax }}</span>
            </router-link>
          </template>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="row-3 font-medium text-center">  
        <img src="@/assets/images/avatar2.png" alt="" class="avatar">
        <div class="t1 s22 color-2">当前未登录</div>
        <div class="t2 s16 color-4">
          登录注册享身份专有特权~ <br>
          快加入我们吧！
        </div>
        <router-link to="/login" class="s16 flex-center btn hn-btn-blue">
          <span>登录</span>
        </router-link>
        <router-link to="/register" class="s16 flex-center btn hn-btn-green">
          <span>注册</span>
        </router-link>
        <!-- <router-link to="" class="s16 flex-center btn hn-btn-green-light btn">
          <span class="icon"></span>
          <span>微信登录</span>
        </router-link> -->
      </div>
    </template>
    <BaseDialog :visible.sync="logOutvisible" title='退出登录' :cancel='true' @close="$emit('close')" @confirm='toLogOut'>
      <div class="s26 color-2 font-medium text-center dialog-text" style="margin-top: 20px">
        是否确定要退出登录？
      </div>
    </BaseDialog>
  </div>
</template>

<script>
import { userInfoApi } from "@/request/api/user.js";
import { LogoutApi } from "@/request/api/login.js";
export default {
  data() {
    return {
      list: [
        { id: 1, target: '', link: '/order', title: '全部订单', num: 0 },
        { id: 2, target: '', link: '/order?tab=3&status=1', title: '待付款', num: 0 },
        { id: 3, target: '', link: '/order?tab=5&status=3', title: '待收货', num: 0 },
        { id: 4, target: '', link: '/order?tab=6&status=4', title: '已完成', num: 0 },
        { id: 5, target: '', link: '/order?tab=1&status=2', title: '待审核', num: 0 },
        { id: 6, target: '', link: '/order?tab=8&status=5', title: '退款/售后', num: 0 },
        { id: 7, target: '', link: '/shop-cart', title: '购物车', num: 0, icon: 'icon-cart' },
        { id: 8, target: '', link: '/collection', title: '我的收藏', num: 0, icon: 'icon-collect' },
        { id: 9, target: '', link: '', title: '历史浏览', num: 0, icon: 'icon-history' },
      ],

      isLogin: false,
      userInfo: {},
      hatArr: [],
      starsArr: [],
      logOutvisible: false
    }
  },
  mounted(){
    if(localStorage.getItem("token")){
      this.getUserInfo();
    }
    else{
      return;
    }
  },
  methods: {
    toLogin(){
      if(localStorage.getItem("token")){
        this.logOutvisible = true;
      }else{
        this.isLogin = false;
      }
      // this.$router.push('/login');
    },
    toRank() {
      this.$router.push('/member-intro')
    },
    async getUserInfo() {
      const res = await userInfoApi();
      if (res.status == 200) {
        this.isLogin = true;
        this.userInfo = res.data;
        localStorage.setItem("user_id",res.data.user_id);
        
        this.hatArr = res.data.level.hat;
        this.starsArr = res.data.level.stars;

        this.list = this.list.map(item => {
          if(item.id == 2){
            item.num = this.userInfo.statistics.order_need_pay;
          }
          else if(item.id == 3){
            item.num = this.userInfo.statistics.order_need_received;
          }
          else if(item.id == 4){
            item.num = this.userInfo.statistics.order_need_send;
          }
          else if(item.id == 5){
            item.num = this.userInfo.statistics.order_need_check;
          }
          else if(item.id == 6){
            item.num = this.userInfo.statistics.order_after_sales;
          }
          else{
            return item;
          }
          return item;
        })

      } else {
        this.isLogin = false
      }
    },
    toLogOut(){
      LogoutApi().then(res => {
        if(res.status == 200){
          this.$message.success("退出登录成功");
          localStorage.removeItem("token");
          setTimeout(() => {
            this.logOutvisible = false;
            this.$router.push("/login")
          },2000)
        }
      })
    },
    toOrder(item){
      if(!item.link){
        this.$message.warning("暂未开放")
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.home-user{
  padding: .3rem;
  .row-1{
    display: flex;
    padding-bottom: .19rem;
    .avatar{
      width: .66rem; height: 0.66rem;
      border-radius: 50%;
      margin-right: .12rem;
    }
    .rg{
      flex: 1;
      margin-top: .06rem;
    }
    .name-wrap{
      .name{
        flex: 1;
      }
      .quit{
        width: 0.14rem;
        cursor: pointer;
      }
    }
    .level-wrap{
      margin-top: .08rem;
      .icons{
        display: flex;
        flex-wrap: wrap;
        .icon{
          margin-left: 5px;
          margin-top: 5px;
          width: 62px;
          height: 26px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
     
      .type{
        line-height: .24rem;
        border-radius: .24rem;
        background-color: #ecf5fa;
        margin-left: .16rem;
        padding-left: .19rem; padding-right: .09rem;
        position: relative;
        color: #4b608a;
        display: flex;
        img{
          position: absolute; left: -.08rem; top: 50%;
          height: .24rem;
          transform: translateY(-50%);
        }
      }
    }
  }
  .row-2{
    border-top: 1px solid #e6f2f8;
    padding-top: .18rem;
    .jifen{
      text-align: center;
      .t2{
        margin-right: .19rem;
      }
    }
    .menu{
      display: flex; flex-wrap: wrap;
      margin-top: .03rem;
      &-item{
        position: relative;
        border-radius: .04rem;
        border: solid 1px #e6f2f8;
        transition: .3s;
        text-align: center;
        margin-right: .12rem; margin-top: .12rem;
        width: calc(81 / 268 * 100%);
        padding: .07rem 0;
        line-height: 1.5;
        &:nth-of-type(3n){
          margin-right: 0;
        }
        .icon{
          height: .22rem;
          margin-top: .07rem;
          margin-bottom: .04rem;
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;
          &-cart{
            background-image: url(~@/assets/images/icon-cart2.png);
          }
          &-collect{
            background-image: url(~@/assets/images/icon-star2.png);
          }
          &-history{
            background-image: url(~@/assets/images/icon-history.png);
          }
          &+.tit{
            margin-bottom: .02rem;
          }
        }
        .num{
          position: absolute; right: -.09rem; top: -.08rem;
          min-width: .2rem; height: .2rem; line-height: .2rem;
          border-radius: .2rem;
          background-color: #f53e39; color: #fff;
        }
        &:hover{
          background-color: $green;
          border-color: $green;
          color: #fff;
          .icon{
            &-cart{
              background-image: url(~@/assets/images/icon-cart3.png);
            }
            &-collect{
              background-image: url(~@/assets/images/icon-star3.png);
            }
            &-history{
              background-image: url(~@/assets/images/icon-history2.png);
            }
          }
        }
      }
    }
  }
  .row-3{
    .avatar{
      width: .66rem;
      display: block;
      margin: auto;
    }
    .t1{
      margin-top: .07rem; margin-bottom: 0.05rem;
    }
    .t2{
      line-height: calc(30 / 16 * 1em);
      margin-bottom: 0.12rem;
    }
    .btn{
      width: 1.6rem;
      line-height: calc(40 / 16 * 1em);
      margin: .1rem auto 0;
      border-radius: .04rem;
      .icon{
        width: .23rem; height: .19rem;
        margin-right: .05rem;
        background: url(~@/assets/images/icon-wechat.png) no-repeat center / contain;
      }
      &:hover{
        .icon{
          background-image: url(~@/assets/images/icon-wechat2.png);
        }
      }
    }
  }
}
</style>