<template>
  <!-- 删除订单 -->
  <BaseDialog
    width='4rem'
    :visible.sync='visible'
    :close='false'
    @cancel="handleCancel"
    @confirm='handleConfirm'
  >
    <div class="dialog-delete s22 color-2 font-medium">是否删除该订单，此操作不可逆</div>
  </BaseDialog>
</template>

<script>
export default {
  data() {
    return {
      visible: true,
    };
  },
  methods: {
    handleCancel() {
      this.$emit('cancel')
    },
    handleConfirm() {
      this.$emit('confirm')
    }
  }
};
</script>

<style lang="scss" scoped>
.dialog-delete{
  padding: .5rem .3rem .2rem;
}
</style>