<template>
  <div class="md-hotnews c-card">
    <div class="title s22 font-medium">大家都在看</div>
    <div class="list">
      <swiper class="swiper" :options="swiperOption">
        <template v-for="(item, index) in listArr">
          <swiper-slide class="slide" :key="item.article_id">
            <div class="link flex-middle" @click="listClick(item)">
              <div class="num s12 color-f">{{ (index + 1) | numPad }}</div>
              <div class="tit s16 color-4 ep2">{{ item.title }}</div>
            </div>
          </swiper-slide>
        </template>
      </swiper>
    </div>

  </div>
</template>

<script>
export default {
  props: {
    listArr: { type: Array, default: () => [] }
  },
  data() {
    return {
      swiperOption: {
        direction: 'vertical',
        slidesPerView: 5,
        mousewheelControl: true,
        observer: true,
        observeParents: true,
      }
    };
  },
  methods: {
    listClick(item) {
      let routeData = this.$router.resolve({
        path: '/article',
        query: { id: item.article_id }
      });
      window.open(routeData.href, '_blank');
    }
  }
};
</script>

<style lang="scss" scoped>
.md-hotnews {
  padding: .26rem .3rem;

  .list {
    margin-top: .17rem;
  }

  .swiper {
    height: 4rem;
  }

  .slide {
    position: relative;

    &:nth-child(-n+3) {
      .num {
        background-color: $green;
      }
    }

    &:after {
      content: '';
      position: absolute;
      left: .1rem;
      top: .45rem;
      width: .02rem;
      height: .47rem;
      background: #e6f2f8;
    }

    &:last-child {
      &:after {
        display: none;
      }
    }
  }

  .link {
    &:hover {
      .tit {
        color: $green;
      }
    }
  }

  .num {
    min-width: 0.2rem;
    line-height: .2rem;
    border-radius: 50%;
    text-align: center;
    background: #bbb;
    margin-right: .1rem;
    padding: 0 .02rem;
  }

  .tit {
    flex: 1;
    line-height: calc(28 / 16 * 1em);
    transition: .2s;
  }
}
</style>