<template>
  <!-- 圆点布尔切换 -->
  <label
    class="base-switch"
    :class="{ on: value }"
    @click="$emit('input', !value)"
  >
    <div class="dot"></div>
  </label>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean | Number,
      default: false
    }
  },
  data() {
    return {};
  },
  methods: {
  }
};
</script>

<style lang="scss" scoped>
.base-switch{
  cursor: pointer;
  width: .3rem; height: .16rem;
  border-radius: .16rem;
  padding: .02rem;
  position: relative;
  background: #999;
  display: inline-flex; align-items: center;
  transition: background .2s;
  .dot{
    width: .12rem; height: .12rem;
    border-radius: 50%;
    background: #fff;
    transition: .2s;
  }
  &.on{
    background: $blue;
    .dot{
      margin-left: .14rem;
    }
  }
}
</style>