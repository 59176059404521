import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Components from './components'
import Filters from './filter'
import Directive from './directive'
import axios from './http/axios.js'
import DataV from '@jiaminghi/data-view'
import scroll from "vue-seamless-scroll";
import {domainSupplier} from "@/config/index"

// 图片预览
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'



// 轮播组件
// https://www.npmjs.com/package/vue-awesome-swiper
import 'swiper/dist/css/swiper.css'
import VueAwesomeSwiper from 'vue-awesome-swiper'

// 全局样式
import './style/main.scss'
import './style/ani.scss'

// 图片预览
Vue.use(Viewer)
Viewer.setDefaults({
  Options: { 'inline': true, 'button': true, 'navbar': true, 'title': true, 'toolbar': true, 'tooltip': true, 'movable': true, 'zoomable': true, 'rotatable': true, 'scalable': true, 'transition': true, 'fullscreen': true, 'keyboard': true, 'url': 'data-source' }
})


// element-ui
import ElementUI from 'element-ui';
import './style/element-variables.scss';
Vue.use(ElementUI);

Vue.use(DataV);
Vue.use(scroll);
Vue.use(scroll, { componentName: "scroll-seamless" });

// 全局组件注册
Vue.use(Components);
Vue.use(VueAwesomeSwiper)
Vue.use(Filters)
Vue.use(Directive)
Vue.prototype.$axios = axios
Vue.prototype.$domainSupplier = domainSupplier
// 弹窗层级叠加
let dialogIndex = 1000
Vue.prototype.$newDialogIndex = function () {
  return ++dialogIndex
}
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
