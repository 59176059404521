<template>
  <div class="c-pic">
    <!-- 按比例 -->
    <div
      v-if="ratio"
      class="img"
      :style="style"
      :class="{
        'bg-cover': fit == 'cover',
        'bg-contain': fit == 'contain'
      }"
    >
      <img :src="src" alt="">
    </div>
    <!-- 图片自适应 -->
    <img :src="src" alt="" v-else>
  </div>
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      default: ''
    },
    width: {
      type: Number,
      default: 0,
    },
    height: {
      type: Number,
      default: 0,
    },
    fit: {
      type: String,
      default: 'cover'
    }
  },
  data() {
    return {};
  },
  computed: {
    ratio() { // 按比例显示
      return this.width && this.height
    },
    style() {
      let res = {
        'background-image': 'url(' + this.src + ')',
        'padding-top': this.height / this.width * 100 + '%',
      }
      return res
    }
  }
};
</script>

<style lang="scss" scoped>
.c-pic{
  &:hover{
    .img{
      transform: scale(1.1);
    }
  }
}
</style>