<template>
  <div class="md-kefu-2 c-card">
    <div class="chat">
      <div class="record" v-loading='loading'>
        <!-- 下一页 -->
        <div class="more-bar flex-center">
          <div class="more s16 color-6 flex-center" @click="getMore">
            <span>查看历史聊天记录</span>
            <img src="@/assets/images/icon-up.png" alt="">
          </div>
        </div>
        <!-- 记录列表 -->
        <div class="c-scroll record-cnt" ref="list">
          <template v-for="(item, index) in list">
            <div class="msg flex-top" :key="index"
              :class="{
                'msg-left': item.uid != uid,
                'msg-right': item.uid == uid
              }"
            >
              <img :src="item.avatar" alt="" class="avatar">
              <div class="msg-rg">
                <div class="msg-text s16 color-4">
                  <!-- 产品 -->
                  <div class="prod" v-if="item.prod">
                    <router-link class="prod-hd flex-middle" to="">
                      <img :src="item.prod.shopAvatar" alt="" class="ava">
                      <div class="tit s18 color-2 font-medium">{{item.prod.shopName}}</div>
                    </router-link>
                    <router-link class="prod-bd flex-middle" to="">
                      <img :src="item.prod.prodImg" alt="" class="cover">
                      <div class="s16 color-4">
                        <p class="t1">{{item.prod.prodName}}</p>
                        <p class="t2">{{item.prod.prodPrice}}</p>
                      </div>
                    </router-link>
                  </div>
                  <!-- 普通文本 -->
                  <p v-else>{{ item.content }}</p>
                  <img :src="item.img" alt="" class="img" v-if="item.img">
                </div>
                <div class="date s14 color-9">{{ item.date | dateFormat('yyyy/MM/dd HH:mm') }}</div>
              </div>
            </div>
          </template>
        </div>
        <div class="bar">
          <BaseButton class="btn-1" size='mini' style-type="light">
            <span>上传文件</span>
            <input type="file" @change="getFile">
          </BaseButton>
          <BaseButton class="btn-2" size='mini' style-type="border" @click.native="send('快件到哪了')">快件到哪了</BaseButton>
          <BaseButton class="btn-3" size='mini' color='red' style-type='light'>结束会话</BaseButton>
        </div>
      </div>
      <form class="form" @submit.prevent="send()">
        <textarea class="area s16 color-3" placeholder="请输入..."
          v-model="text" 
          @keyup.enter.ctrl="send()"
        ></textarea>
        <BaseButton class="btn" @click.native="send()">发送</BaseButton>
      </form>
    </div>
    <div class="rg">
      <div class="question">
        <div class="c-title-2 s22">猜你想问</div>
        <div class="list s16 color-6">
          <template v-for="(item, index) in question">
            <div class="li"
              :key="item"
              v-if="index < 7"
              @click="send(item)"
            >
              {{item}}
            </div>
          </template>
        </div>
      </div>
      <div class="menu">
        <div class="c-title-2 s22">快捷功能</div>
        <div class="list s16 color-2 font-medium">
          <router-link class="li flex-center" to="">
            <img src="@/assets/images/icon-96.png" alt="">
            <span>申请开票</span>
          </router-link>
          <router-link class="li flex-center" to="">
            <img src="@/assets/images/icon-97.png" alt="">
            <span>平台合作</span>
          </router-link>
          <router-link class="li flex-center" to="/feedback">
            <img src="@/assets/images/icon-98.png" alt="">
            <span>投诉建议</span>
          </router-link>
          <router-link class="li flex-center" to="">
            <img src="@/assets/images/code5.png" alt="" class="code">
            <span>app下载</span>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      question: [
        '联系电话',
        '客服电话',
        '优惠券无法使用?',
        '取消订单能只退一部分商品吗?电子发票什么时候开具?',
        '我的订单没有确定收货，订单怎么就完成了?',
        '如何查看我给他人的评价?发货运费谁承担?我这个订单',
        '退款什么时候能到账?我这个订单什么时候通过退款',
      ],
      text: '',
      list: [
        {
          uid: 1,
          avatar: require('@/assets/images/avatar.png'),
          content: 'Hi，中午好~客服很高兴为您服务，任何问题都可以问我哟~',
          date: '2023/01/01 11:00',
        }, {
          uid: 2,
          avatar: require('@/assets/images/avatar6.png'),
          content: '咨询送货单，单号：52135213513651',
          date: '2023/01/01 11:00',
        }, {
          uid: 2,
          avatar: require('@/assets/images/avatar6.png'),
          content: '',
          prod: {
            shopName: '李四材料供应商',
            shopAvatar: require('@/assets/images/avatar.png'),
            prodImg: require('@/assets/images/prod-1.jpg'),
            prodName: '泥建筑混合砂浆楼邦墙砖砂浆',
            prodPrice: '¥50~100'
          },
          date: '2023/01/01 11:00',
        }, {
          uid: 1,
          avatar: require('@/assets/images/avatar.png'),
          content: 'Hi，中午好~客服很高兴为您服务，任何问题都可以问我哟~',
          date: '2023/01/01 11:00',
        }, {
          uid: 2,
          avatar: require('@/assets/images/avatar6.png'),
          content: '咨询送货单，单号：52135213513651',
          date: '2023/01/01 11:00',
        }, {
          uid: 1,
          avatar: require('@/assets/images/avatar.png'),
          content: 'Hi，中午好~客服很高兴为您服务，任何问题都可以问我哟~',
          date: '2023/01/01 11:00',
        }, {
          uid: 2,
          avatar: require('@/assets/images/avatar6.png'),
          content: '咨询送货单，单号：52135213513651',
          date: '2023/01/01 11:00',
        }, {
          uid: 1,
          avatar: require('@/assets/images/avatar.png'),
          content: 'Hi，中午好~客服很高兴为您服务，任何问题都可以问我哟~',
          date: '2023/01/01 11:00',
        }, {
          uid: 2,
          avatar: require('@/assets/images/avatar6.png'),
          content: '咨询送货单，单号：52135213513651',
          date: '2023/01/01 11:00',
        }
      ],
      uid: 2,
      avatar: require('@/assets/images/avatar6.png'),
      loading: false,
    };
  },
  methods: {
    send(text) {
      if(this.loading) { return }
      this.loading = true;
      setTimeout(() => {
        this.list.unshift({
          uid: this.uid,
          avatar: this.avatar,
          content: text || this.text,
          date: Date().now
        })
        this.scrollToNew()
        this.text = ''
        this.loading = false
      }, 500);
    },
    getMore() {
      this.$refs.list.scrollTop = -this.$refs.list.scrollHeight
    },
    scrollToNew() {
      this.$refs.list.scrollTop = 0
    },
    getFile(e) {
      if(this.loading) { return }
      this.loading = true;
      const el = e.target.files[0];
      let obj = {
        uid: this.uid,
        avatar: this.avatar,
        date: Date.now(),
        content: '',
        img: ''
      }
      let reader = new FileReader()
      reader.readAsDataURL(el)
      reader.addEventListener("load", () => {
        obj.img = reader.result
        this.list.unshift(obj)
        e.target.value = ''
        this.scrollToNew()
        setTimeout(() => {
          this.loading = false
        }, 500);
      }, false)
    }
  }
};
</script>

<style lang="scss" scoped>
.md-kefu-2{
  display: flex;
  .chat{
    width: 10.8rem;
    .record{
      height: 5.45rem;
      overflow: auto;
      position: relative;
      .more{
        cursor: pointer;
        &-bar{
          position: absolute; left: 0; top: 0; right: 4px;
          z-index: 2;
          margin: auto;
          height: .7rem;
          background: #fff;
        }
        img{
          margin-left: .1rem;
        }
        &:hover{
          color: $blue;
        }
      }
      .bar{
        position: absolute; bottom: 0; left: 0; right: 4px;
        margin: auto;
        display: flex;
        padding: .2rem .4rem;
        background: #fff;
        .btn-1{
          position: relative;
          margin-right: .1rem;
          input{
            position: absolute; left: 0; top: 0;
            width: 100%; height: 100%;
            opacity: 0;
          }
        }
        .btn-3{
          margin-left: auto;
        }
      }
      &-cnt{
        height: 5.45rem; overflow: auto;
        padding: .4rem;
        padding-bottom: .7rem; padding-top: .73rem;
        display: flex; flex-direction: column-reverse;
      }
      .msg{
        margin-bottom: .15rem;
        .avatar{
          width: 0.6rem; height: .6rem;
          border-radius: 50%;
        }
        .msg-text{
          padding: .177rem; padding-right: .2rem;
          border-radius: .04rem;
          position: relative;
          max-width: 5.8rem;
          &::before{
            content: '';
            position: absolute; top: .18rem;
            border: .07rem solid transparent;
          }
          img{
            max-width: 100%;
          }
        }
        .date{
          margin-top: .12rem;
        }
        &-left{
          .msg-rg{
            margin-left: .2rem;
          }
          .msg-text{
            background-color: #ecf5fa;
            &::before{
              left: -.14rem;
              border-right-color: #ecf5fa;
            }
          }
        }
        &-right{
          .msg-rg{
            margin-left: auto;
            margin-right: .2rem;
            order: 1;
          }
          .avatar{
            order: 2;
          }
          .date{
            text-align: right;
          }
          .msg-text{
            background: #edf9f0;
            &::before{
              right: -.14rem;
              border-left-color: #edf9f0;
            }
          }
        }
        .prod{
          width: 4.52rem;
          &-hd{
            padding-bottom: .13rem;
            .ava{
              width: 0.3rem; height: .3rem;
              border-radius: 50%;
              margin-right: .09rem;
            }
          }
          &-bd{
            border-top: 1px solid #d3eeda;
            padding-top: .18rem;
            .cover{
              width: .5rem; height: .5rem;
              object-fit: cover;
              border-radius: .02rem;
              margin-right: .12rem;
            }
            .t2{
              margin-top: .1rem;
            }
          }
        }
      }
    }
    .form{
      display: block;
      border-top: 1px solid #e4f1f7;
      position: relative;
      .area{
        display: block;
        width: 100%; height: 1.58rem;
        border: none;
        padding: .14rem .4rem;
        &::placeholder{
          color: #999;
        }
      }
      .btn{
        width: 1rem;
        position: absolute; right: 0.3rem; bottom: .4rem;
      }
    }
  }
  .rg{
    flex: 1;
    border-left: 1px solid #e4f1f7;
  }
  .question{
    padding: .36rem .4rem .35rem;
    height: 3.5rem;
    .list{
      margin-top: .16rem;
      .li{
        margin-bottom: .15rem;
        cursor: pointer;
        &:last-of-type{
          margin-bottom: 0;
        }
        &:hover{
          color: $blue;
        }
      }
    }
  }
  .menu{
    padding: .36rem .4rem .3rem;      
    border-top: 1px solid #e4f1f7;
    height: 3.5rem;
    .list{
      display: flex; flex-wrap: wrap; justify-content: space-between;
      margin-top: .15rem;
      .li{
        width: 2.15rem;
        height: 1.11rem;
        margin-bottom: .1rem;
        &:nth-of-type(4n+1){
          background: $blueLight;
        }
        &:nth-of-type(4n+2){
          background: $greenLight;
        }
        &:nth-of-type(4n+3){
          background: $redLight;
        }
        &:nth-of-type(4n+4){
          background: $brownLight;
        }
      }
      img{
        width: .6rem;
        margin-right: .12rem;
      }
      .code{
        width: 0.9rem;
      }
    }
  }
}
</style>