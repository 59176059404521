<template>
  <div class="md-prod-data c-card">
    <div class="row row-1">
      <div class="title s22 color-2 font-medium">店铺口碑</div>
      <div class="cnt">
        <span class="t1 s26 color-blue font-bold">{{ shop_mouth.omprehensive_score }}</span>
        <span class="t2 s14 color-6 font-light">{{ shop_mouth.omprehensive_score_text }}</span>
      </div>
    </div>
    <div class="row row-2">
      <div class="title s22 color-2 font-medium">商品体验</div>
      <div class="cnt flex-middle">
        <div class="item color-brown flex-middle">
          <span class="t1 s20">{{ goods_experience.differ }}</span>
          <span class="t2 s14 bg-brown-light">差</span>
        </div>
        <div class="item color-green flex-middle">
          <span class="t1 s20">{{ goods_experience.centre }}</span>
          <span class="t2 s14 bg-green-light">中</span>
        </div>
        <div class="item color-blue flex-middle">
          <span class="t1 s20">{{ goods_experience.good }}</span>
          <span class="t2 s14 bg-blue-light">优</span>
        </div>
      </div>
    </div>
    <div class="row row-3">
      <div class="title s22 color-2 font-medium">经营表现</div>
      <div class="cnt s16 color-4 flex-top">
        <div class="item">
          <div class="t1 s20 color-blue">¥{{ business_performance.protection_money }}</div>
          <div class="t2">保证金</div>
        </div>
        <div class="item">
          <div class="t1 s20 color-blue">{{ business_performance.execution_rate }}%</div>
          <div class="t2">准时履约率</div>
        </div>
        <div class="item">
          <div class="t1 s20 color-blue">{{ business_performance.favorable_comment }}%</div>
          <div class="t2">好评率</div>
        </div>
        <div class="item">
          <div class="t1 s20 color-blue">{{ business_performance.back_rate }}%</div>
          <div class="t2">回头率</div>
        </div>
      </div>
    </div>
    <div class="row row-4">
      <div class="title s22 color-2 font-medium">买家评论关键词</div>
      <div class="cnt s14 color-blue font-light flex-top">
        <div class="item" v-for="(item,index) in buyer_key" :key="index">{{ item }}</div>
      </div>
    </div>
    <div class="row row-5">
      <div class="list text-center">
        <div class="li bg-contain">
          <img src="@/assets/images/icon-109.png" class="icon">
          <div class="tit s14">品牌授权</div>
        </div>
        <div class="li bg-contain">
          <img src="@/assets/images/icon-110.png" class="icon">
          <div class="tit s14">保证正品</div>
        </div>
        <div class="li bg-contain">
          <img src="@/assets/images/icon-111.png" class="icon">
          <div class="tit s14">售后无忧</div>
        </div>
      </div>
      <div class="tips flex-center s14 color-9 font-light">
        <img src="@/assets/images/icon-112.png" alt="">
        <p>数据由易筑平台提供，确保真实可信度</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    shop_mouth: { type: Object, default: () => { } },
    goods_experience: { type: Object, default: () => { } },
    business_performance: { type: Object, default: () => { } },
    buyer_key: { type: Array, default: () => [] },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.md-prod-data {
  padding: .3rem;

  .row {
    border-bottom: 1px solid #e6f2f8;

    &:last-of-type {
      border-bottom: none;
    }
  }

  .row-1 {
    padding: .06rem 0 .19rem;

    .cnt {
      margin-top: .1rem;

      .t2 {
        margin-left: .08rem;
      }
    }
  }

  .row-2 {
    padding: .19rem 0 .2rem;

    .cnt {
      margin-top: .12rem;

      .item {
        margin-right: 0.28rem;

        &:last-of-type {
          margin-right: 0;
        }


        .t2 {
          line-height: 0.2rem;
          border-radius: .04rem;
          padding: 0 .03rem;
          margin-left: .06rem;
        }
      }
    }
  }

  .row-3 {
    padding: .22rem 0 .19rem;

    .cnt {
      margin-top: .12rem;
      justify-content: space-between;


      .t2 {
        margin-top: .04rem;
      }
    }
  }

  .row-4 {
    padding: .22rem 0 .15rem;

    .cnt {
      margin-top: .15rem;
      flex-wrap: wrap;

      .item {
        border-radius: .04rem;
        border: 1px solid;
        padding: 0 .09rem;
        margin-right: .1rem;
        margin-bottom: .1rem;
        line-height: .28rem;
      }
    }
  }

  .row-5 {
    padding-top: .33rem;

    .list {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;

      .li {
        width: .97rem;
        height: .97rem;
        background: url(~@/assets/images/icon-108.png) no-repeat center / contain;
        padding-top: .22rem;
        margin: 0 .15rem .1rem;
      }

      .icon {
        height: .2rem;
      }

      .tit {
        color: #98c2d6;
        margin-top: .04rem;
      }
    }

    .tips {
      margin-top: .2rem;

      img {
        margin-right: .05rem;
      }
    }
  }
}
</style>