<template>
  <div class="c-card c-prod-params">
    <div v-if="type=='bid'">
      <ParamsItem :typeNum="1" type="bid" :collapse="collapse" :listArr="baseObj.region" @change="handleChoose"
      title="地区" />
      <ParamsItem :typeNum="2" type="bid" :collapse="collapse" :listArr="baseObj.state" @change="handleChoose" title="状态" />
    </div>
    <div v-if="type=='Bid'">
      <ParamsItem :typeNum="1" type="Bid" :collapse="collapse" :listArr="baseObj.engineer_cat" @change="handleChoose"
      title="工程招标" />
      <ParamsItem :typeNum="2" type="Bid" :collapse="collapse" :listArr="baseObj.material_cat" @change="handleChoose" title="材料招标" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: ""
    },
    collapse: {
      type: Boolean,
      default: true
    },
    baseObj: {
      type: Object,
      default: () => { }
    }
  },
  data() {
    return {
      // mockList: [
      //   {
      //     id: 1, title: '材质标准', name: 'standard', value: '',
      //     children: [
      //       { id: '', title: '不限' },
      //       { id: 11, title: '非国标' },
      //       { id: 12, title: '国家标准' },
      //     ]
      //   },
      //   {
      //     id: 2, title: '材质', name: 'material', value: '',
      //     children: [
      //       { id: '', title: '不限' },
      //       { id: 21, title: '不锈钢' },
      //       { id: 22, title: '塑胶' },
      //       { id: 23, title: '铝合金' },
      //     ]
      //   },
      //   {
      //     id: 3, title: '起批量', name: 'count', value: '',
      //     children: [
      //       { id: '', title: '不限' },
      //       { id: 31, title: '100以下' },
      //       { id: 32, title: '200以下' },
      //       { id: 33, title: '300以下' },
      //       { id: 34, title: '500以下' },
      //     ]
      //   },
      //   {
      //     id: 4, title: '品牌', name: 'brand', value: '',
      //     children: [
      //       { id: '', title: '不限' },
      //       { id: 41, title: '奥丹' },
      //       { id: 42, title: '安道' },
      //       { id: 43, title: '兴进消防' },
      //       { id: 44, title: '华辉煌光' },
      //       { id: 45, title: '宏陶陶瓷' },
      //       { id: 46, title: '东鹏瓷砖' },
      //       { id: 47, title: '丰之林' },
      //       { id: 48, title: '家乐仕' },
      //       { id: 49, title: '奥丹' },
      //       { id: 491, title: '奥丹' },
      //     ]
      //   },
      //   {
      //     id: 5, title: '发货地', name: 'place', value: '',
      //     children: [
      //       { id: '', title: '不限' },
      //       { id: 51, title: '广州' },
      //       { id: 52, title: '柳州' },
      //       { id: 596, title: '深圳深圳深圳深圳深圳深圳' },
      //       { id: 53, title: '东莞' },
      //       { id: 54, title: '东莞' },
      //       { id: 55, title: '东莞' },
      //       { id: 56, title: '佛山' },
      //       { id: 57, title: '深圳' },
      //       { id: 58, title: '深圳' },
      //       { id: 59, title: '深圳' },
      //       { id: 591, title: '深圳' },
      //       { id: 592, title: '佛山' },
      //       { id: 593, title: '深圳' },
      //       { id: 594, title: '深圳' },
      //       { id: 595, title: '深圳' },
      //     ]
      //   },
      // ]
    };
  },
  // computed: {
  //   list() {
  //     return this.params.length > 0 ? this.params : this.mockList
  //   }
  // },
  methods: {
    handleChoose(obj) {
      this.$emit('change', obj)
    }
  }
};
</script>

<style lang="scss" scoped>
.c-prod-params {
  margin-top: .2rem;
  padding: .15rem .16rem .15rem .23rem;
}
</style>