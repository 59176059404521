<template>
  <div class="md-case-item c-card">
    <div class="text">
      <div class="tit s22 font-medium">{{ value.title }}</div>
      <div class="data s16">
        <div class="data-item">
          <div class="t1">项目规模：</div>
          <div class="t2 color-6 ep2">{{ value.project_scale }}</div>
        </div>
        <div class="data-item">
          <div class="t1">业主单位：</div>
          <div class="t2 color-6 ep2">{{ value.ownerUnit || '暂无' }}</div>
        </div>
        <div class="data-item">
          <div class="t1">总承包单位：</div>
          <div class="t2 color-6 ep2">{{ value.totalUnit || '暂无' }}</div>
        </div>
        <div class="data-item">
          <div class="t1">项目投资规模：</div>
          <div class="t2 color-6 ep2">￥{{ value.authentication_money || '0' }}</div>
        </div>
        <div class="data-item">
          <div class="t1">施工范围：</div>
          <div class="t2 color-6 ep2">{{ value.construction_scope }}</div>
        </div>
        <div class="data-item">
          <div class="t1">项目位置：</div>
          <div class="t2 color-6 ep2">{{ value.project_location }}</div>
        </div>
        <div class="data-item">
          <div class="t1">融资规模：</div>
          <div class="t2 color-6 ep2">￥{{ value.join_money || '0' }}</div>
        </div>
      </div>
      <BaseButton tag='router-link' :to='`/construction-detail?engineer_id=${value.engineer_id}`' class="btn font-light"
        icon='right' hover='green' styleType="border">
        查看详细
      </BaseButton>
    </div>
    <div class="imgs">
      <swiper class="swiper" :options="swiperOption" ref="mySwiper" :not-next-tick="notNextTick">
        <swiper-slide v-for="(item, index) in value.original_img" :key="index">
          <ImgRatio :src='item' :width="400" :height="300" class="img" />
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      notNextTick: true,
      swiperOption: {
        pagination: '.swiper-pagination',
        paginationClickable: true,
        speed: 500,
        spaceBetween: 10,
        autoplay: 3000,
        loop: true
      }
    };
  },
};
</script>

<style lang="scss" scoped>
.md-case-item {
  display: flex;
  padding: .3rem;
  padding-left: .5rem;
  margin-bottom: 0.2rem;

  &:last-of-type {
    margin-bottom: 0;
  }

  .text {
    flex: 1;
    padding-right: .5rem;
  }

  .tit {
    margin-top: .22rem;
  }

  .data {
    margin-top: .17rem;
    line-height: calc(30 / 16 * 1em);

    &-item {
      display: flex;
      margin-bottom: .07rem;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    .t2 {
      flex: 1;
    }
  }

  .btn {
    margin-top: .43rem;
    min-width: 1.2rem;
  }

  .imgs {
    width: 4rem;

    .img {
      border-radius: .04rem;
    }

    ::v-deep .swiper-pagination {
      bottom: .2rem;
      padding-right: .2rem;
      font-size: 0;
      text-align: right;

      &-bullet {
        width: 0.08rem;
        height: 0.08rem;
        background: #fff;
        opacity: .5;
        margin: 0 0 0 0.09rem !important;

        &-active {
          opacity: 1;
        }
      }
    }
  }
}
</style>