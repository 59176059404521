// 集采商城
import request from '@/request/request'


/* 商品数据 */ 
// 基础数据
export const IndexBaseApi = (params) => request.post('/pc/shop.index/base', params);
// 精选商品
export const SelectedProductsApi = (params) => request.post('/pc/shop.index/selected_products', params);
// 商城商品分类
export const IndexCatListApi = (params) => request.post('/pc/shop.index/cat_list', params);
// 商品列表
export const GoodsListApi = (params) => request.post('/pc/shop.index/goods_list', params);
// 商品详情
export const GoodsInfoApi = (params) => request.post('/pc/shop.index/goods_info', params);
// 商品评价
export const GoodsCommentListApi = (params) => request.post('/pc/shop.index/commentList', params);
// 本店热推
export const IndexHotGoodsApi = (params) => request.post('/pc/shop.index/hot_goods', params);
// 店铺详情
export const IndexshopInfoApi = (params) => request.post('/pc/shop.index/shop_info', params);
// 商品-收藏操作（增删改）
export const CollectGoodsApi = (params) => request.post('/pc/collect/goods_collect', params);
// 店铺-收藏操作（增删改）
export const CollectSuppliersApi = (params) => request.post('/pc/collect/suppliers_collect', params);
// 收藏商品-列表
export const CollectGoodsListApi = (params) => request.post('/pc/collect/goods_list', params);
// 收藏店铺-列表
export const CollectSuppliersListApi = (params) => request.post('/pc/collect/suppliers_list', params);
// 判断是否使用初始模版(进供应商首页时调用)
export const ShopTplJudTplApi = (params) => request.post('/pc/ShopTpl/jud_template', params);
// 店铺装修-模板预览
export const ShopTplPreviewApi = (params) => request.post('/pc/ShopTpl/tpl_preview', params);






/* 购物车 */ 
// 增改
export const CartAddApi = (params) => request.post('/pc/cart/add', params);
// 列表
export const CartListApi = (params) => request.post('/pc/cart/data_list', params);
// 删除
export const CartDelApi = (params) => request.post('/pc/cart/del', params);
// 数量
export const CartCountApi = (params) => request.post('/pc/cart/count', params);
// 选中
export const CartSelectApi = (params) => request.post('/pc/cart/selected', params);






