<template>
  <div class="base-popover">
    <slot name='target' />
    <div class="pop" v-if="$slots.default" :class="{ show: show }">
      <div class="pop-cnt s14 color-9">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean, default: false
    }
  },
  data() {
    return {};
  },
  created() {
    console.log(this.$slots);
  }
};
</script>

<style lang="scss" scoped>
.base-popover{
  position: relative;
  .pop{
    position: absolute; left: .09rem; top: 100%; z-index: 10;
    width: 3.22rem;
    padding-top: .16rem;
    display: none;
    &-cnt{
      border: solid 0.01rem #e6f2f8;
      border-radius: .04rem;
      padding: .14rem .19rem;
      position: relative;
      background: #fff;
      line-height: calc(24 / 14 * 1em);
      &:before{
        content: '';
        position: absolute; left: .175rem; top: -.05rem;
        transform: rotate(-45deg);
        width: .1rem; height: .1rem;
        border-top: solid 0.01rem #e6f2f8;
        border-right: solid 0.01rem #e6f2f8;
        background: #fff;
      }
    }
    &.show{
      display: block;
    }
  }
  &:hover{
    .pop{
      display: block;
    }
  }
}
</style>