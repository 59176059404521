<template>
  <div class="md-service-partner">
    <div class="c-title-2 s22" v-ani.fade-up>{{title}}</div>
    <div class="wrap c-card" v-ani.fade-up>
      <LogoList :row="6" :list="mockList"></LogoList>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: { type: String, default: '合作伙伴' },
    list: { type: Array, default() { return [] } }
  },
  data() {
    return {
      mockList: [
        { src: require('@/assets/images/cop-1.jpg'), name: '越秀地产' },
        { src: require('@/assets/images/cop-2.jpg'), name: '越秀地产' },
        { src: require('@/assets/images/cop-3.jpg'), name: '越秀地产' },
        { src: require('@/assets/images/cop-4.jpg'), name: '越秀地产' },
        { src: require('@/assets/images/cop-5.jpg'), name: '越秀地产' },
        { src: require('@/assets/images/cop-6.jpg'), name: '越秀地产' },
        { src: require('@/assets/images/cop-7.jpg'), name: '越秀地产' },
        { src: require('@/assets/images/cop-8.jpg'), name: '越秀地产' },
        { src: require('@/assets/images/cop-9.jpg'), name: '越秀地产' },
        { src: require('@/assets/images/cop-10.jpg'), name: '越秀地产' },
        { src: require('@/assets/images/cop-11.jpg'), name: '越秀地产' },
        { src: require('@/assets/images/cop-12.jpg'), name: '越秀地产' },
      ]
    };
  },
  computed: {
    showList() {
      return this.list.length == 0 ? this.mockList : this.list
    }
  }
};
</script>

<style lang="scss" scoped>
.md-service-partner{
  margin-top: .37rem;
  .wrap{}
  .list{
    padding: .3rem; padding-bottom: 0.18rem;
    margin-top: .15rem;
  }
}
</style>