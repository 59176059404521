<template>
  <div class="c-id-card">
    <div class="empty" v-if="!idImg">
      <img src="@/assets/images/icon-99.png" class="icon" v-if="type == 'front'">
      <img src="@/assets/images/icon-100.png" class="icon" v-else>
      <BaseButton style-type='light'>
        <span>上传身份证{{ type == 'front' ? '正面' : '背面' }}</span>
        <input type="file" @change="handleFile">
      </BaseButton>
    </div>
    <div class="data" v-else>
      <img :src="idImg" class="img">
      <div class="icon-delete" @click="handleDelete"></div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { domainName } from "@/config/index.js";
export default {
  props: {
    value: { type: String, default: "" },
    type: { type: String, default: 'front' }, // 正面-front, 背面-back
  },
  data() {
    return {
      idImg: '',
    };
  },
  created() {
    this.idImg = this.value;
  },
  methods: {
    handleFile(e) {
      const file = e.target.files[0]; // 获取第一个选定的文件对象
      let formData = new FormData(); // 创建FormData对象
      formData.append('file', file); // 添加文件到formData对象中
      axios.post(`${domainName}/pc/upload/upload_file`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // 设置请求头为multipart/form-data类型
          'yzfrom': 'pcmall',
          'token': localStorage.getItem('token')
        }
      })
        .then((response) => {
          if (response.data.status == 200) {
            this.idImg = response.data.data.url;
            this.$emit('change', this.idImg);
          }
        })
    },
    handleDelete() {
      this.idImg = null;
      this.$emit('change', this.idImg);
    }
  }
};
</script>

<style lang="scss" scoped>
.c-id-card {
  text-align: center;
  height: 1.8rem;
  border-radius: .04rem;
  border: dashed 1px #cae1ec;
  overflow: hidden;

  .empty {
    padding-top: .32rem;

    .icon {
      height: .62rem;
      display: block;
      margin: auto;
    }

    .base-button {
      margin-top: .19rem;
      width: 1.5rem;
      position: relative;

      input {
        position: absolute;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
      }
    }
  }

  .data {
    position: relative;
    height: 100%;

    .img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      display: block;
      margin: auto;
    }

    .icon-delete {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba($color: #000000, $alpha: 0.5) url(~@/assets/images/icon-delete.png) no-repeat center / .15rem;
      cursor: pointer;
      opacity: 0;
    }

    &:hover {
      .icon-delete {
        opacity: 1;
      }
    }
  }
}
</style>