<template>
  <div class="menu c-card" @click="jumpClick">
    <div class="hd">
      <div class="tit s18 font-medium">真正实现轻松化工地项目管理</div>
      <router-link class="more s14 color-9 flex-middle" to="/">
        <span>更多</span>
        <span class="icon"></span>
      </router-link>
    </div>
    <div class="list">
      <template v-for="item in list">
        <router-link class="li bg-contian" :key="item.id" to="">
          <ImgRatio :src="item.img" :width="274" :height="182" fit='contain' />
          <div class="tit s18 font-medium">{{ item.title }}</div>
        </router-link>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [
        { id: 1, title: '人员花名册', img: require('@/assets/images/menu-1.png') },
        { id: 2, title: '考勤灵活记录', img: require('@/assets/images/menu-2.png') },
        { id: 3, title: '项目实时监控', img: require('@/assets/images/menu-3.png') },
        { id: 4, title: '施工组织规范', img: require('@/assets/images/menu-4.png') },
        { id: 5, title: '创建材料需求', img: require('@/assets/images/menu-5.png') },
        { id: 6, title: '项目进度数据', img: require('@/assets/images/menu-6.png') },
      ]
    };
  },
  methods: {
    jumpClick() {
      window.open(`http://47.113.105.59/`);
    }
  }
};
</script>

<style lang="scss" scoped>
.menu {
  padding: .44rem .3rem .18rem;
  height: 100%;

  .hd {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .more {
      .icon {
        width: .06rem;
        height: .1rem;
        min-width: 6px;
        min-height: 10px;
        background: url(~@/assets/images/icon-right.png);
        margin-left: .05rem;
      }

      &:hover {
        color: $green;

        .icon {
          background-image: url(~@/assets/images/icon-right2.png);
        }
      }
    }
  }

  .list {
    display: flex;
    flex-wrap: wrap;
    margin-top: .19rem;

    .li {
      border-radius: .04rem;
      $width: 906 - 30 - 30;
      width: calc(274 / $width * 100%);
      margin-right: calc(12 / $width * 100%);
      margin-bottom: .12rem;
      position: relative;

      &:nth-of-type(3n) {
        margin-right: 0;
      }

      &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        border: 1px solid #e6f2f8;
        border-radius: .04rem;
      }

      &:hover {
        .tit {
          color: $green;
        }
      }
    }

    .tit {
      position: absolute;
      left: 0;
      bottom: .35rem;
      width: 100%;
      text-align: center;
    }
  }
}</style>