<template>
  <div class="md-service-client-case">
    <div class="c-title-2 s22" v-ani.fade-up>{{title}}</div>
    <div class='list'>
      <template v-for="item in list">
        <router-link to="" class='li c-card' :key="item.id" v-ani.fade-up>
          <ImgRatio :src='item.img' :width='263' :height='148'></ImgRatio>
          <div class='text s16'>
            <div class='tit color-2 font-medium text-center'>{{item.tit}}</div>
          </div>
        </router-link>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: { type: String, default: '' },
    list: { type: Array, default() { return [] } }
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.md-service-client-case{
  margin-top: .35rem;
  .list{
    margin-top: -.03rem;
    display: flex; flex-wrap: wrap;
    .li{
      width: calc(323 / 1350 * 100%);
      padding: .3rem; padding-bottom: .35rem;
      margin-right: .18rem; margin-top: .18rem;
      &:nth-of-type(4n){
        margin-right: 0;
      }
      &:hover{
        .tit{
          color: $blue;
        }
        ::v-deep .img{
          transform: scale(1.1);
        }
      }
    }
    .c-pic{
      border-radius: .04rem;
    }
    .text{
      line-height: calc(24 / 16 * 1em);
      padding-top: .16rem;
    }
  }
}
</style>