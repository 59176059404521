<template>
    <!-- 搜索 -->
    <div class="search_side">
        <div class="l s20">{{ describeTxt }}</div>
        <input type="text" class="ipt" v-model.trim="keywords" :placeholder="placeholderTxt" @keyup.enter="searchClick">
        <button class="btn hn-btn-green flex-center" @click="searchClick">
            <img src="@/assets/images/icon-search.png" class="icon">
            <span>{{ btnText }}</span>
        </button>
    </div>
</template>
<script>
export default {
    props: {
        describeTxt: { type: String, default: "模糊搜索" },
        placeholderTxt: { type: String, default: "请输入关键字信息" },
        btnText: { type: String, default: "搜索" },
    },
    created() {

    },
    data() {
        return {
            keywords: ""
        }
    },
    methods: {
        searchClick() {
            this.$emit('sarchFn', this.keywords);
        }
    }
}
</script>
<style lang="scss" scoped>
.search_side {
    padding: 0.2rem;
    display: flex;
    align-items: center;
    position: relative;

    .l {
        margin-right: 0.2rem;
    }

    .ipt {
        flex: 1;
        border: none;
        line-height: .48rem;
        border: solid 1px #e6f2f8;
        border-left: none;
        background: transparent;
        padding: 0 .22rem;
        font-size: 20px;

        &::placeholder {
            color: #999;
        }

        &.bl {
            border-left: 1px solid #e6f2f8;
            border-radius: .04rem 0 0 .04rem;
        }
    }

    .btn {
        border-radius: 0 .04rem .04rem 0;
        border: none;
        min-width: .9rem;
        height: 0.48rem;
        padding: 0 .18rem;
        cursor: pointer;
        position: absolute;
        top: 0.2rem;
        right: 0.2rem;

        .icon {
            width: .16rem;
            margin-right: .05rem;
        }
    }
}
</style>