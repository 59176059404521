<template>
    <el-dialog title="选择经纬度" :visible.sync="dialogVisible" width="50%" :before-close="() => $emit('close')"
        :append-to-body="true">
        <div style="display: flex">
            <div>
                <div>
                    <el-select v-model="keywords" filterable remote reserve-keyword placeholder="请输入关键词"
                        :remote-method="remoteMethod" :loading="loading" :clearable="true" size="mini"
                        @change="currentSelect" style="width: 600px">
                        <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item" class="one-text">
                            <span style="float: left">{{ item.name }}</span>
                            <span style="float: right; color: #8492a6; font-size: 13px">
                                {{ item.district }}
                            </span>
                        </el-option>
                    </el-select>
                </div>
                <div id="container" class="container"></div>
            </div>
            <div class="info_box">
                <div>纬度：{{ form.lat }}</div>
                <div>经度：{{ form.lng }}</div>
                <div>详细地址：{{ form.address }}</div>
            </div>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="() => $emit('close')">取 消</el-button>
            <el-button type="primary" @click="ok">确定</el-button>
        </span>
    </el-dialog>
</template>
   
<script>
import AMapLoader from "@amap/amap-jsapi-loader";
window._AMapSecurityConfig = {
    // 安全密钥
    securityJsCode: "fb5a0f973d8536c15a2b7950ac742321",
};
export default {
    name: "TestIndex",
    props: {
        dialogVisible: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            // 地图实例
            map: null,
            // 标记点
            marker: "",
            // 地址逆解析
            geoCoder: null,
            // 搜索提示
            AutoComplete: null,
            // 搜索关键字
            keywords: "",
            // 位置信息
            form: {
                lng: "",
                lat: "",
                address: "",
                adcode: "", //地区编码
                province:"",
                city:"",
                district:""
            },
            // 搜索节流阀
            loading: false,
            // 搜索提示信息
            options: [],
        };
    },
    methods: {
        ok() {
            this.$emit('ok', this.form)
        },
        initMap() {
            AMapLoader.load({
                // 你申请的Key
                key: "c9435f6c38db689ca645bfd3c49ab151",
                version: "2.0",
                // 需要用到的插件
                plugins: ["AMap.Geocoder", "AMap.AutoComplete"],
            })
                .then((AMap) => {
                    this.map = new AMap.Map("container", {
                        viewMode: "3D", //是否为3D地图模式
                        zoom: 5, //初始化地图级别
                        center: [113.383917, 22.93756], //初始化地图中心点位置
                    });
                    //地址逆解析插件
                    this.geoCoder = new AMap.Geocoder({
                        city: "010", //城市设为北京，默认：“全国”
                        radius: 1000, //范围，默认：500
                    });
                    // 搜索提示插件
                    this.AutoComplete = new AMap.AutoComplete({ city: "全国" });
                    //点击获取经纬度;
                    this.map.on("click", (e) => {
                        // 获取经纬度
                        this.form.lng = e.lnglat.lng;
                        this.form.lat = e.lnglat.lat;
                        // 清除点
                        this.removeMarker();
                        // 标记点
                        this.setMapMarker();
                    });
                })
                .catch((err) => {
                    // 错误
                    console.log(err);
                });
        },
        // 标记点
        setMapMarker() {
            // 自动适应显示想显示的范围区域
            this.map.setFitView();
            this.marker = new AMap.Marker({
                map: this.map,
                position: [this.form.lng, this.form.lat],
            });
            // 逆解析地址
            this.toGeoCoder();
            this.map.setFitView();
            this.map.add(this.marker);
        },
        // 清除点
        removeMarker() {
            if (this.marker) {
                this.map.remove(this.marker);
            }
        },
        // 逆解析地址
        toGeoCoder() {
            let lnglat = [this.form.lng, this.form.lat];
            this.geoCoder.getAddress(lnglat, (status, result) => {
                if (status === "complete" && result.regeocode) {
                    this.form.address = result.regeocode.formattedAddress;
                    this.form.province = result.regeocode.addressComponent.province || '';
                    this.form.city = result.regeocode.addressComponent.city || '';
                    this.form.district = result.regeocode.addressComponent.district || '';
                }
            });
        },
        // 搜索
        remoteMethod(query) {
            if (query !== "") {
                this.loading = true;
                setTimeout(() => {
                    this.loading = false;
                    this.AutoComplete.search(query, (status, result) => {
                        this.options = result.tips;
                    });
                }, 200);
            } else {
                this.options = [];
            }
        },
        // 选中提示
        currentSelect(val) {
            // 清空时不执行后面代码
            if (!val) {
                return;
            }
            this.form = {
                lng: val.location?.lng,
                lat: val.location?.lat,
                address: val.district + val.address,
                adcode: val.adcode,
            };
            // 清除点
            this.removeMarker();
            // 标记点
            this.setMapMarker();
        },
    },
    mounted() {
        this.initMap();
    },
};
</script>
   
<style lang="scss" scoped>
.container {
    width: 600px;
    height: 500px;
}

.info_box {
    margin-left: 20px;

    div {
        margin-top: 20px;
    }
}
</style>