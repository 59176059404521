<template>
  <div class="c-breadcrumbs flex-middle s16 color-9">
    <template v-for="(item, index) in list">
      <div class="item flex-middle" :key="item.name">
        <!-- 首页标志 -->
        <img src="@/assets/images/icon-home.png" alt="" class="icon-1" v-if="index == 0 && icon">
        <!-- 有链接 -->
        <router-link v-if="item.link" class="link" :to="item.link">{{ item.name }}</router-link>
        <!-- 没链接 -->
        <p v-else class="link">{{ item.name }}</p>
        <!-- 间隔的箭头 -->
        <img src="@/assets/images/icon-right.png" alt="" class="icon-2" v-if="index != list.length - 1">
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default() { return [] }
    },
    icon: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.c-breadcrumbs {
  padding: 0.27rem 0 .26rem;

  .item {
    &:last-of-type {
      color: #444;
    }
  }

  .icon-1 {
    width: .16rem;
    min-width: 1em;
    margin-right: .06rem;
  }

  .icon-2 {
    width: .06rem;
    min-width: 6px;
    margin: 0 .06rem;
  }

  a {
    &:hover {
      color: $blue;
    }
  }
}</style>