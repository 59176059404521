<template>
  <div class="c-card md-process">
    <div class="c-title-2 s22">{{info.title}}</div>
    <div class="desc s16 color-4" v-html="info.desc"></div>
    <div class="list" :class="{
      between: info.list.length < 6,
      twoline: info.list.length > 6
    }">
      <template v-for="(item, index) in info.list">
        <div class="li flex-middle" :key="item.id" :class="{ on: current == index }">
          <div class="wrap">
            <div class="cnt flex-center">
              <p class="num s32 font-bold">{{index + 1 | numPad}}</p>
              <div class="tit s16 color-2 font-medium" v-html="item.title"></div>
            </div>
            <p class="desc s16 color-9" v-if="item.desc">{{item.desc}}</p>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    info: {
      type: Object,
      default() { return null }
    }
  },
  data() {
    return {
      current: '',
      timer: ''
    };
  },
  mounted() {
    let i = 0
    this.timer = setInterval(() => {
      this.current = i
      i++
      if (i > this.info.list.length - 1) {
        i = 0
      }
    }, 1000)
  },
  beforeDestroy() {
    clearInterval(this.timer)
  }
};
</script>

<style lang="scss" scoped>
.md-process{
  margin-top: .4rem;
  padding: .37rem .3rem .2rem;
  .c-title-2{}
  .desc{
    line-height: calc(30 / 16 * 1em);
    margin-top: .16rem;
  }
  .list{
    display: flex; flex-wrap: wrap; align-items: flex-start;
    &.between{
      justify-content: space-around;
      position: relative;
      &:before{
        content: '';
        position: absolute; left: 0; top: .83rem; z-index: 1;
        width: 100%; height: .03rem;
        background: linear-gradient(to right, #cae1ec .03rem, transparent .03rem, transparent .09rem) repeat-x left center;
        background-size: .09rem auto;
      }
      .li{
        position: relative; z-index: 2;
        width: 1.76rem;
        background: #fff;
        display: block;
        &::before,
        &::after{
          display: none;
        }
      }
      .cnt{
        margin-left: auto; margin-right: auto;
      }
    }
    &.twoline{
      .li{
        &:last-of-type{
          &::after{
            display: none;
          }
        }
      }
    }
    .li{
      text-align: center;
      margin: .2rem 0;
      align-items: flex-start;
      &:before,
      &:after{
        content: '';
        width: .18rem; height: .03rem;
        background: linear-gradient(to right, #cae1ec .03rem, transparent .03rem, transparent .09rem) repeat-x left center;
        background-size: .09rem auto;
        margin-top: .63rem;
      }
      &.on{
        .cnt{
          background-image: url(~@/assets/images/icon-105.png);
        }
        .tit{
          color: $green;
        }
        .num{
          color: rgba($color: $green, $alpha: 0.2);
        }
      }
    }
    .cnt{
      width: 1.31rem; height: 1.31rem;
      background: url(~@/assets/images/icon-106.png) no-repeat center / contain;
      position: relative;
      margin: 0 .22rem;
      transition: .3s;
    }
    .num{
      color: #e6f2f8;
      position: absolute; left: 0; top: .38rem;
      width: 100%;
      transition: .3s;
    }
    .tit{
      line-height: calc(24 / 16 * 1em);
      position: relative; z-index: 2; top: .1rem;
      transition: .3s;
    }
    .desc{
      line-height: calc(24 / 16 * 1em);
      width: 9em; max-width: 100%;
      margin: .02rem auto 0;
    }
  }
}
</style>