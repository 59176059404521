<template>
  <div class="md-service-vs c-card">
    <div class="data" v-if="data1">
      <div class="c-title-2 s22">{{data1.title}}</div>
      <div class="list">
        <template v-for="item in data1.list">
          <div class="li flex-middle" :key="item.id">
            <div class="text">
              <div class="tit s18 color-2 font-medium">{{item.tit}}</div>
              <div class="desc s16 color-9" v-html="item.desc"></div>
            </div>
            <img :src="item.icon" alt="" class="icon">
          </div>
        </template>
      </div>
    </div>
    <div class="data" v-if="data2">
      <div class="split s48 font-bold">VS</div>
      <div class="c-title-2 s22 title-2">{{data2.title}}</div>
      <div class="list">
        <template v-for="item in data2.list">
          <div class="li flex-middle" :key="item.id">
            <div class="text">
              <div class="tit s18 color-2 font-medium">{{item.tit}}</div>
              <div class="desc s16 color-9" v-html="item.desc"></div>
            </div>
            <img :src="item.icon" alt="" class="icon">
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data1: { type: Object, default() { return null } },
    data2: { type: Object, default() { return null } },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.md-service-vs{
  margin-top: 0.4rem;
  padding: .37rem .3rem;
  .data{
    position: relative;
  }
  .list{
    display: flex;
    margin-top: .15rem;
    .li{
      width: 3.07rem; min-height: 1.61rem;
      border-radius: .04rem;
      border: solid 1px #e6f2f8;
      padding: .3rem; padding-right: .25rem;
      margin-right: .2rem;
      &:nth-of-type(4n){
        margin-right: 0;
      }
    }
    .text{
      flex: 1;
    }
    .desc{
      line-height: calc(26 / 16 * 1em);
      margin-top: .085rem;
    }
    .icon{
      width: .5rem;
      margin-left: .1rem;
    }
  }
  .title-2{
    margin-top: .44rem;
  }
  .split{
    position: absolute; left: 0; top: -.36rem;
    width: 100%;
    color: #e6f2f8; text-align: center;
  }
}
</style>