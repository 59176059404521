<template>
  <div class="home-data">
    <div class="base-width">
      <div class="list c-card s16 color-2">
        <template v-for="item in list">
          <div class="li" :key="item.id">
            <div class="row">
              <div class="num s36 font-bold color-blue">{{ item.num }}</div>
              <div class="unit s16">{{ item.unit }}</div>
              <img :src="item.icon" alt="" class="icon">
            </div>
            <div class="tit s22 font-medium">{{ item.title }}</div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { IndexApi } from "@/request/api/user";
export default {
  props: {
    obj: { type: Object, default: () => { } },
  },
  created(){
    this.list[0].num = this.obj.project_amount;
    this.list[1].num = this.obj.supplier_num;
    this.list[2].num = this.obj.project_num;
    this.list[3].num = this.obj.sku_num;
  },
  data() {
    return {
      list: [
        {
          id: 1,
          num: 262,
          unit: '亿元',
          title: '项目总金额',
          icon: require('@/assets/images/data-1.png')
        }, {
          id: 2,
          num: 1156,
          unit: '家',
          title: '战略供应商',
          icon: require('@/assets/images/data-2.png')
        }, {
          id: 3,
          num: 132,
          unit: '个',
          title: '管理工程项目',
          icon: require('@/assets/images/data-3.png')
        }, {
          id: 4,
          num: 19822,
          unit: '个',
          title: '上线SKU',
          icon: require('@/assets/images/data-4.png')
        }
      ]
    };
  },
  mounted(){
    this.getIndexData();
  },
  methods: {
    //顶部数据
    getIndexData(){
      IndexApi().then(res => {
        if(res.status == 200){
          this.list[0].num = res.data.data_statistics.project_amount;
          this.list[1].num = res.data.data_statistics.supplier_num;
          this.list[2].num = res.data.data_statistics.project_num;
          this.list[3].num = res.data.data_statistics.sku_num;
        }
      })
    },
  }
};
</script>

<style lang="scss" scoped>
.home-data {
  margin-top: .2rem;

  .list {
    display: flex;

    .li {
      flex: 1;
      position: relative;
      padding: .31rem 0 .24rem;
      text-align: center;

      &:after {
        content: '';
        position: absolute;
        right: 0;
        top: 50%;
        width: 1px;
        height: .38rem;
        background: #e6f2f8;
        margin-top: -.19rem;
      }

      &:last-of-type {
        &:after {
          display: none;
        }
      }
    }

    .row {
      display: flex;
      align-items: flex-end;
      justify-content: center;

      .num {
        line-height: 1;
      }

      .unit {
        margin: 0 .02rem;
      }

      .icon {
        height: .22rem;
        min-height: 16px;
      }
    }

    .tit {
      margin-top: .07rem;
    }
  }
}
</style>