<template>
  <div class="md-rank-1 c-card" v-ani.fade-up>
    <swiper :options="swiperOption" class="list">
      <template v-for="item in cateArr">
        <swiper-slide :key="item.id">
          <div class="slide" :class="{ on: item.id == current }" @click="select(item)">
            <div class="icon">
              <img :src="item.icon"  class="i-1">
              <img :src="item.icon2" class="i-2">
            </div>
            <div class="tit s18 color-2 font-medium">{{ item.title }}</div>
          </div>
        </swiper-slide>
      </template>
    </swiper>
  </div>
</template>

<script>
import { IndexBiddingCatListApi } from "@/request/api/user";
export default {
  created() {
    // 分类
    IndexBiddingCatListApi().then(({ status, data }) => {
      if (status == 200) {
        this.cateArr = data;
      }
    })
  },
  data() {
    return {
      cateArr: [],
      cate: [
        {
          id: 1,
          icon: require('@/assets/images/rank-1.png'),
          icon2: require('@/assets/images/rank-1-1.png'),
          title: '土建'
        }, {
          id: 2,
          icon: require('@/assets/images/rank-2.png'),
          icon2: require('@/assets/images/rank-2-1.png'),
          title: '市政'
        }, {
          id: 3,
          icon: require('@/assets/images/rank-3.png'),
          icon2: require('@/assets/images/rank-3-1.png'),
          title: '装饰'
        }, {
          id: 4,
          icon: require('@/assets/images/rank-4.png'),
          icon2: require('@/assets/images/rank-4-1.png'),
          title: '园林'
        }, {
          id: 5,
          icon: require('@/assets/images/rank-5.png'),
          icon2: require('@/assets/images/rank-5-1.png'),
          title: '暖通'
        }, {
          id: 6,
          icon: require('@/assets/images/rank-6.png'),
          icon2: require('@/assets/images/rank-6-1.png'),
          title: '给排水'
        }, {
          id: 7,
          icon: require('@/assets/images/rank-7.png'),
          icon2: require('@/assets/images/rank-7-1.png'),
          title: '其他'
        }, {
          id: 8,
          icon: require('@/assets/images/rank-8.png'),
          icon2: require('@/assets/images/rank-8-1.png'),
          title: '二手建材'
        }
      ],
      swiperOption: {
        // autoplay: 5000,
        // autoplayDisableOnInteraction: false,
        slidesPerView: 8,
        spaceBetween: 0,
        // 响应显示隐藏
        observer: true,
        observeParents: true,
      },
      current: 1,
    };
  },
  methods: {
    select(item) {
      this.current = item.id;
      this.$emit("change", item.id)
    }
  }
};
</script>

<style lang="scss" scoped>
.md-rank-1 {
  padding: .3rem;
  margin-top: 0.4rem;

  .list {
    .slide {
      cursor: pointer;
      text-align: center;
      border-top: solid 1px #e6f2f8;
      border-bottom: solid 1px #e6f2f8;
      border-right: solid 1px #e6f2f8;

      &:first-child {
        border-left: solid 1px #e6f2f8;
      }

      padding-top: 0.19rem;
      padding-bottom: 0.23rem;

      &.on {
        background: $blue;

        .tit {
          color: #fff;
        }

        .icon {
          .i-1 {
            display: none;
          }

          .i-2 {
            display: block;
          }
        }
      }
    }

    .tit {
      margin-top: 0.04rem;
    }

    .icon {
      width: 0.66rem;
      height: 0.66rem;
      font-size: 0;
      margin: auto;

      img {
        width: 100%;
        height: 100%;
      }

      .i-1 {
        display: block;
      }

      .i-2 {
        display: none;
      }
    }
  }
}
</style>