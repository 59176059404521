<template>
  <div class="md-order-product">
    <!-- 表头 -->
    <div class="thead s16 color-2 font-medium flex-middle" v-ani.fade-up>
      <div class="td">商品名称</div>
      <div class="td">单价</div>
      <div class="td">数量</div>
      <div class="td">小计</div>
    </div>
    <!-- 商品 -->
    <div class="tbody s16 color-4">
      <template v-for="item in goodsArr">
        <!-- 店铺 -->
        <div class="store" :key="item.suppliers_id" v-ani.fade-up>
          <!-- 店铺名称 -->
          <div class="store-info flex-middle">
            <div class="td">
              <ProductStoreName :shop_name="item.shop_name" :shop_type="item.shop_type"
                :suppliers_id="item.suppliers_id" />
            </div>
          </div>
          <!-- 商品列表 -->
          <div class="store-goods-list">
            <!-- 商品 -->
            <div class="store-goods flex-middle" v-for="goods in item.list" :key="goods.id"
              :class="{ out: goods.out, check: goods.check }">
              <div class="td">
                <ProductItemChoosed :goods="goods" />
              </div>
              <div class="td">
                <div class="price">{{ goods.member_goods_price || goods.goods_price }}</div>
              </div>
              <div class="td">{{ goods.goods_num }}</div>
              <div class="td subtotal color-red s22 font-medium">{{ goods.goods_fee || goods.total_price }}</div>
            </div>
          </div>
        </div>
      </template>
    </div>
    <div class="tfoot flex-middle" v-if="foot">
      <div class="t1 s16 font-medium color-green">
        <img src="@/assets/images/icon-bag.png" alt="">
        <span>安心购</span>
      </div>
      <p class="t2 s14 color-9">担保交易 · 官方严选 · 全程监督</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    foot: { type: Boolean, default: false },
    goodsArr: { type: Array, default: () => [] },
  },
  data() {
    return {
      list: [
        {
          id: 1,
          store: {
            type: '官方',
            name: '李四广州F4南区总分区店铺',
          },
          goods: [
            {
              id: 1,
              cover: require('@/assets/images/prod-4.jpg'),
              title: '水上乐园挂壁式泳池防水水下灯LED水底灯',
              attr: '尖角黑色四层   50*50',
              price: '¥280.00',
              all: 100,
              productNumber: 20,
              max: '',
              out: false,
              total: '¥280.00'
            }, {
              id: 2,
              cover: require('@/assets/images/prod-3.jpg'),
              title: '佛山瓷砖800x800客厅地砖全瓷通体大理石地板砖',
              attr: '尖角黑色四层   50*50',
              price: '¥280.00',
              all: 100,
              productNumber: 20,
              max: '',
              out: false,
              total: '¥280.00'
            }
          ]
        }
      ]
    };
  },
};
</script>

<style lang="scss" scoped>
.md-order-product {
  .thead {
    height: .7rem;
    background: #fff;
    border: solid 1px #e6f2f8;
  }

  .td {
    text-align: center;

    &:nth-child(1) {
      flex: 1;
      padding-left: .3rem;
      text-align: left;
    }

    &:nth-child(2) {
      width: 2.2rem;
    }

    &:nth-child(3) {
      width: 1.6rem;
    }

    &:nth-child(4) {
      width: 2.2rem;
      margin-right: .3rem;
    }
  }


  .store {
    border: solid 1px #e6f2f8;
    background: #fff;
    margin-top: .09rem;

    &-info {
      border-bottom: 1px solid #e6f2f8;

      .td {
        padding-top: .1rem;
        padding-bottom: .1rem;
      }
    }

    &-goods-list {
      .td {
        padding-top: .3rem;
        padding-bottom: .3rem;
      }
    }

    &-goods {
      border-bottom: 2px solid #e6f2f8;

      &:last-of-type {
        border: none;
      }
    }
  }

  .tfoot {
    padding-left: .3rem;
    background: #fff;
    height: .5rem;
    border: 1px solid #e6f2f8;

    .t1 {
      margin-right: .19rem;

      img {
        width: 0.14rem;
        margin-right: .05rem;
      }
    }
  }
}
</style>