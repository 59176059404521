<template>
  <!-- 下拉列表组件 -->
  <div class="base-select" v-clickoutside="hideOption" tabindex="0">
    <input type="text" hidden :value="selectedValue" :name="name">
    <!-- 显示当前值 -->
    <div class="text flex-middle" :class="[iptClass, selectedValue ? '' : emptyClass]" @click="showOption = true">
      {{ showText }}
    </div>
    <!-- 选项列表 -->
    <div class="list s15" v-show="showOption">
      <!-- <div v-if="placeholder" class="li" @click="choose({ value: '' })">
        {{ placeholder }}
      </div> -->
      <template v-for="item in options">
        <div class="li" :class="{ on: item.id == selectedValue }" :key="item.id" @click="choose(item)">
          {{ item.name }}
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    placeholder: {
      type: String,
      default: ''
    },
    options: {
      type: Array, default() { return [] }
    },
    value: {
      type: [String, Number],
      default: ''
    },
    iptClass: {
      type: String,
      default: ''
    },
    placeholderClass: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      selectedValue: this.value,
      showOption: false
    };
  },
  computed: {
    emptyClass() {
      return this.placeholderClass || 'empty'
    },
    showText() {
      let res = this.placeholder
      if (this.value) {
        let opt = this.options.find(el => el.id === this.value)
        if (opt) {
          res = opt.name
        }
      }
      return res
    },
  },
  methods: {
    choose(item) {
      this.selectedValue = item.id;
      this.$emit('input', item.id);
      this.$emit('change', item.id);
      this.hideOption()
    },
    hideOption(e) {
      this.showOption = false
    }
  }
};
</script>

<style lang="scss" scoped>
.base-select {
  position: relative;
  height: 100%;

  .text {
    height: 100%;
    background: url(~@/assets/images/icon-down.png) right .1rem center no-repeat;
    padding-left: .1rem;

    &.empty {
      color: #999;
    }
  }

  .list {
    position: absolute;
    left: 0;
    top: 100%;
    z-index: 100;
    width: 100%;
    max-height: 3rem;
    overflow-y: scroll;
    background: #fff;
    box-shadow: 0.02rem 0.05rem 0.1rem 0 #7c7c7c;
    border: 1px solid #eee;

    .li {
      padding: .07rem .1rem;
      border-bottom: 1px solid #eee;
      cursor: pointer;

      &:hover {
        background-color: $blueLight;
      }

      &.on {
        color: #fff;
        background-color: $blue;
      }
    }
  }
}
</style>