<template>
    <!-- 收藏的商品 -->
    <div class="collect_goods">
        <!-- 搜索 -->
        <div class="search_box" v-ani.fade-up>
            <!-- 输入框搜索 -->
            <collectSearch @sarchFn="sarchFn"></collectSearch>
            <!-- 状态搜索 -->
            <div class="status_search">
                <div class="l">
                    <div class="s20">商品状态</div>
                    <div class="opt" ref="item">
                        <div class="li s20" :class="{ on: getData.on_sale_status == index }"
                            v-for="(item, index) in statusArr" :key="index" @click="statusClick(index)">
                            {{ item }}
                        </div>
                    </div>
                </div>
                <div class="r">
                    <div class="r_btn s20" v-if="isMask" @click="allClick">
                        <img v-if="isAll" src="https://yizhu-new.oss-cn-shenzhen.aliyuncs.com/app_img/aIcon/icon390.png">
                        <img v-else src="https://yizhu-new.oss-cn-shenzhen.aliyuncs.com/app_img/aIcon/icon389.png">
                        全选
                    </div>
                    <div class="r_del color-red s20" v-if="isMask" @click="delClick">移除</div>
                    <div class="r_txt s20" v-if="isMask" @click="() => isMask = false">取消管理</div>
                    <div class="r_txt s20" v-else @click="() => isMask = true">管理</div>
                </div>
            </div>
            <!-- 商品数量 -->
            <div class="total_num flex-between">
                <div class="l"></div>
                <div class="r s16">共{{ total }}+商品</div>
            </div>
        </div>
        <!-- 商品列表 -->
        <div class="md-prod-list">
            <template v-for="item in listArr">
                <CollectGoodsItem :value='item' @refresh="listFn" :isMask="isMask" v-ani.fade-up @checkFn="checkFn">
                </CollectGoodsItem>
            </template>
        </div>
        <!-- 分页 -->
        <Pagination v-show="total > 0" :page="getData.p" :size="getData.page_num" :total="total" v-ani.fade-up
            @change="changePage" :key="pagKey" />
    </div>
</template>
<script>
import { CollectGoodsListApi, CollectGoodsApi } from "@/request/api/shop";
export default {
    created() {
        this.listFn();
    },
    data() {
        return {
            getData: {
                p: 1,
                page_num: 20,
                keywords: "",
                on_sale_status: 0,  // 状态：0=全部，1=出售中，-1=已下架
            },
            listArr: [],
            total: 0,
            pagKey: 0,
            statusArr: ['不限', '已下架', '出售中'],
            isMask: false,
            goodsIdArr: [],
            isAll: false,
        }
    },
    methods: {
        // 商品全选
        allClick() {
            this.isAll = !this.isAll;
            if (this.isAll) {
                this.listArr.map(item => {
                    item.isCheck = true;
                    this.goodsIdArr.push(item.goods_id)
                });
            } else {
                this.listArr.map(item => item.isCheck = false);
                this.goodsIdArr = [];
            }
        },
        // 商品选中
        checkFn(obj) {
            if (obj.isCheck) {
                this.goodsIdArr.push(obj.goods_id);
            } else {
                this.goodsIdArr = this.goodsIdArr.filter((item) => item != obj.goods_id)
            }
        },
        // 移除商品
        delClick() {
            if (this.goodsIdArr.length <= 0) {
                this.$message.error('请选择移除商品');
                return;
            }
            CollectGoodsApi({ goods_id: this.goodsIdArr.toString() }).then(({ status }) => {
                if (status == 200) {
                    this.$message.success('操作成功');
                    this.isMask = false;
                    this.listFn();
                }
            })
        },
        sarchFn(val) {
            this.getData.keywords = val;
            this.getData.p = 1;
            this.listFn();
        },
        statusClick(index) {
            this.getData.on_sale_status = index;
            this.getData.p = 1;
            this.listFn();
        },
        listFn() {
            CollectGoodsListApi(this.getData).then(({ data, status }) => {
                if (status == 200) {
                    data.list.map(item => {
                        item.isCheck = false;
                    })
                    this.listArr = data.list;
                    this.total = data.total;
                    this.pagKey++;
                }
            })
        },
        // 分页
        changePage(page) {
            this.getData.p = page;
            this.listFn();
            window.scroll({ top: 500, behavior: "smooth", });
        },
    }
}
</script>
<style lang="scss" scoped>
.search_box {
    margin-top: 0.2rem;
    padding-bottom: 0.2rem;
    // min-height: 2rem;
    background-color: #fff;
    border-radius: .04rem;
}

.md-prod-list {
    margin-top: .2rem;
    display: flex;
    flex-wrap: wrap;

    .c-product-item {
        width: 3rem;
        margin-bottom: .2rem;
        margin-right: .2rem;

        &:nth-of-type(5n) {
            margin-right: 0;
        }
    }
}

.status_search {
    padding: 0 0.2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .l {
        display: flex;
        align-items: center;

        .opt {
            display: flex;

            .li {
                margin-left: 0.1rem;
                padding: 0 .05rem;
                min-width: .84rem;
                text-align: center;
                line-height: .3rem;
                border-radius: .04rem;
                cursor: pointer;

                &:hover {
                    color: $blue;
                }

                &.on {
                    color: $blue;
                    background: $blueLight;
                }
            }
        }
    }

    .r {
        display: flex;

        .r_btn {
            margin-right: 0.2rem;
            display: flex;
            align-items: center;
            cursor: pointer;

            img {
                margin-right: 6px;
                width: 21px;
                height: 21px;
            }
        }

        .r_del {
            margin-right: 0.2rem;
            cursor: pointer;
        }

        .r_txt {
            cursor: pointer;
        }
    }
}

.total_num {
    margin-top: 0.1rem;
    padding: 0 0.2rem;
    color: #999999;
}
</style>