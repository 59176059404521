<template>
  <!-- 收藏 -->
  <div class="c-collect flex-middle s16 color-4">
    <img src="@/assets/images/icon-collect-2.png" alt="" v-if="value">
    <img src="@/assets/images/icon-collect-1.png" alt="" v-else>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    value: { type: Boolean | Number, default: false }
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.c-collect {
  cursor: pointer;

  img {
    margin-right: .04rem;
  }
}
</style>