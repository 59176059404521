<template>
  <div class="md-prod-seller c-card">
    <div class="row-1 flex-top">
      <img :src="shop_info.logo" class="avatar">
      <div>
        <div class="flex-middle sect-1">
          <div class="name s22 color-2 font-medium">{{ shop_info.shop_name }}</div>
          <div v-if="shop_info.sell_mode" class="seller-type s14 color-green font-light">{{ shop_info.sell_mode }}</div>
        </div>
        <div class="flex-middle sect-2">
          <UserRank :hatArr="shop_info.level.hat" :starsArr="shop_info.level.stars"></UserRank>

          <BaseButton v-if="shop_info.real_name" size='mini' :hover="false" class="label-1" color="blue"
            style-type='light'>{{ shop_info.real_name }}
          </BaseButton>
          <BaseButton v-if="shop_info.settle_in" size='mini' :hover="false" class="label-1" color="green"
            style-type='light'>
            {{ shop_info.settle_in }}
          </BaseButton>
        </div>
      </div>
      <div class="col-2">
        <div class="flex-middle sect-3 s16 color-6">
          <div class="text">主营：{{ shop_info.cat_name || '暂无' }}</div>
          <div class="text">总销：{{ shop_info.order_sales_num }}单</div>
        </div>
        <!-- <div class="flex-middle sect-4 s14 color-9 font-light">
          <div class="flex-middle rank">
            <img src="@/assets/images/icon-107.png" alt="">
            <span>入围好评店铺榜单 第3名</span>
          </div>
          <div class="flex-middle rank">
            <img src="@/assets/images/icon-107.png" alt="">
            <span>入围实力店铺榜单 第3名</span>
          </div>
        </div> -->
      </div>
      <div class="btn-follow s16 bg-blue color-f flex-middle" @click="followClick">
        <img src="@/assets/images/icon-follow.png">
        <span v-if="shop_info.if_follow">已关注</span>
        <span v-else>关注</span>
      </div>

    </div>
    <div class="row-2 s16">
      <div class="text">
        <span class="color-6">店铺地址：</span>
        <span class="color-4">{{ shop_info.address }}</span>
      </div>
      <div class="text">
        <span class="color-6">店铺简介：</span>
        <span class="color-4">{{ shop_info.business_description }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    shop_info: { type: Object, default: () => { } }
  },
  data() {
    return {};
  },
  methods: {
    followClick() {
      this.$emit('followFn');
    },
  }
};
</script>

<style lang="scss" scoped>
.md-prod-seller {
  padding: .3rem;
  padding-bottom: .27rem;

  .avatar {
    width: 0.66rem;
    height: .66rem;
    border-radius: 50%;
    margin-right: .12rem;
  }


  .seller-type {
    border: 1px solid;
    border-radius: .04rem;
    line-height: .18rem;
    padding: 0 .06rem;
    margin-left: .2rem;
  }

  .sect-1 {
    margin-top: .01rem;
  }

  .sect-2 {
    margin-top: .09rem;
  }

  .c-level-wrap {
    height: .3rem;
    width: 1.1rem;
  }

  .label-1 {
    margin-left: 0.12rem;
  }

  .col-2 {
    margin-left: .68rem;
  }

  .sect-3 {
    margin-top: .11rem;

    .text {
      margin-right: .3rem;
    }
  }

  .sect-4 {
    margin-top: .16rem;
  }

  .rank {
    margin-right: .3rem;

    img {
      width: .13rem;
      margin-right: .03rem;
    }
  }

  .btn-follow {
    margin-left: auto;
    margin-top: 0.03rem;
    border-radius: .04rem;
    line-height: .3rem;
    padding: 0 .16rem 0 .13rem;
    cursor: pointer;

    img {
      width: 0.16rem;
      margin-right: .05rem;
    }

    &:hover {
      background: $blueDeep;
    }
  }

  .row-2 {
    margin-top: .31rem;

    .text {
      margin-bottom: .14rem;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}
</style>