<template>
  <header>
    <div class="header">
      <div class="base-width flex-middle">
        <!-- logo -->
        <h1 class="logo s0">
          <router-link to="/">
            <img src="@/assets/images/logo.png" alt="">
          </router-link>
        </h1>
        <!-- 导航 -->
        <ul class="nav s16 color-2 flex-middle">
          <li class="li-1">
            <div class="a-1" style="cursor: pointer;" @click="shoucang('易筑官网', '.url', 'https://www.51ezhu.com/#/')">
              添加到桌面</div>
          </li>
          <template v-for="item in list">
            <li class="li-1" :key="item.id" :class="{ on: isCurrent(item.name) }">
              <router-link class="a-1" :to="item.link" :target="item.target">
                {{ item.title }}
              </router-link>
            </li>
          </template>
        </ul>
        <!-- 加入易筑 -->
        <router-link to="/join" class="btn-add s16 hn-btn-blue-plain" :class="{ on: currentId == -1 }">
          加入易筑
        </router-link>
      </div>
    </div>
    <div class="blank"></div>
  </header>
</template>

<script>
import { list } from '@/router'
export default {
  data() {
    return {
      list: [
        { id: 1, target: '', title: '首页', link: '/', name: 'home' },
        { id: 2, target: '', title: '集采商城', link: '/shop', name: 'shop' },
        { id: 3, target: '', title: '智慧工地', link: '/construction', name: 'construction' },
        { id: 4, target: '', title: '招投标', link: '/bid', name: 'bid' },
        { id: 5, target: '', title: '供应链金融', link: '/finance', name: 'finance' },
        { id: 6, target: '', title: '配套服务', link: '/service', name: 'service' },
        { id: 7, target: '', title: '行业资讯', link: '/news', name: 'news' },
        { id: 8, target: '', title: '云仓物流', link: '/logistics', name: 'logistics' },
        { id: 9, target: '', title: '大数据中心', link: '/data', name: 'data' },
        { id: 10, target: '', title: '合作伙伴', link: '/partner', name: 'partner' },
      ],
      routes: list
    }
  },
  computed: {
    currentId() {
      let res = this.list[0].id
      let temp = this.list.find(el => el.link == this.$route.path || el.name == this.$route.meta.parent)
      if (temp) {
        res = temp.id
      }
      if (this.$route.path == '/join') {
        res = -1
      }
      return res
    }
  },
  methods: {
    isCurrent(name) {
      if (name == this.$route.name || name == this.$route.meta.parent) {
        return true
      } else {
        for (let i = 0; i < this.routes.length; i++) {
          const el = this.routes[i];
          if (el.name == name) {
            if (el.children) {
              for (let j = 0; j < el.children.length; j++) {
                const ele = el.children[j];
                if (ele.name == this.$route.name) {
                  return true
                }
              }
            }
          }
        }
        return false
      }
    },
    shoucang(a, b, c) {
      var d = '' + a + b;
      var e = '[InternetShortcut]' + '\n' + 'URL=' + c;
      this.saveToDesktop(d, e);
    },
    /**
         * [
         *     saveToDesktop 
         *     IE:可以将数据写进文件并保存到桌面；
         *     fireFox:可以选择路径将写进数据的文件保存；
         *     chrome:只能保存到浏览器指定的下载目录下；
         * ]
         * @param  {[string]} fileName [文件名 如："test.txt"]
         * @param  {[string]} data     [数据 如："username:key \r\n password:123456"]
    */
    saveToDesktop(fileName, data) {
      var explorer = navigator.userAgent.toLowerCase();
      if (explorer.indexOf("trident") > 0) {
        //IE 5+
        var wss = new ActiveXObject("WScript.Shell");
        var desktopUrl = wss.SpecialFolders("Desktop");
        var fso = new ActiveXObject("Scripting.FileSystemObject");
        var tf = fso.CreateTextFile(desktopUrl + "\\" + fileName, true);
        tf.Write(data);
        tf.Close();
      } else {
        //fireFox chrome
        var urlObj = window.URL || window.webkitURL || window;
        var blob = new Blob([data]);
        var link = document.createElementNS("http://www.w3.org/1999/xhtml", "a")
        link.href = urlObj.createObjectURL(blob);
        link.download = fileName;
        this.saveClick(link);
      }
    },
    saveClick(obj) {
      var e = document.createEvent("MouseEvents");
      e.initMouseEvent(
        "click", true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null
      );
      obj.dispatchEvent(e);
    }
  }
};
</script>

<style lang='scss' scoped>
.header {
  background: #fff;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 200;
  width: 100%;
  min-width: 1200px;

  .base-width {
    min-height: .8rem;
  }

  .logo {
    margin-right: auto;

    img {
      max-height: .7rem;
    }
  }

  .nav {
    line-height: .4rem;
    text-align: center;

    .li-1 {
      margin-right: .06rem;

      &:last-of-type {
        margin-right: 0;
      }

      .a-1 {
        display: block;
        min-width: .7rem;
        border-radius: .04rem;
        padding: 0 .19rem;
        transition: .3s;
      }

      &:hover {
        .a-1 {
          color: $blue;
        }
      }

      &.on {
        .a-1 {
          background: $blue;
          color: #fff;
        }
      }
    }
  }

  .btn-add {
    line-height: .38rem;
    padding: 0 .17rem;
    border-radius: .04rem;
    margin-left: .26rem;

    &.on {
      background: $blue;
      color: #fff;
    }
  }
}

.blank {
  height: 0.8rem;
}
</style>