<template>
  <div class="home-news-2 c-card">
    <div class="title s18 font-medium">招标项目情况</div>
    <div class="cnt">
      <div class="lf">
        <template v-for="(item, index) in list">
          <div
            v-if="index < 5"
            class="item hn-btn-green-light flex-middle"
            :key="item.id"
            :class="{ on: index == current }"
            @click="current = index"
          >
            <span class="s16 font-medium">{{ item.title }}</span>
            <img :src="item.icon" alt="" class="icon">
          </div>
        </template>
      </div>
      <div class="rg">
        <template v-for="(item, index) in list">
          <transition name="fade" :key="item.id" v-if="index < 5">
            <div class="list" v-show="index === current">
              <template v-for="(art,index) in item.article">
                <router-link class="li flex flex-middle" to="" :key="index">
                  <div class="tit ep1 s16">{{ art.title }}</div>
                  <div class="time s14 color-9 flex-middle">
                    <img src="@/assets/images/icon-time.png" alt="">
                    <span>{{ art.addtime }}</span>
                  </div>
                  <div class="status hn-btn-blue-light s14 font-light">{{ item.status }}</div>
                </router-link>
              </template>
            </div>
          </transition>
        </template>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    data: {
      type: Array,
      default() { return [] }
    }
  },
  data() {
    return {
      current: 0,
      mockList: [
        {
          id: 1,
          title: '招标计划',
          icon: require('@/assets/images/icon-88.png'),
          article: [
            {
              id: 1,
              title: '德颂项目装修工程泥工铺贴分包标段招标',
              date: '2023.05.01'
            }, {
              id: 2,
              title: '德颂项目装修工程腻子批灰劳务分包工程',
              date: '2023.05.01'
            }, {
              id: 3,
              title: '德颂项目装修工程泥工铺贴劳务分包(一、二)标段',
              date: '2023.05.01'
            }, {
              id: 4,
              title: '陆丰市甲东镇禁毒检查点升级改造工程',
              date: '2023.05.01'
            }, {
              id: 5,
              title: '陆丰市上英镇联海村党建文化广场建设工招标',
              date: '2023.05.01'
            }, {
              id: 6,
              title: '云浮市新兴县稔村镇稔村社区岸阳坊宅基招标地工程',
              date: '2023.05.01'
            }, {
              id: 7,
              title: '江门市新会区双水镇梁凤仪文学馆项目改招标',
              date: '2023.05.01'
            }
          ]
        }, {
          id: 2,
          title: '招标候公告',
          icon: require('@/assets/images/icon-9.png'),
          article: [
            {
              id: 1,
              title: '德颂项目装修工程泥工铺贴分包标段招标',
              date: '2023.05.01'
            }, {
              id: 2,
              title: '德颂项目装修工程腻子批灰劳务分包工程',
              date: '2023.05.01'
            }, {
              id: 3,
              title: '德颂项目装修工程泥工铺贴劳务分包(一、二)标段',
              date: '2023.05.01'
            }, {
              id: 4,
              title: '陆丰市甲东镇禁毒检查点升级改造工程',
              date: '2023.05.01'
            }, {
              id: 5,
              title: '陆丰市上英镇联海村党建文化广场建设工招标',
              date: '2023.05.01'
            }, {
              id: 6,
              title: '云浮市新兴县稔村镇稔村社区岸阳坊宅基招标地工程',
              date: '2023.05.01'
            }, {
              id: 7,
              title: '江门市新会区双水镇梁凤仪文学馆项目改招标',
              date: '2023.05.01'
            }
          ]
        }, {
          id: 3,
          title: '评标报告',
          icon: require('@/assets/images/icon-10.png'),
          article: [
            {
              id: 1,
              title: '德颂项目装修工程泥工铺贴分包标段招标',
              date: '2023.05.01'
            }, {
              id: 2,
              title: '德颂项目装修工程腻子批灰劳务分包工程',
              date: '2023.05.01'
            }, {
              id: 3,
              title: '德颂项目装修工程泥工铺贴劳务分包(一、二)标段',
              date: '2023.05.01'
            }, {
              id: 4,
              title: '陆丰市甲东镇禁毒检查点升级改造工程',
              date: '2023.05.01'
            }, {
              id: 5,
              title: '陆丰市上英镇联海村党建文化广场建设工招标',
              date: '2023.05.01'
            }, {
              id: 6,
              title: '云浮市新兴县稔村镇稔村社区岸阳坊宅基招标地工程',
              date: '2023.05.01'
            }, {
              id: 7,
              title: '江门市新会区双水镇梁凤仪文学馆项目改招标',
              date: '2023.05.01'
            }
          ]
        }, {
          id: 4,
          title: '中标候选人',
          icon: require('@/assets/images/icon-11.png'),
          article: [
            {
              id: 1,
              title: '德颂项目装修工程泥工铺贴分包标段招标',
              date: '2023.05.01'
            }, {
              id: 2,
              title: '德颂项目装修工程腻子批灰劳务分包工程',
              date: '2023.05.01'
            }, {
              id: 3,
              title: '德颂项目装修工程泥工铺贴劳务分包(一、二)标段',
              date: '2023.05.01'
            }, {
              id: 4,
              title: '陆丰市甲东镇禁毒检查点升级改造工程',
              date: '2023.05.01'
            }, {
              id: 5,
              title: '陆丰市上英镇联海村党建文化广场建设工招标',
              date: '2023.05.01'
            }, {
              id: 6,
              title: '云浮市新兴县稔村镇稔村社区岸阳坊宅基招标地工程',
              date: '2023.05.01'
            }, {
              id: 7,
              title: '江门市新会区双水镇梁凤仪文学馆项目改招标',
              date: '2023.05.01'
            }
          ]
        }, {
          id: 5,
          title: '中标结果',
          icon: require('@/assets/images/icon-12.png'),
          article: [
            {
              id: 1,
              title: '德颂项目装修工程泥工铺贴分包标段招标',
              date: '2023.05.01'
            }, {
              id: 2,
              title: '德颂项目装修工程腻子批灰劳务分包工程',
              date: '2023.05.01'
            }, {
              id: 3,
              title: '德颂项目装修工程泥工铺贴劳务分包(一、二)标段',
              date: '2023.05.01'
            }, {
              id: 4,
              title: '陆丰市甲东镇禁毒检查点升级改造工程',
              date: '2023.05.01'
            }, {
              id: 5,
              title: '陆丰市上英镇联海村党建文化广场建设工招标',
              date: '2023.05.01'
            }, {
              id: 6,
              title: '云浮市新兴县稔村镇稔村社区岸阳坊宅基招标地工程',
              date: '2023.05.01'
            }, {
              id: 7,
              title: '江门市新会区双水镇梁凤仪文学馆项目改招标',
              date: '2023.05.01'
            }
          ]
        }
      ]
    };
  },
  computed: {
    list() {
      return this.data.length == 0 ? this.mockList : this.data
    }
  },
  
};
</script>

<style lang="scss" scoped>
.home-news-2{
  padding: .28rem .3rem;
  .cnt{
    margin-top: .1rem;
    display: flex;
    height: 4.07rem; overflow: hidden;
    .lf{
      width: 1.81rem;
      margin-right: .4rem;
      display: flex; flex-direction: column;
      .item{
        flex: 1;
        border-radius: .04rem;
        position: relative;
        overflow: hidden;
        height: .66rem;
        padding: 0 .19rem;
        cursor: pointer;
        margin-bottom: .18rem;
        &:last-of-type{
          margin-bottom: 0;
        }
        .icon{
          position: absolute; right: -.1rem;
          opacity: .1;
          width: .44rem;
        }
        &.on,
        &:hover{
          background: #2ba743; color: #fff;
          .icon{
            mix-blend-mode: color-dodge;
            right: .1rem;
          }
        }
      }
    }
    .rg{
      flex: 1;
      .list{
        .li{
          padding: .145rem 0;
          border-bottom: 1px solid #e6f2f8;
          &:first-of-type{
            padding-top: 0.02rem;
          }
          &:hover{
            .tit{
              color: $green;
            }
            .status{
              background: $blue; color: #fff;
            }
          }
        }
        .tit{
          flex: 1;
        }
        .status{
          line-height: calc(30 / 14 * 1em);
          border-radius: .04rem;
          padding: 0 .11rem;
          margin-left: .2rem;
        }
        .time{
          margin-left: .2rem;
          img{
            margin-right: .06rem;
            width: .14rem; min-width: 1em;
          }
        }
      }
    }
  }
}
.fade-enter-active{
  transition: .5s;
}
.fade-enter{
  opacity: 0;
  transform: translateX(1rem);
}
</style>