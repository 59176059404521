<template>
  <label class="c-radio flex-middle">
    <input type="radio" hidden :name="name" :checked='value == label' @change="handleChange">
    <div class="c-radio_icon"></div>
    <div class="c-radio_name">
      <slot />
    </div>
  </label>
</template>

<script>
export default {
  props: {
    value: '',
    name: '',
    label: '', // 值
  },
  data() {
    return {};
  },
  methods: {
    handleChange() {
      this.$emit('input', this.label);
      this.$emit('change')
    }
  }
};
</script>

<style lang="scss" scoped>
.c-radio {
  margin: .068rem .3rem .068rem 0;
  cursor: pointer;
  position: relative;

  &_icon {
    margin-right: .07rem;
  }

  input:checked~&_name {
    color: $blue;
  }

  &:hover {
    .text {
      display: block;
    }
  }
}
</style>