<template>
  <!-- 产品相册轮播 -->
  <div class="c-product-album">
    <!-- 大图 -->
    <div class="list-big">
      <swiper class="container" :options="optionsBig" :not-next-tick="notNextTick" ref="swiperBig">
        <swiper-slide v-for="(item, index) in mockList" :key="index" class="slide">
          <img :src="item" alt="" class="img">
        </swiper-slide>
      </swiper>
    </div>
    <!-- 缩略图 -->
    <div class="list-small flex-middle">
      <div class="album-btn bg-contain pointer album-btn-prev"></div>
      <swiper class="container" :options='optionsSmall' :not-next-tick="notNextTick" ref="swiperSmall">
        <swiper-slide v-for="(item, index) in mockList" :key="index" class="slide" :class="{ on: current == index }"
          @click.native="jump(index)">
          <img :src="item" alt="" class="img">
        </swiper-slide>
      </swiper>
      <div class="album-btn bg-contain pointer album-btn-next"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      props: { type: Array, default() { return [] } }
    },
    mockList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      // mockList: [
      //   { img: require('@/assets/images/prod-6.jpg') },
      //   { img: require('@/assets/images/prod-6.jpg') },
      //   { img: require('@/assets/images/prod-6.jpg') },
      //   { img: require('@/assets/images/prod-6.jpg') },
      //   { img: require('@/assets/images/prod-6.jpg') },
      //   { img: require('@/assets/images/prod-6.jpg') },
      // ],
      notNextTick: true,
      optionsBig: {
        autoplay: 3000,
        autoplayDisableOnInteraction: false,
        onTransitionEnd: (swiper) => {
          this.current = swiper.activeIndex
          this.swiperSmall.slideTo(swiper.activeIndex)
        }
      },
      optionsSmall: {
        slidesPerView: 4,
        spaceBetween: '5.4%',
        // 响应显示隐藏
        observer: true,
        observeParents: true,
        // 左右箭头
        prevButton: '.album-btn-prev',
        nextButton: '.album-btn-next',
      },
      current: 0
    };
  },
  computed: {
    swiperBig() {
      return this.$refs.swiperBig.swiper
    },
    swiperSmall() {
      return this.$refs.swiperSmall.swiper
    },
  },
  methods: {
    jump(i) {
      this.current = i
      this.swiperBig.slideTo(i)
    }
  }
};
</script>

<style lang="scss" scoped>
.c-product-album {
  width: 4.62rem;

  .list-big {
    .slide {
      border: 1px solid #cae1ec;
      border-radius: .04rem;
    }

    .img {
      display: block;
      border-radius: inherit;
      width: 4.6rem;
      height: 4.6rem;
      object-fit: cover;
    }
  }

  .list-small {
    margin-top: .2rem;

    .album-btn {
      width: .17rem;
      height: .27rem;

      &-prev {
        background-image: url(~@/assets/images/icon-arrow-1.png);
      }

      &-next {
        background-image: url(~@/assets/images/icon-arrow-2.png);
      }
    }

    .container {
      flex: 1;
      margin: 0 .3rem;
    }

    .slide {
      border: 1px solid #cae1ec;
      border-radius: .04rem;
      cursor: pointer;

      &.on {
        position: relative;

        &:before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          border: .03rem solid $green;
        }
      }
    }

    .img {
      display: block;
      width: 100%;
      height: 100%;
      border-radius: .04rem;
    }
  }
}
</style>