// 登录模块
import request from '@/request/request'

// 密码登录
export const passwordLoginApi = (params) => request.post('/pc/user/login', params);
// 短信登录
export const sendLoginApi = (params) => request.post('/pc/user/code_login', params);
// 短信登录
export const sendSmsApi = (params) => request.post('/pc/sms/send', params);
// 注册
export const registerApi = (params) => request.post('/pc/user/register', params);
// 退出登录
export const LogoutApi = (params) => request.post('/pc/user/logout', params);
// 生成登录二维码
export const QrcodeApi = (params) => request.post('/pc/user/qrcode', params);
// 检测扫码结果
export const QrcodeLoginApi = (params) => request.post('/pc/user/qrcode_login', params);





// 省市区(树形)
export const FegionTreeApi = (params) => request.post('/pc/address/get_region_tree', params);
// 省市区
export const FegionApi = (params) => request.post('/pc/address/get_region', params);


// 协议列表
export const ProtocolsApi = (params) => request.post('/pc/index/protocols', params);


