<template>
  <div class="c-card c-prod-params">
    <!-- <template v-for="item in list">
      <ProductParamsItem :collapse="collapse" :key="item.id" :item='item' @change="handleChoose" />
    </template> -->
    <ProductParamsItem v-if="baseObj.cat_list?.length > 0" :typeNum="6" :collapse="collapse" :listArr="baseObj.cat_list"
      @change="handleChoose" title="分类" />
    <ProductParamsItem :typeNum="1" :collapse="collapse" :listArr="baseObj.material_type" @change="handleChoose"
      title="材质标准" />
    <ProductParamsItem :typeNum="2" :collapse="collapse" :listArr="baseObj.material" @change="handleChoose" title="材质" />
    <ProductParamsItem :typeNum="3" :collapse="collapse" :listArr="baseObj.limit_buy" @change="handleChoose"
      title="起批量" />
    <ProductParamsItem :typeNum="4" :collapse="collapse" :listArr="baseObj.brand" @change="handleChoose" title="品牌"
      showMore />
    <ProductParamsItem :typeNum="5" :collapse="collapse" :listArr="baseObj.address" @change="handleChoose" title="发货地" />
  </div>
</template>

<script>
export default {
  props: {
    collapse: {
      type: Boolean,
      default: true
    },
    baseObj: {
      type: Object,
      default: () => { }
    }
  },
  data() {
    return {
    };
  },
  methods: {
    handleChoose(obj) {
      this.$emit('change', obj)
    }
  }
};
</script>

<style lang="scss" scoped>
.c-prod-params {
  margin-top: .2rem;
  padding: .15rem .16rem .15rem .23rem;
}
</style>