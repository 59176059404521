<template>
  <!-- 本店商品，无店铺信息 -->
  <div class="c-product-item c-card">
    <router-link class="goods" :to="`/product-detail?goods_id=${value.goods_id}`" tag="a" target="_blank"> 
      <!-- 图片 -->
      <div class="img">
        <ImgRatio :src="value.goods_image" :width="260" :height="260"></ImgRatio>
        <div v-if="value.if_period" class="label s14 color-f font-light">账期</div>
      </div>
      <!-- 价格 -->
      <div class="price s20 color-red font-medium">
        <span class="s22">{{ value.shop_price }}</span>
        <span class="s20">/{{ value.unit_name }}</span>
      </div>
      <!-- 标题 -->
      <div class="tit s16 ep2">{{ value.goods_name }}</div>
      <!-- 销量 -->
      <div class="s14 color-9 font-light sales">
        <span>{{ value.sales_sum }}人已购买</span>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  props: {
    value: { type: Object, default() { return {} } }
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.c-product-item {
  .goods {
    padding: 0.2rem;
    padding-bottom: .28rem;
    display: block;

    .img {
      position: relative;
      border-radius: .04rem;
      overflow: hidden;

      .label {
        position: absolute;
        right: 0;
        top: 0;
        background-image: linear-gradient(90deg, #2991ee 0%, #0174da 100%);
        border-radius: 0 .04rem 0 .04rem;
        line-height: calc(30 / 14 * 1em);
        padding: 0 .11rem;
      }
    }

    .tit {
      line-height: calc(30 / 16 * 1em);
      height: calc(30 / 16 * 1em * 2);
      margin-top: .05rem;
    }

    .price {
      margin-top: .15rem;
    }

    .sales {
      margin-top: .09rem;
    }

    &:hover {
      .tit {
        color: $blue;
      }
    }
  }
}</style>