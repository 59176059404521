<template>
  <div class="md-service-rich-list c-card flex-top">
    <div class="item" v-for="item in list" :key="item.id">
      <img :src="item.icon" alt="" class="icon">
      <div class="tit s18 color-2 font-medium">{{item.title}}</div>
      <div class="desc s16 color-6">{{item.desc}}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array, default() { return [] }
    }
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.md-service-rich-list{
  text-align: center;
  margin-top: .4rem;
  min-height: 2rem;
  .item{
    flex: 1;
    padding-top: .32rem; padding-bottom: .2rem;
  }
  .icon{
    height: .7rem;
  }
  .tit{
    line-height: calc(30 / 18 * 1em);
  }
  .desc{
    line-height: calc(30 / 16 * 1em);
    margin-top: .01rem;
  }
}
</style>