<template>
  <footer class="footer color-f dark">
    <!-- 广告 -->
    <div class="foot-1">
      <div class="base-width">
        <template v-for="item in list">
          <div class="ad flex-middle" :key="item.id">
            <div class="icon s22 font-light flex-center">{{ item.icon }}</div>
            <div class="text">
              <div class="tit s16">{{ item.title }}</div>
              <div class="desc s14 font-medium">{{ item.desc }}</div>
            </div>
          </div>
        </template>
      </div>
    </div>
    <!-- 导航、联系方式 -->
    <div class="foot-2">
      <div class="base-width">
        <!-- 导航 -->
        <div class="nav">
          <template v-for="item in nav">
            <dl :key="item.id" class="nav-item s16">
              <dt class="level-1 s18 font-medium">
                <router-link class="a-1" :to='item.link' :target='item.target' :title="item.title">
                  {{ item.title }}
                </router-link>
              </dt>
              <template v-for="child in item.children">
                <dd :key="child.id" class="level-2">
                  <router-link class="a-2" :to='child.link' :target='child.target' :title="child.title">
                    {{ child.title }}
                  </router-link>
                </dd>
              </template>
            </dl>
          </template>
        </div>
        <!-- 联系方式 -->
        <div class="contact">
          <div class="hd s18 font-medium">联系方式</div>
          <div class="bd s16">
            <div class="item">
              <span class="t1">公司：</span>
              <span class="t2">广东鼎易建筑科技有限公司</span>
            </div>
            <div class="item">
              <span class="t1">地址：</span>
              <span class="t2">广州市番禺区南村镇坑头工业区西线路48号A座东大堂401</span>
            </div>
            <div class="item">
              <span class="t1">客服热线：</span>
              <span class="t2">4008893388</span>
            </div>
          </div>
        </div>
        <!-- app二维码 -->
        <div class="code">
          <img src="@/assets/images/code.jpg" alt="" class="img">
          <div class="tit s14">扫描二维码<br>下载易筑APP</div>
        </div>
      </div>
    </div>
    <!-- 备案信息 -->
    <div class="foot-3 s14">
      <div class="base-width">
        <div class="copy"><a href="https://beian.miit.gov.cn" target="_blank">粤ICP备2020094292号</a></div>
        <div class="link">
          <a href="" target="_blank">网站建设</a>：<a href="" target="_blank">广东鼎易建筑科技有限公司</a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      list: [
        {
          title: '品类齐全 一站购齐',
          desc: '数万种SKU，建材全品类',
          icon: '全',
          id: 1
        },
        {
          title: '快速响应 售后无忧',
          desc: '24H配送  30天退换',
          icon: '快',
          id: 2
        },
        {
          title: '品质把控，源头供应',
          desc: '严选优材，源头把控',
          icon: '优',
          id: 3
        },
        {
          title: '账期采购  价格透明',
          desc: '降低融资成本，缓解资金压力',
          icon: '省',
          id: 4
        },
      ],
      nav: [
        {
          id: 1, target: '', title: '购物指南', link: '/',
          children: [
            { id: 11, target: '', title: '会员介绍', link: '/' },
            { id: 12, target: '', title: '常见问题', link: '/' },
            { id: 13, target: '', title: '流程介绍', link: '/' },
            { id: 14, target: '', title: '联系客服', link: '/' },
          ]
        },
        {
          id: 2, target: '', title: '支付方式', link: '/',
          children: [
            { id: 21, target: '', title: '账期集采', link: '/' },
            { id: 22, target: '', title: '对公转账', link: '/' },
            { id: 23, target: '', title: '在线支付', link: '/' },
          ]
        },
        {
          id: 3, target: '', title: '配送方式', link: '/',
          children: [
            { id: 31, target: '', title: '货拉拉', link: '/' },
            { id: 32, target: '', title: '德邦物流', link: '/' },
            { id: 33, target: '', title: '自有物流', link: '/' },
          ]
        },
        {
          id: 4, target: '', title: '特色服务', link: '/',
          children: [
            { id: 41, target: '', title: '招采大厅', link: '/' },
            { id: 42, target: '', title: '招投标', link: '/' },
            { id: 43, target: '', title: '免费发布需求', link: '/' },
            { id: 44, target: '', title: '工程保函', link: '/' },
          ]
        },
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.footer {
  background: #fff;

  a {
    &:hover {
      color: $green;
    }
  }

  .foot-1 {
    padding: .38rem 0 .4rem;

    .base-width {
      display: flex;
      justify-content: space-between;
    }

    .ad {
      .icon {
        width: .73rem;
        height: .73rem;
        background: url(~@/assets/images/icon-circle.png) no-repeat center / contain;
        color: $green;
        margin-right: .2rem;
      }

      .tit {
        color: #222;
      }

      .desc {
        margin-top: .08rem;
        color: #999;
      }
    }
  }

  .foot-2 {
    .base-width {
      min-height: 3.17rem;
      padding-top: .37rem;
      padding-bottom: .4rem;
      border-top: 1px solid #e6f2f8;
      display: flex;
    }

    .nav {
      margin-right: auto;
      display: flex;

      .nav-item {
        width: 2rem;
      }

      .level-1 {
        margin-bottom: .24rem;
        color: #222;
      }

      .level-2 {
        margin-bottom: .15rem;
        color: #666;
      }

      .a-1 {}

      .a-2 {}
    }

    .contact {
      width: 2.56rem;
      color: #222;

      .hd {
        margin-bottom: .22rem;
      }

      .bd {
        line-height: calc(36 / 16 * 1em);

        .item {
          display: flex;
        }

        .t1 {}

        .t2 {
          flex: 1;
        }
      }
    }

    .code {
      margin-left: .74rem;
      margin-top: 0.05rem;
      color: #999;

      .img {
        display: block;
        width: 1.2rem;
        margin: auto;
      }

      .tit {
        line-height: calc(24 / 14 * 1em);
        text-align: center;
        margin-top: .14rem;
      }
    }
  }

  .foot-3 {
    color: #999;

    .base-width {
      padding: 0.27rem 0 .35rem;
      border-top: 1px solid #e6f2f8;
      display: flex;
      justify-content: space-between;
    }

    .copy {}

    .link {}
  }

  &.dark {
    background: #37405e;
    color: #fff;

    a {
      &:hover {
        color: #fff;
      }
    }

    .foot-1 {
      background: url(~@/assets/images/foot-bg.jpg) no-repeat center / cover;

      .ad {
        .icon {
          background-image: url(~@/assets/images/icon-circle2.png);
          color: #fff;
        }

        .tit {
          color: #fff;
        }

        .desc {
          color: rgba($color: #fff, $alpha: .5);
        }
      }
    }

    .foot-2 {
      .base-width {
        border: none;
      }

      .nav {
        &-item {
          .level-1 {
            color: #fff;
          }

          .level-2 {
            color: rgba($color: #fff, $alpha: .5);
          }
        }
      }

      .contact {
        color: #fff
      }

      .code {
        color: #fff;
      }
    }

    .foot-3 {
      color: rgba($color: #fff, $alpha: .5);

      .base-width {
        border-top: 1px solid rgba($color: #e6f2f8, $alpha: .15);
      }
    }
  }
}</style>