<template>
  <BaseDialog
    :visible.sync="visible"
    title='新增收货地址'
    :cancel='false'
    confirmText='保存收货地址'
    @close="$emit('close')"
    @confirm='handleSubmit'
  >
    <form class="form s16 color-2">
      <!-- 收货人姓名 -->
      <div class="form-item">
        <label class="form-item_label required">
          <div><p class="p_label">收货人姓名</p></div>
        </label>
        <div class="form-item_content">
          <input type="text" class="ipt" placeholder="请输入收货人姓名" v-model="name">
        </div>
      </div>
      <!-- 联系人电话 -->
      <div class="form-item">
        <label class="form-item_label required">
          <div><p class="p_label">联系人电话</p></div>
        </label>
        <div class="form-item_content">
          <input type="text" class="ipt" placeholder="请输入联系人电话" v-model="phone">
        </div>
      </div>
      <!-- 身份证号码 -->
      <div class="form-item">
        <label class="form-item_label required">
          <div><p class="p_label">身份证号码</p></div>
        </label>
        <div class="form-item_content">
          <input type="text" class="ipt" placeholder="请输入身份证号码" v-model="idNumber">
        </div>
      </div>
      <!-- 身份证图片 -->
      <div class="form-item" style="margin-bottom: 0">
        <label class="form-item_label required">
          <div><p class="p_label">身份证图片</p></div>
        </label>
        <div class="form-item_content"></div>
      </div>
      <div class="idcard">
        <BaseIdCard type='front' v-model="idcard_1"></BaseIdCard>
        <BaseIdCard type='back' v-model="idcard_2"></BaseIdCard>
      </div>
    </form>
  </BaseDialog>
</template>

<script>
export default {
  data() {
    return {
      visible: true,
      name: '',
      phone: '',
      idNumber: '',
      idcard_1: '',
      idcard_2: '',
    };
  },
  methods: {
    handleSubmit() {
      this.$emit('close')
      this.$emit('update') // 添加成功，页面要重新加载地址列表
    }
  }
};
</script>

<style lang="scss" scoped>
  .form{
    padding: .3rem .3rem 0;
    &-item{
      display: flex; align-items: flex-start;
      margin-bottom: .2rem;
      &:last-of-type{
        margin-bottom: 0;
      }
      &_label{
        min-width: 1rem;
        min-height: .5rem;
        display: flex; align-items: center;
        line-height: 1.4em;
        position: relative;
        padding-right: .07rem;
        &.required {
          .p_label{
            position: relative;
            &:after{
              content: '*';
              color: #f53e39;
              position: absolute; right: -.5em; top: .02rem;
            }
          }
          .p_tips{
            text-align: center;
          }
        }
      }
      &_content{
        flex: 1;
      }
    }
    ::v-deep .ipt{
      width: 100%; height: .5rem;
      border-radius: .04rem;
      border: solid 1px #cae1ec;
      padding: 0 .14rem;
      transition: .3s;
      &::placeholder{
        color: #999;
      }
      &:focus{
        border-color: $blue;
      }
    }
    .c-id-card{
      width: 100%;
      margin-bottom: .2rem;
    }
  }
</style>