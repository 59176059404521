<template>
  <!-- 用户等级展示 -->
  <div class="c-level-wrap">
    <div class="level flex-middle">
      <img :src="item" v-for="(item, index) in hatArr" :key="index + 'a'">
      <img :src="item" v-for="(item, index) in starsArr" :key="index">
    </div>
  </div>
</template>

<script>
export default {
  props: {
    hatArr: {
      type: Array,
      default: () => []
    },
    starsArr: {
      type: Array,
      default: () => []
    },
    type: {
      type: Number,
      default: 1
    },
    num: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {};
  },
  computed: {
    starImg() {
      switch (this.type) {
        case 1: return require('@/assets/images/icon-star-yellow.png');
        case 2: return require('@/assets/images/icon-star-red.png');
        case 3: return require('@/assets/images/icon-star-blue.png');
        case 4: return require('@/assets/images/icon-star-white.png');
        default: return '';
      }
    },
    hatImg() {
      switch (this.type) {
        case 1: return require('@/assets/images/hat-yellow.png');
        case 2: return require('@/assets/images/hat-red.png');
        case 3: return require('@/assets/images/hat-blue.png');
        case 4: return require('@/assets/images/hat-white.png');
        default: return '';
      }
    },
  },
  methods: {
  }
};
</script>

<style lang="scss" scoped>
.c-level-wrap {
  height: 0.24rem;
  display: flex;

  .level {
    background-color: #ecf5fa;
    line-height: .24rem;
    border-radius: .24rem;
    flex: 1;
    padding: 0 .05rem;
    justify-content: center;

    img {
      height: .13rem;
      margin-right: .02rem;

      &:last-of-type {
        margin-right: 0;
      }
    }

    @media (max-width: 1810px) {
      padding: 0 .06rem;

      img {
        margin-right: .02rem;
      }
    }
  }
}
</style>