<template>
  <div class="menu text-center c-card">
    <img src="@/assets/images/3.png" alt="" class="img">
    <div class="text s22 color-f font-zihun">
      立即认证<br>
      工程管理身份
    </div>
    <router-link class="btn s16 color-blue" to="/join">查看认证</router-link>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.menu{
  height: 4.96rem;
  background: #fff url(~@/assets/images/2.jpg) no-repeat center / cover;
  padding-top: 1.16rem;
  .img{
    width: .82rem;
  }
  .text{
    line-height: calc(30 / 22 * 1em);
    margin-top: .19rem;
  }
  .btn{
    display: inline-block;
    line-height: calc(50 / 16 * 1em);
    border-radius: .04rem;
    margin-top: .36rem;
    background: #fff;
    min-width: 1.13rem;
    padding: 0 1em;
    transition: .3s;
    &:hover{
      transform: translateY(.02rem);
    }
  }
}
</style>