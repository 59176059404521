<template>
  <component
    :is="tag"
    :to='to'
    class="base-button"
    :class="{
      's14': size == 'xs' || size == 'mini',
      's16': size == 'normal' || size == 'large',
      ['base-button--' + color]: true,
      ['base-button--' + size]: true,
      'is-border': styleType == 'border',
      'is-light': styleType == 'light',
      'no-round': !round,
      'hover': hover === true,
      ['hover-' + hover]: hoverColorValided,
    }"
    @click="$emit('click', $event)"
  >
    <slot />
    <template v-if="icon">
      <span class="icon-right"></span>
    </template>
  </component>
</template>

<script>
export default {
  props: {
    color: { // 颜色类型
      type: String,
      default: 'blue', // blue | green | red | brown | gray
    },
    styleType: { // 样式类型
      type: String,
      default: 'main', // main-纯色背景 | border-边框 | light-浅色背景
    },
    round: { // 4px圆角
      type: Boolean,
      default: true
    },
    tag: { // 标签类型
      type: String,
      default: 'div', // div | button | router-link | a
    },
    to: { // 链接
      type: String,
      default: '',
    },
    size: { // 尺寸、高度
      type: String,
      default: 'normal', // xs-20 | mini-30 | normal-40 | large-50
    },
    hover: { // 指定悬浮色
      type: [String, Boolean],
      default: true
    },
    icon: {
      type: String,
      default: '', // right-右箭头
    },
    // hoverParent: {
    //   type: String,
    //   default: ''
    // }
  },
  data() {
    return {
      colorList: ['blue', 'green', 'red', 'brown']
    };
  },
  computed: {
    hoverColorValided() {
      return this.colorList.includes(this.hover)
    }
  },
  methods: {
    // handleMove(event) {
    //   let list = [...event.target.classList]
    //   if (
    //     event.target.contains(this.$el) &&
    //     list.includes(this.hoverParent)
    //   ) {
    //     this.$el.classList.add('hover-parent')
    //     event.target.addEventListener('mouseleave', () => {
    //       this.$el.classList.remove('hover-parent')
    //     })
    //   }
    // }
  },
  mounted() {
    // if (this.hoverParent) {
    //   document.body.addEventListener('mousemove', this.handleMove)
    //   document.body.addEventListener('scroll', this.handleMove)
    // }
  },
  beforeDestroy() {
    // if (this.hoverParent) {
    //   document.body.removeEventListener('mousemove', this.handleMove)
    // }
  }
};
</script>

<style lang="scss" scoped>
@mixin btn($colorName, $color, $colorLight, $colorDeep) {
  &--#{$colorName}{
    background-color: $color; color: #fff;
    &.is-light,
    &.is-border{
      color: $color;
      .icon-right{
        background-image: url(~@/assets/images/icon-right-#{$colorName}.png);
      }
    }
    &.is-light {
      background-color: $colorLight;
    }
    &.is-border{
      background-color: transparent;
      border: 1px solid $color;
    }
    // 正常悬浮
    &.hover{
      &:hover,
      &.hover-parent{
        background-color: $colorDeep;
        &.is-light,
        &.is-border{
          @include buttonHover($color);
        }
      }
    }
  }
  // 指定悬浮色
  &.hover-#{$colorName}{
    &:hover,
    &.hover-parent{
      @include buttonHover($color);
    }
  }
}
.base-button{
  display: inline-flex; align-items: center; justify-content: center;
  padding: 0 1em;
  // margin: .05rem;
  transition: color .2s, background .2s;
  cursor: pointer;
  border-radius: .04rem;
  border: none;
  &--xs{
    line-height: calc(20 / 14 * 1em);
    &.is-border{ line-height: calc(18 / 14 * 1em); }
  }
  &--mini{
    line-height: calc(30 / 14 * 1em);
    &.is-border{ line-height: calc(28 / 14 * 1em); }
  }
  &--normal{
    line-height: calc(40 / 16 * 1em);
    &.is-border{ line-height: calc(38 / 16 * 1em); }
  }
  &--large{
    line-height: calc(50 / 16 * 1em);
    &.is-border{ line-height: calc(48 / 16 * 1em); }
  }
  &.no-round{
    border-radius: 0
  }
  .icon-right{
    width: .06rem; height: .1rem;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-image: url(~@/assets/images/icon-right4.png);
    margin-left: .1rem;
  }
  @include btn('blue', $blue, $blueLight, $blueDeep);
  @include btn('green', $green, $greenLight, $greenDeep);
  @include btn('red', $red, $redLight, $redDeep);
  @include btn('brown', $brown, $brownLight, $brownDeep);
  @include btn('gray', #999, #aaa, #666);
}
</style>