<template>
  <div class="cate-list c-card">
    <template v-for="(item, index) in listArr">
      <div class="cate-item" :key="index">
        <div class="cate-1 s18 color-2 font-medium">
          <router-link class="a1" :to="item.url" :target="item.target" :title="item.title">
            {{ item.title }}
          </router-link>
        </div>
        <div class="cate-2 s16 color-6">
          <template v-for="(child, index) in item.data">
            <router-link class="a2" :key="index" :to="`${item.url}${child.officia_url}?cat_id=${child.cat_id}&cat_name=${child.cat_name}`"
              :target="child.target" :title="child.cat_name">
              {{ child.cat_name }}
            </router-link>
          </template>
        </div>
        <div class="pop">
          <div class="pop-cnt">
            <div class="lf">
              <div class="tit s22 color-2 font-medium">{{ item.title }}</div>
              <div class="desc s14 color-9 font-light ep3">
                {{ item.msg }}
              </div>
              <router-link class="more flex-middle color-blue s14" :to="item.url">
                <span>进入{{ item.title }}</span>
                <img src="@/assets/images/icon-right3.png" class="icon">
              </router-link>
            </div>
            <img src="@/assets/images/img-cate.png" class="img">
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    listArr: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      // 分类
      list: [
        {
          id: 1, target: '', link: '/shop', title: '易筑集采',
          img: require('@/assets/images/img-cate.png'),
          desc: '智慧工地是智慧地球理念在工程领域的行业具现，是一种崭新的工程全生命周期管理理念。智慧工地是指运用信息化手段。',
          children: [
            { id: 11, target: '', link: '/shop', title: '土建' },
            { id: 12, target: '', link: '/shop', title: '市政' },
            { id: 13, target: '', link: '/shop', title: '装饰' },
            { id: 14, target: '', link: '/shop', title: '园林' },
            { id: 15, target: '', link: '/shop', title: '暖通' },
            { id: 16, target: '', link: '/shop', title: '给排水' },
            { id: 17, target: '', link: '/shop', title: '二手建材' },
            { id: 18, target: '', link: '/shop', title: '其他' },
          ]
        },
        {
          id: 2, target: '', link: '/construction', title: '智慧工地',
          img: require('@/assets/images/img-cate.png'),
          desc: '智慧工地是智慧地球理念在工程领域的行业具现，是一种崭新的工程全生命周期管理理念。智慧工地是指运用信息化手段。',
          children: [
            { id: 21, target: '', link: '/construction', title: '房屋建筑' },
            { id: 22, target: '', link: '/construction', title: '房屋建筑' },
            { id: 23, target: '', link: '/construction', title: '水利水务' },
            { id: 24, target: '', link: '/construction', title: '装饰装修' },
            { id: 25, target: '', link: '/construction', title: '园林绿化' },
          ]
        },
        {
          id: 3, target: '', link: '/service', title: '配套服务',
          img: require('@/assets/images/img-cate.png'),
          desc: '智慧工地是智慧地球理念在工程领域的行业具现，是一种崭新的工程全生命周期管理理念。智慧工地是指运用信息化手段。',
          children: [
            { id: 31, target: '', link: '/service', title: '工程资料' },
            { id: 32, target: '', link: '/service', title: '工程保函' },
            { id: 33, target: '', link: '/service', title: '工程广告' },
            { id: 34, target: '', link: '/service', title: '工程造价' },
            { id: 35, target: '', link: '/service', title: '工程财税' },
            { id: 36, target: '', link: '/service', title: '材料送检' },
          ]
        },
        {
          id: 4, target: '', link: '/news', title: '行业资讯',
          img: require('@/assets/images/img-cate.png'),
          desc: '智慧工地是智慧地球理念在工程领域的行业具现，是一种崭新的工程全生命周期管理理念。智慧工地是指运用信息化手段。',
          children: [
            { id: 41, target: '', link: '/news', title: '行业新闻' },
            { id: 42, target: '', link: '/news', title: '选材百科' },
            { id: 43, target: '', link: '/news', title: '施工干货' },
            { id: 44, target: '', link: '/news', title: '工程财税' },
            { id: 45, target: '', link: '/news', title: '工程造价' },
            { id: 46, target: '', link: '/news', title: '工程法务' },

          ]
        },
      ],
    }
  }
};
</script>

<style lang="scss" scoped>
.cate-list {
  padding: .3rem;
  position: relative;
  z-index: 2;

  .cate-item {
    border-radius: .04rem;
    padding: .26rem 0 .16rem .2rem;
    margin-bottom: .02rem;
    position: relative;

    &:last-of-type {
      margin-bottom: 0;
    }

    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      border-radius: .04rem;
      border: 1px solid;
      display: none;
    }

    &:nth-of-type(4n+1) {
      @include lg-blue;

      &:after {
        border-color: $blue;
      }

      &:hover {
        .cate-1 {
          color: $blue;
        }
      }

      .cate-2 .a2:hover {
        color: $blue;
      }
    }

    &:nth-of-type(4n+2) {
      @include lg-red;

      &:after {
        border-color: $red;
      }

      &:hover {
        .cate-1 {
          color: $red;
        }
      }

      .cate-2 .a2:hover {
        color: $red;
      }
    }

    &:nth-of-type(4n+3) {
      @include lg-brown;

      &:after {
        border-color: $brown;
      }

      &:hover {
        .cate-1 {
          color: $brown;
        }
      }

      .cate-2 .a2:hover {
        color: $brown;
      }
    }

    &:nth-of-type(4n+4) {
      @include lg-green;

      &:after {
        border-color: $green;
      }

      &:hover {
        .cate-1 {
          color: $green;
        }
      }

      .cate-2 .a2:hover {
        color: $green;
      }
    }

    &:hover {
      &:after {
        display: block;
      }

      .pop {
        display: block;
      }
    }
  }

  .cate-1 {
    margin-bottom: .13rem;
    position: relative;
    z-index: 2;
  }

  .cate-2 {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    z-index: 2;

    .a2 {
      min-width: 4em;
      margin-bottom: 0.09rem;
      margin-right: .17rem;

      @media (max-width: 1500px) {
        margin-right: .1rem;
      }
    }
  }

  .pop {
    position: absolute;
    left: 100%;
    top: 0;
    z-index: 100;
    padding-left: .23rem;
    filter: drop-shadow(0 0 .3rem rgba(219, 236, 255, 0.8));
    display: none;

    &:before {
      content: '';
      position: absolute;
      left: .03rem;
      top: .6rem;
      border: .1rem solid transparent;
      border-right-color: #fff;
    }

    &-cnt {
      background: #fff;
      border-radius: .04rem;
      overflow: hidden;
      position: relative;
      width: 4.9rem;
      height: 2rem;
      display: flex;
      align-items: center;
    }

    .lf {
      padding: .25rem .3rem;
      padding-right: 0;
      flex: 1;
    }

    .img {
      width: 1.71rem;
      margin-top: .13rem;
    }

    .desc {
      line-height: calc(24 / 14 * 1em);
      margin-top: .09rem;
    }

    .more {
      margin-top: .21rem;

      &:hover {
        text-decoration: underline;
      }

      .icon {
        margin-left: 0.09rem;
      }
    }
  }
}
</style>