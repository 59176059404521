<template>
  <!-- 支付方式选择 -->
  <div class="pay-way flex-center">
    <!-- 微信 -->
    <div class="item flex-middle item-1" :class="{ on: value == 1 }" @click="handleClick(1)">
      <div class="icon"></div>
      <img src="@/assets/images/icon-wechatpay.png" class="img">
    </div>
    <!-- 支付宝 -->
    <div class="item flex-middle item-2" :class="{ on: value == 2 }" @click="handleClick(2)">
      <div class="icon"></div>
      <img src="@/assets/images/icon-zhifubao.png" class="img">
    </div>
    <!-- 线下 -->
    <div class="item flex-middle item-3" :class="{ on: value == 3 }" @click="handleClick(3)">
      <div class="icon"></div>
      <div class="text">
        <span class="s22 font-medium color-blue">线下支付</span>
        <img src="@/assets/images/icon-113.png" class="img">
        <div class="pop">
          <div class="pop-cnt c-card s14 color-9">
            账期支付：为保证三流合一，建议选择“线下支付”采用企业对公支付
          </div>
        </div>
      </div>
      <div class="p1 color-blue font-light">推荐</div>
    </div>
    <!-- 余额 -->
    <div class="item flex-middle item-4" :class="{ on: value == 4 }" @click="handleClick(4)">
      <div class="icon"></div>
      <div class="text">
        <div class="s22 p1 font-medium color-blue">余额支付</div>
        <div class="s16 p2 color-4">余额¥{{ user_money }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: '',
    user_money: { type: String, default: "" }
  },
  data() {
    return {};
  },
  methods: {
    handleClick(val) {
      this.$emit('input', val)
    }
  }
};
</script>

<style lang="scss" scoped>
.pay-way {
  margin-top: .29rem;

  .item {
    width: 2.5rem;
    height: 1rem;
    border-radius: 0.04rem;
    border: solid 0.01rem #cae1ec;
    cursor: pointer;
    padding-left: .3rem;
    margin: 0 .1rem;

    &.on {
      border-color: #0174da;

      .icon {
        background: url(~@/assets/images/icon-119.png) no-repeat center / contain;
        border: none;
      }
    }

    &-1 {
      .img {
        width: 1.28rem;
        margin-left: .26rem;
      }
    }

    &-2 {
      .img {
        width: 1.22rem;
        margin-left: .28rem;
      }
    }

    &-3 {
      position: relative;

      &:after {
        content: '';
        position: absolute;
        right: -.35rem;
        top: -.35rem;
        z-index: 1;
        border: .35rem solid transparent;
        border-left-color: $blueLight;
        transform: rotate(-45deg);
      }

      .p1 {
        position: absolute;
        right: 0;
        top: .06rem;
        z-index: 2;
        padding: 0 .02rem;
        font-size: 12px;
      }

      .text {
        position: relative;
        margin-left: .38rem;

        .img {
          width: .14rem;
          position: absolute;
          left: 100%;
          top: -.01rem;
        }

        .pop {
          position: absolute;
          left: .63rem;
          bottom: 100%;
          z-index: 3;
          width: 2.5rem;
          padding-bottom: .27rem;
          display: none;

          &-cnt {
            position: relative;
            filter: drop-shadow(0 0 0.3rem rgba(219, 236, 255, 0.8));
            padding: .14rem .2rem;
            line-height: calc(24 / 14 * 1em);

            &:before {
              content: '';
              position: absolute;
              left: .25rem;
              bottom: -.2rem;
              border: .1rem solid transparent;
              border-top-color: #fff;
            }
          }
        }

        &:hover {
          .pop {
            display: block;
          }
        }
      }
    }

    &-4 {
      .icon {
        margin-right: .38rem;
      }

      .p1 {
        margin-top: .09rem;
      }

      .p2 {
        margin-top: .03rem;
      }
    }
  }

  .icon {
    width: .22rem;
    height: .22rem;
    border-radius: 50%;
    border: solid 0.01rem #cae1ec;
  }
}
</style>