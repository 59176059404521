// 用户模块
import request from '@/request/request'

// 首页用户数据
export const userInfoApi = (params) => request.post('/pc/user/userInfo', params);

//获取项目类型
export const industryTypeApi = (params) => request.post('/pc/industry/industry_type', params);

//发布需求
export const freeDemandAddApi = (params) => request.post('/pc/industry/free_demand_add', params);


// 首页基础数据
export const IndexApi = (params) => request.post('/pc/index/base', params);
// 首页轮播
export const IndexBannerApi = (params) => request.post('/pc/index/banner', params);
// 首页榜单数据
export const IndexBillboardApi = (params) => request.post('/pc/index/billboard', params);
// 榜单分类
export const IndexBiddingCatListApi = (params) => request.post('/pc/bidding.bidding/cat_list', params);






/* 入驻管理 */
// 入职-提交
export const SettledSubmitApi = (params) => request.post('/pc/settled/submit', params);
// 预设
export const SettledReadyApi = (params) => request.post('/pc/settled/ready', params);
// 入驻检测
export const SettledCheckApi = (params) => request.post('/pc/settled/server_check', params);
// 采购需求分类
export const SettledGoodsCategoryApi = (params) => request.post('/pc/settled/goods_category', params);




/* 银行管理 */
export const BankListApi = (params) => request.post('/pc/bank/bank_list', params);
