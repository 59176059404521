<template>
  <div class="md-service-download">
    <!-- 标题 -->
    <div class="c-title-2 s22" v-ani.fade-up>{{ title }}</div>

    <div class="content c-card" v-ani.fade-up>

      <!-- 分类 -->
      <div class="cate s16" v-if="cate.length > 0">
        <template v-for="item in cate">
          <div class="cate-item" :key="item.cat_id" :class="{ on: currentCate == item.cat_id }" @click="cateClick(item)">
            {{ item.title }}
          </div>
        </template>
      </div>

      <!-- 下载列表 -->
      <div class='list'>
        <template v-for="item in destList">
          <div class='li flex-middle' :key="item.id">
            <ImgRatio :src='item.image' :width='140' :height='105'></ImgRatio>
            <div class='text'>
              <div class='tit s18 color-2 font-medium'>{{ item.title }}</div>
              <div class="date s16 color-6 flex-middle">
                <img src="@/assets/images/icon-date.png">
                <span>{{ item.date | dateFormat('yyyy/MM/dd') }}</span>
              </div>
            </div>
            <router-link class="down" to="" download>
              <img src="@/assets/images/icon-down5.png" @click="downClick(item)">
            </router-link>
          </div>
        </template>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: { type: String, default: '' },
    list: { type: Array, default() { return [] } },
    cate: { type: Array, default() { return [] } },
  },
  data() {
    return {
      mockList: [
        {
          id: 1,
          img: require('@/assets/images/svc11_1.jpg'),
          title: '知名企业期主体配套工程钢筋施工方案',
          date: '2023/12/05'
        }, {
          id: 2,
          img: require('@/assets/images/svc11_2.jpg'),
          title: '知名企业期主体配套工程钢筋施工方案',
          date: '2023/12/05'
        }, {
          id: 3,
          img: require('@/assets/images/svc11_3.jpg'),
          title: '知名企业期主体配套工程钢筋施工方案',
          date: '2023/12/05'
        }, {
          id: 4,
          img: require('@/assets/images/svc11_4.jpg'),
          title: '知名企业期主体配套工程钢筋施工方案',
          date: '2023/12/05'
        }
      ],
      currentCate: ''
    };
  },
  computed: {
    destList() {
      return this.list.length > 0 ? this.list : this.mockList
    }
  },
  methods: {
    cateClick(item) {
      this.currentCate = item.cat_id;
      this.$emit('change', item.cat_id);
    },
    downClick(item) {
      let image = new Image();
      // 解决跨域 Canvas 污染问题
      image.setAttribute("crossOrigin", "anonymous");
      image.onload = function () {
        let canvas = document.createElement("canvas");
        canvas.width = image.width;
        canvas.height = image.height;
        let context = canvas.getContext("2d");
        context?.drawImage(image, 0, 0, image.width, image.height);
        let url = canvas.toDataURL(`image/png`); //得到图片的base64编码数据
        let a = document.createElement("a"); // 生成一个a元素
        let event = new MouseEvent("click"); // 创建一个单击事件
        a.download = '图片' || "pic"; // 设置图片名称
        a.href = url; // 将生成的URL设置为a.href属性
        a.dispatchEvent(event); // 触发a的单击事件
      }
      //将资源链接赋值过去，才能触发image.onload 事件
      image.src = item.download_url
    }
  }
};
</script>

<style lang="scss" scoped>
.md-service-download {
  margin-top: .35rem;

  .content {
    padding: .3rem;
    margin-top: .15rem;
  }

  .list {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -.2rem;

    .li {
      width: calc(636 / 1292 * 100%);
      padding: .19rem;
      margin-right: calc(20 / 1292 * 100%);
      margin-bottom: .2rem;
      border: solid 1px #e6f2f8;
      border-radius: .04rem;

      &:nth-of-type(2n) {
        margin-right: 0;
      }

      &:hover {
        background: #ecf5fa;

        ::v-deep .img {
          transform: scale(1.1);
        }
      }
    }

    .c-pic {
      width: 1.4rem;
      border-radius: .04rem 0 0 .04rem;
      margin-right: .3rem;
    }

    .text {
      flex: 1;
      margin-right: .2rem;
    }

    .date {
      margin-top: .13rem;
    }

    .down {
      font-size: 0;

      img {
        width: 0.5rem;
      }
    }
  }

  .cate {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: .1rem;

    &-item {
      padding: 0 .13rem;
      color: $blue;
      border: 1px solid $blue;
      border-radius: .04rem;
      margin-right: .1rem;
      margin-bottom: .1rem;
      line-height: .3rem;
      cursor: pointer;
      transition: .3s;

      &:hover,
      &.on {
        background: $blue;
        color: #fff;
      }
    }
  }
}
</style>