let ob = new IntersectionObserver(fn)
function fn(entries, observer) {
    for (var i = 0; i < entries.length; i++) {
        var entry = entries[i];
        if (entry.isIntersecting) {
            entry.target.myCallback()
            ob.unobserve(entry.target)
        }
    }
}
// ie>=9
const Bound = {
    list: [],
    push(el) {
        this.list.push(el)
    },
    isInViewPort(el) {
        // var viewWidth = document.documentElement.clientWidth || document.body.clientWidth;
        var viewHeight = document.documentElement.clientHeight || document.body.clientHeight;
        var rect = el.getBoundingClientRect();
        // 有一点点进入到视口就返回true
        return rect.bottom >= 0 && rect.top <= viewHeight
    },
    observe() {
        console.log(this.list);
        this.list.forEach((el, i) => {
            if (this.isInViewPort(el)) {
                el.myCallback()
                this.list.splice(i, 1)
            }
        })
    },
    initEvent() {
        document.addEventListener('scroll', () => {
            this.observe()
        })
    }
}
function monitor(el) {
    if (window.IntersectionObserver) {
        monitor = function(el) {
            ob.observe(el)
        }
    } else if (el.getBoundingClientRect) {
        Bound.initEvent()
        monitor = function (el) {
            Bound.push(el)
        }
    }
    monitor(el)
}
export default {
    key: 'ani',
    demo: 'v-ani="fade-up"',
    func: {
        bind(el, binding, vnode) {
            // console.log('bind');
            let str = Object.keys(binding.modifiers)[0]
            el.setAttribute('data-ani', str)
            el.myCallback = function() {
                el.classList.add('ani')
                setTimeout(function(){
                    el.removeAttribute('data-ani')
                }, 1000)
            }
            monitor(el)
        },
        inserted() {
            // console.log('inserted');
        },
        update() {
            // console.log('update');
        },
        componentUpdated() {
            // console.log('componentUpdated');
        },
        unbind(el){

        }
    }
}