/**
 * 数字最大限制到99+
 * @param {Number} params 数字
 * @returns 
 */
export function numMax(params) {
  return params > 99 ? '99+' : params
}
/**
 * 字符补齐
 * @param {*} params 字符
 * @param {Number} len 长度 默认2
 * @param {String} symbol 补位符号 默认'0'
 * @returns 
 */
export function numPad(params, len, symbol) {
  len = len || 2;
  symbol = symbol || '0'
  return String(params).padStart(len, symbol)
}
/**
 * 深度克隆
 * @param {Object|Array} obj 
 * @returns {Object|Array}
 */
export function deepClone (obj) {
  if (obj === null || typeof obj !== 'object') { 
      return obj; 
  }
  let clone = Array.isArray(obj) ? [] : {}; 
  for (let key in obj) { 
      if (obj.hasOwnProperty(key)) { 
          clone[key] = deepClone(obj[key]); 
      } 
  }
  return clone; 
}
/**
 * 返回一个时间戳的信息
 * @param {String} data 日期
 * @returns 
 */
function getDateData(data) {
  var res = {
    year: '',
    month: '',
    date: '',
    hours: '',
    minutes: '',
    seconds: '',
    valid: false
  }
  if (!data) { return res }
  if (/^\d+$/.test(data)) { // 纯数字、即时间戳
    if (String(data).length === 10) { // 秒，转成毫秒
      data *= 1000
    }
  }
  var _date = new Date(data);
  if (_date.toString() === 'Invalid Date') { // 错误数据
    return res
  }
  res.year = _date.getFullYear();
  res.month = _date.getMonth() + 1;
  res.date = _date.getDate();
  res.hours = _date.getHours();
  res.minutes = _date.getMinutes();
  res.seconds = _date.getSeconds();
  res.valid = true
  return res
}
/**
 * @param {String} dateStr 要格式化的日期
 * @param {String} format 格式化模板
 * @returns 
 * @example yyyy-MM-dd HH:mm:ss          2017-08-18 20:08:08
*/
export function dateFormat (dateStr, format) {
  // 例子
  // 格式                         示例值
  // yyyy-MM-dd HH:mm:ss          2017-08-18 20:08:08
  // yyyy年MM月dd日 HH时mm分ss秒   2017年08月18日 20时08分08秒
  // yyyyMMdd                     20170818
  // dd/MM/yyyy                   18/08/2017
  // yyyy年M月                    2017年8月
  // M月d日                       8月18日
  // 北京时间：HH点mm分            北京时间：20点08分
  // yyyy年的M月某天晚上，大概H点   2017年的8月某天晚上，大概20点
  var _date = getDateData(dateStr)
  if (!_date.valid) {
    return dateStr
  }
  format = format || 'yyyy-MM-dd HH:mm:ss'; // 默认格式
  var dateType = 'yyyy|y|MM|M|dd|d|HH|H|mm|m|ss|s';
  // 将日期格式字符转换为数组
  function formatArr(format) {
    return (format || '').match(new RegExp(dateType + '|.', 'g')) || []
  }
  var arr = formatArr(format);
  var config = {
    year: 'yyyy|y',
    month: 'MM|M',
    date: 'dd|d',
    hours: 'HH|H',
    minutes: 'mm|m',
    seconds: 'ss|s'
  }
  for (let i = 0; i < arr.length; i++) {
    var item = arr[i]
    var len = item.length
    for (const key in config) {
      if (Object.hasOwnProperty.call(config, key)) {
        const val = config[key]
        var reg = new RegExp(val)
        if (reg.test(item)) {
          arr[i] = numPad(_date[key], len)
        }
      }
    }
  }
  return arr.join('')
};
/**
 * 姓名隐藏中间字符
 * @param {String} str 字符
 * @returns 
 */
export function nameFormat(str) {
  if(str === undefined || str === null) {
    return str
  }
  str = str.toString()
  let res = str[0] + '**'
  if (str.length > 3) {
    res += str[3]
  }
  return res
}
/**
 * @param {String} dateStr 截止日期
 * @returns 倒计时信息
 */
export function getCountDownObj(dateStr) {
  var day = 0;
  var hours = 0;
  var minutes = 0;
  var seconds = 0;
  try {
    seconds = Math.floor((new Date(dateStr).getTime() - Date.now()) / 1000)
  } catch (error) {
    seconds = 0
  }
  if (seconds > 60 * 60 * 24) {
    day = Math.floor(seconds / (60 * 60 * 24))
    seconds -= day * 60 * 60 * 24
  }
  if (seconds > 60 * 60) {
    hours = Math.floor(seconds / (60 * 60))
    seconds -= hours * 60 * 60
  }
  if (seconds > 60) {
    minutes = Math.floor(seconds / 60)
    seconds -= minutes * 60
  }
  return {
    day: numPad(day, 2),
    hours: numPad(hours, 2),
    minutes: numPad(minutes, 2),
    seconds: numPad(seconds, 2),
  }
}

export function priceFormat(val) {
  if (isNaN(val)) {
    return val
  } else {
    let res = Number(val).toFixed(2)
    return res
  }
}

//处理累计金额
export const dealMoneyCountfun = (numStr) => {
  let outerArr = [];
  let innerArr = [];

  numStr.substr(-3).split("").forEach(item => {
  innerArr.push(item)
  })
  innerArr.length > 0 ? outerArr.unshift(innerArr) : "";

  if(numStr.length > 3 && numStr.length <= 6){
    innerArr = [];
    numStr.substr(0, numStr.length - 3).split("").forEach(item => {
      innerArr.push(item)
    })
    innerArr.length > 0 ? outerArr.unshift(innerArr) : "";
  }
  
  if(numStr.length > 6 && numStr.length <= 9){
    innerArr = [];
    numStr.substr(-6, 3).split("").forEach(item => {
      innerArr.push(item)
    })
    innerArr.length > 0 ? outerArr.unshift(innerArr) : "";

    innerArr = [];
    numStr.substr(0, numStr.length - 6).split("").forEach(item => {
      innerArr.push(item)
    })
    innerArr.length > 0 ? outerArr.unshift(innerArr) : "";
  }

  if(numStr.length > 9 && numStr.length <= 12){
    innerArr = [];
    numStr.substr(-6, 3).split("").forEach(item => {
      innerArr.push(item)
    })
    innerArr.length > 0 ? outerArr.unshift(innerArr) : "";

    innerArr = [];
    numStr.substr(-9, 3).split("").forEach(item => {
      innerArr.push(item)
    })
    innerArr.length > 0 ? outerArr.unshift(innerArr) : "";

    innerArr = [];
    numStr.substr(0, numStr.length - 9).split("").forEach(item => {
      innerArr.push(item)
    })
    innerArr.length > 0 ? outerArr.unshift(innerArr) : "";
  }

  return outerArr
}