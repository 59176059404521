<template>
  <!-- 复选框列表 -->
  <div class="c-checkbox_group flex-middle color-6">
    <template v-for="item in options">
      <label class="c-checkbox flex-middle" :key="item.value">
        <input type="checkbox" hidden :name="name" :value='item.value' :checked='isChecked(item.value)'
          @change="handleChange(item)">
        <div class="c-checkbox_icon"></div>
        <span class="c-checkbox_name">{{ item.name }}</span>
      </label>
    </template>
  </div>
</template>

<script>
import { deepClone } from '@/utils'
export default {
  props: {
    options: { type: Array, default() { return [] } },
    value: '',
    name: '',
  },
  data() {
    return {
      list: [],
      checkArr: [],
    };
  },
  created() {
    // this.checkArr = this.options;
    if (Array.isArray(this.value)) {
      this.list = deepClone(this.value);
    }
  },
  methods: {
    isChecked(val) {
      return this.list.findIndex(el => el == val) != -1
    },
    handleChange(item) {
      let idx = this.list.findIndex(el => el == item.value);
      if (idx == -1) {
        this.list.push(item.value);
        this.checkArr.push(item)
      } else {
        this.list.splice(idx, 1)
        this.checkArr.splice(idx, 1)
      }
      this.list.sort()
      this.$emit('input', this.list);
      this.$emit('change', this.list); // key值集合
      this.$emit('checkFn', this.checkArr);
    }
  }
};
</script>

<style lang="scss" scoped>
.c-checkbox_group {
  flex-wrap: wrap;
}

.c-checkbox {
  // margin: 0 .3rem .13rem 0;
  margin: .068rem .3rem .068rem 0;
  cursor: pointer;

  &:last-of-type {
    margin-right: 0;
  }

  &_icon {
    margin-right: .07rem;
    width: .16rem;
    height: .16rem;
    // min-width: 14px; min-height: 14px;
    background: url(~@/assets/images/icon-104.png) no-repeat center / contain;
  }

  input:checked+&_icon {
    background-image: url(~@/assets/images/icon-103.png);
  }

  input:checked~&_name {
    color: $blue;
  }
}
</style>