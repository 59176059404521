<template>
  <div class="item" :class="{ open: !collapse || isOpen }">
    <div class="t1 s16 color-9 font-bold">{{ title }}：</div>
    <div class="list s18 color-4 font-medium" ref='list'>
      <template v-for="(el, index) in listArr">
        <div class="opt" ref="item" :key="index" >
          <div class="li" :class="{ on: valIndex == index }" @click="handleChoose(index, el)"  v-if="type=='Bid'">
            {{ el.cat_name }}
          </div>
          <div class="li" :class="{ on: valIndex == index }" @click="handleChoose(index, el)"  v-if="type=='bid'">
            {{ el.title }}
          </div>
        </div>
      </template>
    </div>
    <div v-if="showMore && collapse" class="s18 color-blue font-medium btn-toggle flex-middle pointer"
      @click="isOpen = !isOpen">
      <span>{{ isOpen ? '收起' : '展开' }}</span>
      <img src="@/assets/images/icon-down2.png" alt="">
    </div>
  </div>
</template>

<script>
export default {
  props: {
    collapse: {
      type: Boolean,
      default: true
    },
    listArr: {
      type: Array,
      default() {
        return []
      }
    },
    title: {
      type: String,
      default: ""
    },
    showMore: {
      type: Boolean,
      default: false,
    },
    typeNum: {
      type: Number,
      default: 0,
    },
    type: {
      type: String,
      default: ""
    },
  },
  data() {
    return {
      // showMore: true,
      isOpen: false,
      valIndex: 0
    };
  },
  mounted() {
    if (this.collapse && Array.isArray(this.$refs.item)) {
      let liWidth = 0
      this.$refs.item.forEach(el => {
        liWidth += el.clientWidth
      })
      let containerWidth = this.$refs.list.clientWidth
      if (liWidth > containerWidth) {
        this.showMore = true
      }
    }
  },
  methods: {
    handleChoose(index, el) {
      this.valIndex = index;
      this.$emit('change', { val: el, typeNum: this.typeNum });
    }
  }
};
</script>

<style lang="scss" scoped>
.item {
  display: flex;
  align-items: flex-start;
  padding: .1rem 0;
  border-bottom: dashed 1px #e6f2f8;

  &:last-of-type {
    border: none;
  }

  .t1 {
    width: 5.45em;
    text-align: right;
    line-height: .3rem;
    margin: .05rem 0;
  }

  .list {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    margin-left: 0.18rem;
    height: .4rem;
    overflow: hidden;

    .opt {
      padding-left: .1rem;
    }

    .li {
      padding: 0 .05rem;
      min-width: .84rem;
      margin: .05rem 0;
      text-align: center;
      line-height: .3rem;
      border-radius: .04rem;
      cursor: pointer;

      &:hover {
        color: $blue;
      }

      &.on {
        // font-size: .14rem;
        color: $blue;
        background: $blueLight;
      }
    }
  }

  .btn-toggle {
    margin-right: .14rem;
    margin-top: .04rem;
    line-height: .3rem;
    user-select: none;

    img {
      width: 0.09rem;
      margin-left: .05rem;
      transition: .3s;
    }
  }

  &.open {
    .list {
      height: auto;
    }

    .btn-toggle {
      img {
        transform: rotate(180deg);
      }
    }
  }
}
</style>