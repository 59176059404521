import { numMax, numPad, dateFormat, nameFormat, priceFormat } from "@/utils";

/**
 * 全局注册: 在main.js中引入,并使用Vue.use()注册
*/
export default {
  install: Vue => {
    Vue.filter('numMax', numMax);
    Vue.filter('numPad', numPad);
    Vue.filter('dateFormat', dateFormat)
    Vue.filter('nameFormat', nameFormat)
    Vue.filter('priceFormat', priceFormat)
  }
};
