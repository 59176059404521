<template>
  <Layout>
    <div class="main-service">
      <!-- <div class="base-width">
        <Search type v-ani.fade-up @search="handleSearch" />
      </div> -->
      <div class="c-layout-2">
        <div class="base-width">
          <div class="c-layout-2__lf">
            <Cate :list="cateList" :curId="String(curId)" @change="cateChange" v-ani.fade-up />
          </div>
          <div class="c-layout-2__main">
            <slot></slot>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
export default {
  created() {
    if (this.$route.path == '/service/cost') {
      localStorage.setItem('cateId', 1)
    }
    if (this.$route.path == '/service/data') {
      localStorage.setItem('cateId', 2)
    }
    if (this.$route.path == '/service/letter') {
      localStorage.setItem('cateId', 6)
    }
    if (this.$route.path == '/service/advertising') {
      localStorage.setItem('cateId', 10)
    }
    if (this.$route.path == '/service/finance') {
      localStorage.setItem('cateId', 4)
    }
    if (this.$route.path == '/service/material') {
      localStorage.setItem('cateId', 7)
    }
    let id = localStorage.getItem('cateId');
    if (id) {
      this.curId = id;
    }
  },
  data() {
    return {
      cateList: [
        { cat_id: 1, cat_name: '工程造价', link: '/service/cost' },
        { cat_id: 2, cat_name: '工程资料', link: '/service/data' },
        { cat_id: 3, cat_name: '工程预决算', link: '/service/budget' },
        { cat_id: 4, cat_name: '工程财税', link: '/service/finance' },
        { cat_id: 5, cat_name: 'BIM工程', link: '/service/BLM' },
        { cat_id: 6, cat_name: '工程保函', link: '/service/letter' },
        { cat_id: 7, cat_name: '材料送检', link: '/service/material' },
        { cat_id: 8, cat_name: '深化设计', link: '/service/design' },
        { cat_id: 9, cat_name: '工程法务', link: '/service/legal' },
        { cat_id: 10, cat_name: '工程广告', link: '/service/advertising' },
        { cat_id: 11, cat_name: '规范查询', link: '/service/query' },
        { cat_id: 12, cat_name: '辅助工具', link: '/service/tools' },
      ],
      curId: "1",
    };
  },
  methods: {
    cateChange(e) {
      localStorage.setItem('cateId', e)
    },
    handleSearch(e) {
      console.log(e);
    }
  }
};
</script>

<style lang="scss">
.main-service {
  padding-top: 0.2rem;
  padding-bottom: 0.4rem;

  .banner {
    text-align: right;

    .text {
      left: 0;
      right: 1.1rem;
      top: 45%;
    }

    .tit {
      font-size: .5rem;
      text-shadow: 2px 2px 2px rgba($color: #000000, $alpha: 0.2);
    }
  }
}
</style>