<template>
  <div class="c-dialog_wrapper" v-if="visible" :style="{ 'z-index': zIndex }">
    <!-- 蒙层 -->
    <div class="c-dialog_mask"></div>
    <div class="c-dialog" :style="{width: width}">
      <!-- 关闭 -->
      <img
        v-if="close"
        class="c-dialog_close"
        src="@/assets/images/icon-close.png"
        alt=""
        @click="handle_close"
      >
      <div class="c-dialog_header flex-middle" v-if="title || $slots.title">
        <!-- 标题 -->
        <div class="c-dialog_title s22 color-2 font-medium" v-if="title">{{title}}</div>
        <slot name="title" v-if="$slots.title" />
      </div>
      <div class="c-dialog_body c-scroll">
        <!-- 自定义内容 -->
        <slot></slot>
      </div>
      <!-- 底部 -->
      <div class="c-dialog_footer" v-if="cancel || confirm">
        <!-- 确定按钮 -->
        <div
          class="btn s16 color-f btn-confirm"
          v-if="confirm"
          @click="handle_confirm"
        >
          {{confirmText}}
        </div>
        <!-- 取消按钮 -->
        <div
          class="btn s16 color-f btn-cancel"
          v-if="cancel"
          @click="handle_cancel"
        >
          {{cancelText}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    // 显示
    visible: { type: Boolean, default: false },
    // 弹框宽度
    width: { type: String, default: '6rem' },
    // 标题
    title: { type: String, default: '' },
    // 关闭按钮，默认显示
    close: { type: Boolean, default: true },
    // 取消按钮，默认显示
    cancel: { type: Boolean, default: true },
    cancelText: { type: String, default: '取消' },
    // 确认按钮，默认显示
    confirm: { type: Boolean, default: true },
    confirmText: { type: String, default: '确认' },
  },
  data() {
    return {
      zIndex: 1000
    };
  },
  watch: {
    visible(val) {
      if (val) {
        this.zIndex = this.$newDialogIndex()
      }
    }
  },
  methods: {
    update_visible() {
      this.$emit('update:visible', false)
    },
    // 关闭弹窗
    handle_close() {
      this.update_visible()
      this.$emit('close')
    },
    // 取消
    handle_cancel() {
      this.update_visible()
      this.$emit('cancel')
    },
    handle_confirm() {
      this.$emit('confirm')
    }
  }
};
</script>

<style lang="scss" scoped>
.c-dialog {
  position: relative; z-index: 2;
  display: inline-block; vertical-align: middle;
  background: #fff;
  width: 6rem; max-width: 100%;
  text-align: left;
  border-radius: .04rem;
  &_wrapper{
    position: fixed; left: 0; top: 0; right: 0; bottom: 0; z-index: 1000;
    text-align: center;
    &:after{
      content: '';
      display: inline-block;
      width: 0; height: 100%;
      vertical-align: middle;
    }
  }
  &_mask{
    position: absolute; left: 0; top: 0; z-index: 1;
    width: 100%; height: 100%;
    background: rgba($color: #000000, $alpha: 0.8);
  }
  &_header{
    padding: 0 .3rem;
    height: .7rem;
    background: #ecf5fa;
    position: relative;
    border-radius: .04rem .04rem 0 0;
  }
  &_close{
    position: absolute; right: .29rem; top: .25rem; z-index: 2;
    width: .21rem; height: .21rem;
    cursor: pointer;
  }
  &_title{
    margin-right: 20px;
  }
  &_body{
    max-height: 70vh; overflow-y: auto;
  }
  &_footer{
    border-radius: 0 0 .04rem .04rem;
    padding: .1rem 0 .4rem;
    display: flex; justify-content: center; align-items: center;
    .btn{
      min-width: .9rem;
      line-height: .5rem;
      border-radius: .04rem;
      margin: 0 .1rem;
      padding: 0 .2rem;
      cursor: pointer;
      transition: .3s;
      text-align: center;
      &-cancel{
        background: #999999;
        &:hover{
          background: #666;
        }
      }
      &-confirm{
        background: $blue;
        &:hover{
          background: $blueDeep;
        }
      }
    }
  }
}
</style>