<template>
  <label class="c-checkbox flex-middle">
    <input
      type="checkbox"
      hidden
      :name="name"
      :checked='value'
      :disabled='disabled'
      @change="handleChange"
    >
    <div class="c-checkbox_icon"></div>
    <span class="c-checkbox_name" v-if="$slots.default">
      <slot></slot>
    </span>
  </label>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    name: '',
    disabled: { type: Boolean, default: false }
  },
  data() {
    return {};
  },
  methods: {
    handleChange(e) {
      this.$emit('input', !this.value)
      this.$emit('change', !this.value)
    }
  }
};
</script>

<style lang="scss" scoped>
.c-checkbox{
  cursor: pointer;
  &:last-of-type{
    margin-right: 0;
  }
  &_icon{
    width: .16rem; height: .16rem;
    background: url(~@/assets/images/icon-104.png) no-repeat center / contain;
  }
  &_name{
    margin-left: .07rem;
  }
  input:checked + &_icon{
    background-image: url(~@/assets/images/icon-103.png);
  }
  input:checked ~ &_name{
    color: $blue;
  }
}
</style>