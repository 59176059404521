<template>
  <BaseDialog
    :visible.sync="visible"
    title='取消订单'
    @close="$emit('close')"
    @cancel="$emit('cancel')"
    @confirm='handleSubmit'
  >
    <form class="form s16 color-2">
      <!-- 收货人姓名 -->
      <div class="form-item">
        <label class="form-item_label required">
          <div><p class="p_label">取消理由</p></div>
        </label>
        <div class="form-item_content">
          <BaseSelect :options='options' v-model="cancel_type_id" ipt-class='ipt' placeholder="请选择理由"></BaseSelect>
        </div>
      </div>
      <!-- 联系人电话 -->
      <div class="form-item">
        <label class="form-item_label">
          <div><p class="p_label">取消说明</p></div>
        </label>
        <div class="form-item_content">
          <textarea type="text" class="ipt" placeholder="自行输入，最多输入100个字符" v-model="cancel_reason">
          </textarea>
        </div>
      </div>
    </form>
  </BaseDialog>
</template>

<script>
import { cancelOrderApi } from "@/request/api/order.js"
export default {
  props: {
    orderId:{
      type: String,
      default: ""
    }
  },
  data() {
    return {
      visible: true,
      cancel_type_id: 1,
      cancel_type: '',
      cancel_reason: '',
      options: [
        { id: 1, name: '不想要了' },
        { id: 2, name: '无法联系上卖家' },
      ]
    };
  },
  methods: {
    handleSubmit() {
      this.options.forEach(item => {
        if(item.id == this.cancel_type_id){
          this.cancel_type = item.name;
        }
      })
      let params = {
        order_id: this.orderId,
        cancel_type: this.cancel_type,
        cancel_reason: this.cancel_reason
      }
      cancelOrderApi(params).then(res => {
        if(res.status == 200){
          this.$message.success("取消订单成功");
          this.$emit('confirm') // 添加成功，页面要重新加载地址列表
        }
      })
    },
    
  }
};
</script>

<style lang="scss" scoped>
  .form{
    padding: .3rem .3rem .2rem;
    &-item{
      display: flex; align-items: flex-start;
      margin-bottom: .2rem;
      &:last-of-type{
        margin-bottom: 0;
      }
      &_label{
        min-width: 1rem;
        min-height: .5rem;
        display: flex; align-items: center;
        line-height: 1.4em;
        position: relative;
        padding-right: .07rem;
        &.required {
          .p_label{
            position: relative;
            &:after{
              content: '*';
              color: #f53e39;
              position: absolute; right: -.5em; top: .02rem;
            }
          }
          .p_tips{
            text-align: center;
          }
        }
      }
      &_content{
        flex: 1;
      }
    }
    ::v-deep .ipt{
      width: 100%; height: .5rem;
      border-radius: .04rem;
      border: solid 1px #cae1ec;
      padding: 0 .14rem;
      transition: .3s;
      &::placeholder{
        color: #999;
      }
      &:focus{
        border-color: $blue;
      }
    }
    textarea.ipt{
      display: block;
      height: 1.5rem;
      padding: .14rem .14rem;
    }
    .c-id-card{
      width: 100%;
      margin-bottom: .2rem;
    }
  }
</style>