<template>
  <div class="md-search s16">
    <div class="row-1">
      <!-- 搜索 -->
      <form class="search" @submit.prevent="handleSearch()">
        <!-- 搜索类别 -->
        <div class="type" v-if="type">
          <div class="type-hd flex-center">
            <span>{{ searchTypeText }}</span>
            <img src="@/assets/images/icon-down.png">
          </div>
          <div class="type-bd">
            <div class="type-option" v-for="item in searchTypeList" :key="item.id" @click="searchType = item.id">
              {{ item.title }}
            </div>
          </div>
        </div>
        <!-- 关键词 -->
        <input type="text" class="ipt" :class="{ bl: !type }" v-model.trim="keywords" :placeholder="placeholder">
        <!-- 按钮 -->
        <button class="btn hn-btn-green flex-center">
          <img src="@/assets/images/icon-search.png" class="icon">
          <span>{{ btnText }}</span>
        </button>
      </form>
      <!-- 购物车 -->
      <router-link class="hn-btn-blue btn-cart flex-center" to="/shop-cart" v-if="shopCart">
        <img src="@/assets/images/icon-cart.png" class="icon">
        <span>购物车 <span v-if="shopCartNum > 0">（{{ shopCartNum <= 99 ? shopCartNum : 99 }} <span v-if="shopCartNum > 99">
              +</span> ）</span></span>
      </router-link>
    </div>
    <!-- 记录 -->
    <div class="record" v-if="record">
      <span class="hd">历史搜索:</span>
      <div class="bd">
        <span class="text pointer" v-for="item in recordList" :key="item" @click="handleSearch(item)">
          {{ item }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { CartCountApi } from "@/request/api/shop";
export default {
  props: {
    type: { // 搜索类别切换
      type: Boolean,
      default: false
    },
    placeholder: { // 搜索提示语
      type: String,
      default: '请输入关键商品信息'
    },
    record: { // 显示搜索记录
      type: Boolean,
      default: false
    },
    btnText: { // 按钮文案
      type: String,
      default: '搜索'
    },
    shopCart: { // 显示购物车按钮
      type: Boolean,
      default: false
    },
    // shopCartNum: {  // 购物车数量
    //   type: String,
    //   default: "",
    // }
  },
  data() {
    return {
      keywords: '',
      recordList: ['不锈钢', '塑胶', '铝合金'],
      shopCartNum: '',
      searchType: 1,
      searchTypeList: [
        { title: '商品', id: 1 },
        { title: '店铺', id: 2 },
      ]
    }
  },
  computed: {
    searchTypeText() {
      let temp = this.searchTypeList.find(el => el.id === this.searchType)
      if (!temp) {
        temp = this.searchTypeList[0]
      }
      return temp.title
    }
  },
  watch: {
    $route: function (route) {
      this.keywords = route.query.keywords || '';
    }
  },
  created() {
    let token = localStorage.getItem('token');
    if (token) {
      // 购物车数量
      CartCountApi().then(({ status, data }) => {
        if (status == 200) {
          this.shopCartNum = data.count;
        }
      })
    }

    this.keywords = this.$route.query.keywords
    try {
      this.recordList = JSON.parse(localStorage.getItem('search'))
      if (!Array.isArray(this.recordList)) {
        this.recordList = []
      }
    } catch (error) {
      this.recordList = []
    }
  },
  methods: {
    handleSearch(word) {
      // 购物车
      if (this.$route.path == '/shop/list') {
        this.$emit('change', this.keywords);
        return;
      }
      // 智慧工地
      if (this.$route.path == '/construction') {
        this.$emit('change', this.keywords);
        return;
      }

      // 行业资讯
      if (this.$route.path == '/news') {
        this.$emit('change', this.keywords);
        return;
      }
      // 招投标
      if (this.$route.path == '/bid/material' || this.$route.path == '/bid/bid') {
        this.$emit('change', this.keywords);
        return;
      }

       // 店铺详情页
       if (this.$route.path == '/shop/seller') {
        this.$emit('change', this.keywords);
        return;
      }
      // let params = {
      //   keywords: this.keywords
      // }
      // if (this.type) {
      //   params.type = this.searchType
      //   params.typeName = this.searchTypeText
      // }
      // this.$emit('search', params)


      let keywords = word || this.keywords || ''
      if (this.searchType == 1) { // 商品
        this.$router.push('/shop/list?keywords=' + keywords)
      } else {
        this.$router.push('/shop/seller?keywords=' + keywords)
      }
      if (!word && this.recordList.findIndex(keywords) == -1) {
        this.recordList.push(keywords)
        localStorage.setItem('search', JSON.stringify(this.recordList))
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.md-search {
  margin: .19rem 0;
  position: relative;
  z-index: 100;

  .row-1 {
    display: flex;
    line-height: .5rem;
  }

  .search {
    flex: 1;
    background: #fff;
    display: flex;
    border-radius: .04rem;

    .type {
      position: relative;
      line-height: .48rem;

      &-hd {
        min-width: .9rem;
        border: solid 1px #e6f2f8;
        padding: 0 .1rem;
        border-radius: .04rem 0 0 .04rem;

        img {
          width: .09rem;
          margin-left: .07rem;
        }
      }

      &-bd {
        position: absolute;
        left: 0;
        top: 100%;
        width: 100%;
        display: none;
        background: #fff;
        text-align: center;
        padding: .1rem 0;

        .type-option {
          cursor: pointer;
          line-height: 2;

          &:hover {
            color: $blue;
          }
        }
      }

      &:hover {
        .type-bd {
          display: block;
        }
      }
    }

    .ipt {
      flex: 1;
      border: none;
      line-height: .48rem;
      border: solid 1px #e6f2f8;
      border-left: none;
      background: transparent;
      padding: 0 .22rem;

      &::placeholder {
        color: #999;
      }

      &.bl {
        border-left: 1px solid #e6f2f8;
        border-radius: .04rem 0 0 .04rem;
      }
    }

    .btn {
      border-radius: 0 .04rem .04rem 0;
      border: none;
      min-width: .9rem;
      padding: 0 .18rem;
      cursor: pointer;

      .icon {
        width: .16rem;
        margin-right: .05rem;
      }
    }
  }

  .btn-cart {
    margin-left: 0.2rem;
    border-radius: .04rem;
    min-width: 1.59rem;

    .icon {
      width: .18rem;
      margin-right: .08rem;
    }
  }

  .record {
    display: flex;
    flex-wrap: wrap;
    margin-top: .15rem;

    .hd {
      margin-right: .1rem;
    }

    .bd {
      display: flex;
      flex-wrap: wrap;

      .text {
        margin-right: .2rem;
        color: #999;

        &:hover {
          color: $green;
        }
      }
    }
  }
}
</style>