<template>
  <div class="md-service-list">
    <div class="c-title-2 s22" v-ani.fade-up>{{title}}</div>
    <div class='list'>
      <template v-for="item in list">
        <div class='li flex-middle c-card' :key="item.id" v-ani.fade-up>
          <ImgRatio :src='item.img' :width='387' :height='218'></ImgRatio>
          <div class='text'>
            <div class='tit s18 color-2 font-medium'>{{item.tit}}</div>
            <div class='desc s16 color-6'>{{item.desc}}</div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: { type: String, default: '' },
    list: { type: Array, default() { return [] } }
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.md-service-list{
  margin-top: .35rem;
  .list{
    margin-top: .15rem;
    .li{
      padding: .28rem  .6rem .29rem .3rem;
      margin-bottom: .2rem;
      &:last-of-type{
        margin-bottom: 0;
      }
    }
    .c-pic{
      width: calc(387 / 1350 * 100%);
      border-radius: .04rem;
      margin-right: .58rem;
    }
    .text{
      flex: 1;
    }
    .tit{}
    .desc{
      line-height: calc(30 / 16 * 1em);
      margin-top: .18rem;
    }
  }
}
</style>