<template>
  <div class="c-form-file" :class="{
    'theme-1': type == 1,
    'theme-2': type == 2
  }">
    <div class="c-form-file_lf">
      <div class="img-list" v-if="list.length > 0">
        <div class="img-item" v-for="(item, index) in list" :key="index" @click="handleDelete(index)">
          <img :src="item" class="img">
          <div class="icon-delete"></div>
        </div>
      </div>
      <p class="t1 s16 color-9">
        <span>{{ placeholder }}</span>
        <span v-if="max">{{ list.length }}/{{ max }}</span>
      </p>
    </div>
    <BaseButton class="btn-file" style-type="light" tag='label'>
      上传文件
      <input type="file" @change="handleFile" :multiple="multiple">
    </BaseButton>
  </div>
</template>

<script>
import axios from 'axios';
import { domainName } from "@/config/index.js";
export default {
  props: {
    value: { type: Array, default() { return [] } },
    multiple: { type: Boolean, default: false },
    max: { type: Number, default: 5 },
    placeholder: { type: String, default: '请上传相关截图' },
    type: { type: Number, default: 1 }, // 风格1-横排，风格2-上下
  },
  created() {
    this.list = this.value;
  },
  data() {
    return {
      list: []
    };
  },
  methods: {
    handleFile(e) {
      if (this.list.length >= this.max) {
        this.$message.success('请删除后再上传');
        return;
      }
      const file = e.target.files[0]; // 获取第一个选定的文件对象
      let formData = new FormData(); // 创建FormData对象
      formData.append('file', file); // 添加文件到formData对象中
      axios.post(`${domainName}/pc/upload/upload_file`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // 设置请求头为multipart/form-data类型
          'yzfrom': 'pcmall',
          'token': localStorage.getItem('token')
        }
      })
        .then((response) => {
          if (response.data.status == 200) {
            this.list.push(response.data.data.url);
            this.$emit('change', this.list)
          }
          if(response.data.status == 401){
            this.$router.push("/login")
          }
        })
      // for (let i = 0; i < e.target.files.length; i++) {
      //   const el = e.target.files[i];
      //   let obj = {
      //     id: Math.floor(Math.random() * 10000),
      //     name: el.name,
      //     type: el.type,
      //     src: ''
      //   }
      //   let reader = new FileReader()
      //   reader.readAsDataURL(el)
      //   reader.addEventListener("load", () => {
      //     obj.src = reader.result
      //     if (this.max == 0 || this.list.length < this.max) {
      //       this.list.push(obj)
      //       this.$emit('change', this.list)
      //     }
      //   }, false)
      // }
    },
    handleDelete(i) {
      this.list.splice(i, 1)
      this.$emit('change', this.list)
    }
  }
};
</script>

<style lang="scss" scoped>
.c-form-file {
  .img-list {
    display: flex;
    flex-wrap: wrap;
    margin-top: .05rem;

    .img-item {
      position: relative;
      border-radius: .04rem;
      overflow: hidden;
      margin-right: .1rem;
      margin-bottom: .08rem;

      &:hover {
        .icon-delete {
          opacity: 1;
        }
      }

      .img {
        display: block;
        width: .8rem;
        height: .8rem;
        object-fit: cover;
      }

      .icon-delete {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba($color: #000000, $alpha: 0.5) url(~@/assets/images/icon-delete.png) no-repeat center / .15rem;
        cursor: pointer;
        opacity: 0;
      }
    }

    &~.t1 {
      margin-bottom: .04rem;
    }
  }

  .btn-file {
    position: relative;

    input {
      position: absolute;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      cursor: pointer;
    }
  }

  &.theme-1 {
    display: flex;
    align-items: center;
    border: solid 1px #cae1ec;
    border-radius: .04rem;
    padding: .05rem .05rem .05rem .17rem;

    .c-form-file_lf {
      flex: 1;
      margin-right: .2rem;
    }
  }

  &.theme-2 {
    .c-form-file_lf {
      border: solid 1px #cae1ec;
      border-radius: .04rem;
      padding: .15rem .2rem .12rem;
    }

    .btn-file {
      margin-top: .1rem;
    }
  }
}
</style>