// 生成一个上下文模块。它包含对该目录中所有模块的引用，对于与正则表达式匹配的请求，可能需要该引用。
// 上下文模块包含一个映射，该映射将请求转换为模块ID。
const files = require.context("./", true, /\.vue$/);

/**
 * 全局注册:在main.js中引入,并使用Vue.use()注册
 */
export default {
  install: Vue => {
    files.keys().forEach(path => {
      // 获取组件
      let value = files(path).default;
      // path格式一般是 './base/b/index.vue'
      let nameArr = path.replace(/^\.\//, '').replace(/\.\w+$/, '').replace(/\/index$/, '').split('/') // 按目录划分层级
      // 这里转换为 BaseB
      let name = nameArr.reduce(function(res, str) {
        return res += str[0].toUpperCase() + str.slice(1)
      }, '')
      // 组件
      Vue.component(name, value);
    });
  }
};
