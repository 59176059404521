<template>
  <div class="md-prod-seller c-card">
    <div class="row-1 flex-bottom">
      <img :src="shop_logo" class="avatar">
      <div>
        <div class="name s18 color-2 font-medium">{{ shop_name }}</div>
        <UserRank :type='3' :num='4' :hatArr="hatArr" :starsArr="starsArr"></UserRank>
      </div>
    </div>
    <BaseButton class="btn" color="blue" style-type='light' v-if="is_collect_shop" @click="collectClick">已关注</BaseButton>
    <BaseButton class="btn" color="blue" style-type='light' v-else @click="collectClick">关注</BaseButton>
    <BaseButton class="btn" color="green" style-type='light' tag='router-link'
      :to='`/shop/seller?suppliers_id=${suppliers_id}`'>进店看看</BaseButton>
    <div class="btn-kefu flex-center color-blue s16 font-light pointer" @click="$emit('kefu')">
      <img src="@/assets/images/icon-kefu2.png">
      <span>联系客服</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    shop_logo: { type: String, default: "" },
    shop_name: { type: String, default: "" },
    suppliers_id: { type: String, default: "" },
    is_collect_shop: { type: Number, default: 0 },
    hatArr: { type: Array, default: () => [] },
    starsArr: { type: Array, default: () => [] }
  },
  data() {
    return {};
  },
  methods: {
    collectClick() {
      this.$emit('collectFn');
    }
  }
};
</script>

<style lang="scss" scoped>
.md-prod-seller {
  padding: .3rem;
  padding-bottom: .28rem;

  .row-1 {
    margin-bottom: .21rem;
  }

  .avatar {
    width: 0.66rem;
    height: .66rem;
    border-radius: 50%;
    margin-right: .12rem;
  }

  .seller-type {
    border: 1px solid;
    border-radius: .04rem;
    line-height: .18rem;
    padding: 0 .06rem;
    margin-left: .2rem;
  }

  .sect-1 {
    margin-top: .01rem;
  }

  .sect-2 {
    margin-top: .09rem;
  }

  .c-level-wrap {
    width: 1.1rem;
    height: .24rem;
    margin-top: .1rem;
  }

  .rank {
    margin-right: .3rem;

    img {
      width: .13rem;
      margin-right: .03rem;
    }
  }

  .btn {
    display: flex;
    margin-bottom: .1rem;
  }

  .btn-kefu {
    height: 0.4rem;
    border-radius: 0.04rem;
    border: solid 0.01rem #e6f2f8;

    img {
      width: .13rem;
      margin-right: .06rem;
    }

    &:hover {
      border-color: $blue;
    }
  }
}
</style>