<template>
  <BaseDialog :visible.sync="visible" :title="(addressId ? '编辑' : '新增') + '收货人信息'" :cancel='false' confirmText='保存收货地址'
    @close="$emit('close')" @confirm='handleSubmit'>
    <form class="form s16 color-2">
      <!-- 所在地区 -->
      <div class="form-item">
        <label class="form-item_label required">
          <div>
            <p class="p_label">所在地区</p>
          </div>
        </label>
        <div class="form-item_content region">
          <BaseSelect :options="provinceArr" ipt-class='ipt' v-model="form.province" placeholder="所在省"
            @change="proviceChange">
          </BaseSelect>
          <BaseSelect v-if="form.province" :options="cityArr" ipt-class='ipt' v-model="form.city" placeholder="所在市"
            @change="cityChange">
          </BaseSelect>
          <BaseSelect v-if="form.city" :options="districtArr" ipt-class='ipt' v-model="form.district" placeholder="所在区">
          </BaseSelect>
        </div>
      </div>
      <!-- 联系人 -->
      <div class="form-item">
        <label class="form-item_label required">
          <div>
            <p class="p_label">联系人</p>
          </div>
        </label>
        <div class="form-item_content">
          <input type="text" class="ipt" placeholder="请输入联系人" v-model="form.consignee">
        </div>
      </div>
      <!-- 手机号码 -->
      <div class="form-item">
        <label class="form-item_label required">
          <div>
            <p class="p_label">手机号码</p>
          </div>
        </label>
        <div class="form-item_content">
          <input type="text" class="ipt" placeholder="请输入手机号码" v-model="form.mobile" maxlength="11">
        </div>
      </div>
      <!-- 经纬度 -->
      <div class="form-item">
        <label class="form-item_label required">
          <div>
            <p class="p_label">经纬度</p>
          </div>
        </label>
        <div class="form-item_content">
          <div class="flex-center btn-map color-blue bg-blue-light ipt pointer" @click="() => gadeVis = true">
            <template v-if="!form.latitude">
              <img src="@/assets/images/icon-address.png">
              <span>获取经纬度</span>
            </template>
            <span v-else>{{ form.latitude }}~{{ form.longitude }}</span>
          </div>
        </div>
      </div>
      <!-- 地址 -->
      <div class="form-item">
        <label class="form-item_label required">
          <div>
            <p class="p_label">所在地址</p>
          </div>
        </label>
        <div class="form-item_content">
          <input type="text" class="ipt" placeholder="请输入所在地址" v-model="form.address">
          <!-- <p class="tips s14 color-red">提示：地区目前暂支持广东省内</p> -->
        </div>
      </div>
      <!-- 默认 -->
      <div class="form-item form-item--default">
        <label class="form-item_label">
          <div>
            <p class="p_label">是否设为默认地址</p>
          </div>
        </label>
        <div class="form-item_content s0">
          <BaseSwitch2 v-model="form.is_default" />
        </div>
      </div>
    </form>
    <!-- 高德地图 -->
    <DialogGaDeMap v-if="gadeVis" :dialogVisible="gadeVis" @ok="gadeOk"></DialogGaDeMap>
  </BaseDialog>
</template>

<script>
import { AddressDetailApi, AddressActionApi } from "@/request/api/order";
import { FegionApi } from "@/request/api/login";
export default {
  props: {
    addressId: { type: Number, default: 0 }
  },
  created() {
    if (this.addressId) {
      AddressDetailApi({ address_id: this.addressId }).then(({ status, data }) => {
        if (status == 200) {
          this.form = data.data;
          FegionApi({ parent_id: this.form.province, }).then(({ status, data }) => {
            if (status == 200) {
              this.cityArr = data;
            }
          })
          FegionApi({ parent_id: this.form.city, }).then(({ status, data }) => {
            if (status == 200) {
              this.districtArr = data;
            }
          })
        }
      })
    }
    this.provinceFn();
  },
  data() {
    return {
      form: {
        address_id: "",
        consignee: "",
        mobile: "",
        address: "",
        province: "",
        city: '',
        district: '',
        latitude: "",
        longitude: "",
        is_default: false,
      },
      visible: true,
      provinceArr: [],
      cityArr: [],
      districtArr: [],
      gadeVis: false
    };
  },
  methods: {
    gadeOk(obj) {
      this.form.address = obj.address;
      this.form.latitude = obj.lat;
      this.form.longitude = obj.lng;
      this.gadeVis = false;
    },
    handleSubmit() {
      AddressActionApi(this.form).then(({ status, data }) => {
        if (status == 200) {
          this.$message.success(`${this.addressId ? '新增' : '编辑'}成功`);
          this.$emit('close');
          this.$emit('update'); // 添加成功，页面要重新加载地址列表
        }
      })

    },
    // 获取省
    provinceFn() {
      FegionApi({ parent_id: 0, }).then(({ status, data }) => {
        if (status == 200) {
          this.provinceArr = data;
        }
      })
      this.form.city = '';
      this.form.district = '';
    },
    // 获取市
    cityFn() {
      FegionApi({ parent_id: this.form.province, }).then(({ status, data }) => {
        if (status == 200) {
          this.cityArr = data;
        }
      })
      this.form.district = '';
    },
    // 获取区
    districtFn() {
      FegionApi({ parent_id: this.form.city, }).then(({ status, data }) => {
        if (status == 200) {
          this.districtArr = data;
        }
      })
    },
    proviceChange(e) {
      this.cityFn();
    },
    cityChange(e) {
      this.districtFn();
    }
  }
};
</script>

<style lang="scss" scoped>
.form {
  padding: .3rem .3rem 0;

  &-item {
    display: flex;
    align-items: flex-start;
    margin-bottom: .2rem;

    &:last-of-type {
      margin-bottom: 0;
    }

    &_label {
      min-width: 1rem;
      min-height: .5rem;
      display: flex;
      align-items: center;
      line-height: 1.4em;
      position: relative;
      padding-right: .07rem;

      &.required {
        .p_label {
          position: relative;

          &:after {
            content: '*';
            color: #f53e39;
            position: absolute;
            right: -.5em;
            top: .02rem;
          }
        }

        .p_tips {
          text-align: center;
        }
      }
    }

    &_content {
      flex: 1;
    }

    &--default {
      align-items: center;

      .form-item_label {
        min-height: .3rem;
      }
    }
  }

  ::v-deep .ipt {
    width: 100%;
    height: .5rem;
    border-radius: .04rem;
    border: solid 1px #cae1ec;
    padding: 0 .14rem;
    transition: .3s;

    &::placeholder {
      color: #999;
    }

    &:focus {
      border-color: $blue;
    }
  }

  .region {
    display: flex;

    .base-select {
      flex: 1;
      margin-right: .08rem;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  .tips {
    padding: 0 .1rem;
    margin-top: .08rem;
  }

  .btn-map {
    img {
      width: .15rem;
      margin-right: .05rem;
    }
  }
}
</style>